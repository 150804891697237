import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Course from "./components/admin/managecourse/Course";
import Invoice from "./components/admin/invoice/Invoice";
import LiveRecorded from "./components/admin/Liverecord/LiveRecorded";
import SubscriberInfo from "./components/admin/subscriber/SubscriberInfo";
import Navbar from "./components/admin/navbar/Navbar";
import { createContext, useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import Adding from "./components/admin/uploadcourse/Adding";
import AddChapter from "./components/admin/uploadcourse/AddChapter";
import AddQuiz from "./components/admin/uploadcourse/AddQuiz";
import ChapterData from "./components/admin/uploadcourse/ChapterData";
import CourseDetailsForm from "./components/admin/uploadcourse/CourseDetailsForm";
import FlashCardForm from "./components/admin/uploadcourse/FlashcardForm";
import EditChapter from "./components/admin/uploadcourse/EditChapter";
import EditQuiz from "./components/admin/uploadcourse/EditQuiz";
import EditData from "./components/admin/uploadcourse/EditData";
import Category from "./components/Category";
import Products from "./components/Products";
import Pricing from "./components/Pricing";
import AddCourse from "./components/AddCourse";
import Loading from "./components/Loading";
import ResetPassword from "./components/admin/ResetPassword/ResetPassword";
import Enquirers from "./components/Enquirers";
import Coupon from "./components/admin/Coupon";
import DeleteModal from "./components/DeleteModal";
import Login from "./components/Login";
import { ToastContainer } from "react-toastify";
import AccountSetting from "./components/admin/account/accountSetting";
import UserContext from "./context/UserContext";
import Live from "./components/Live";
import AddCoupon from "./components/admin/AddCoupon";
import General from "./components/admin/CouponContent/General";
import UsageRestriction from "./components/admin/CouponContent/UsageRestriction";
import UsageLimit from "./components/admin/CouponContent/UsageLimit";
import { theme } from "./theme";
import { ThemeProvider } from "@mui/material/styles";
import UploadCourse from "./components/admin/uploadcourse/UploadCourse";
import ForgotPassword from "./components/admin/uploadcourse/ForgotPassword";
import NavbarContext from "./context/NavbarContext";
import ResetPasswordLogin from "./components/admin/uploadcourse/ResetPasswordLogin";

const Data = createContext();
function App() {
  let [state, setState] = useState(false);
  let [updateValue, setUpdateValue] = useState(false);
  let [namedata, setNamedata] = useState([]);
  const [showNavbar, setShowNavbar] = useState(true);
  const [user, setUser] = useState({ name: "Guest User" });

  let [quizData, setQuizData] = useState([]);
  let [flashData, setFlashData] = useState([]);
  let [imageData, setImageData] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 900) {
        setUpdateValue(true);
      } else {
        setUpdateValue(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <UserContext.Provider value={{ user, setUser }}>
        <Data.Provider value={{ state, setState }}>
          <NavbarContext.Provider value={{ showNavbar, setShowNavbar }}>
            <ChapterData.Provider
              value={{
                namedata,
                setNamedata,
                quizData,
                setQuizData,
                flashData,
                setFlashData,
                imageData,
                setImageData,
              }}
            >
              <ToastContainer />

              <Router>
                {showNavbar ? <Navbar /> : null}

                <Box
                  sx={{
                    paddingLeft: updateValue || !showNavbar ? "0px" : "170px",
                    paddingTop: showNavbar ? "80px" : 0,
                  }}
                  // sx={{
                  //   paddingLeft:
                  //     updateValue || ["/"].includes(window.location.pathname)
                  //       ? "0px"
                  //       : "160px",
                  //   paddingTop: !["/"].includes(window.location.pathname)
                  //     ? "80px"
                  //     : 0,
                  // }}
                >
                  <Routes>
                    <Route path="/" element={<Login />} />
                    <Route
                      path="/change-password"
                      element={<ResetPassword />}
                    />
                    <Route
                      path="/account-setting"
                      element={<AccountSetting />}
                    />
                    <Route
                      path="/upload-courses"
                      element={<CourseDetailsForm />}
                    />
                    <Route
                      path="/resetPassword/:access_token"
                      element={<ResetPasswordLogin />}
                    />
                    <Route
                      path="/upload-courses/course-details"
                      element={<CourseDetailsForm />}
                    />
                    <Route path="/addCourse" element={<AddCourse />} />
                    {/* <Route path="/upload-courses/adding" element={<Adding />} /> */}
                    <Route path="/manage-courses" element={<Course />} />
                    <Route
                      path="/subscribers-info"
                      element={<SubscriberInfo />}
                    />
                    <Route
                      path="/forgot-password"
                      element={<ForgotPassword />}
                    />

                    <Route path="/:access_token" element={<Loading />} />
                    <Route path="/menu" element={<Category />} />
                    <Route path="/products" element={<Products />} />
                    <Route path="/dates" element={<Pricing />} />
                    <Route path="/invoice" element={<Invoice />} />
                    <Route path="/livestreaming" element={<LiveRecorded />} />
                    <Route path="/live" element={<Live />} />

                    <Route
                      path="/upload-courses/adding"
                      element={<UploadCourse />}
                    />
                    {/* <Route
                    path="/upload-courses/adding/addquiz"
                    element={<AddQuiz />}
                  />
                  <Route
                    path="/upload-courses/adding/addflashcard"
                    element={<FlashCardForm />}
                  />
                  <Route
                    path="/upload-courses/adding/editchapter"
                    element={<EditChapter />}
                  />
                  <Route
                    path="/upload-courses/adding/editquiz"
                    element={<EditQuiz />}
                  /> */}

                    <Route path="/enquirers" element={<Enquirers />} />
                    {/* <Route path="/coupon" element={<Coupon />} /> */}
                    <Route path="/addcoupon" element={<AddCoupon />} />
                    <Route path="/delete" element={<DeleteModal />} />
                    <Route path="/general" element={<General />} />

                    <Route
                      path="/usagerestriction"
                      element={<UsageRestriction />}
                    />
                    <Route path="/usagelimits" element={<UsageLimit />} />
                  </Routes>
                </Box>
              </Router>
            </ChapterData.Provider>
          </NavbarContext.Provider>
        </Data.Provider>
      </UserContext.Provider>
    </ThemeProvider>
  );
}
export default App;
