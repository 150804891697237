import React, { useState } from "react";
import {
  Container,
  Fab,
  Grid,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  styled,
  Tab,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Modal from "@mui/material/Modal";
import { Tabs } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}
const Heading = styled(TableCell)(() => ({
  fontWeight: "bold",
  textAlign: "center",
}));
const Data = styled(TableCell)(() => ({
  textAlign: "center",
}));

const Expand = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));
const style = {
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const LiveRecorded = () => {
  const [tabValue, setTabValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [recordedData, setRecordedData] = useState([]);
  const [formData, setFormData] = useState({
    course: "",
    date: "",
    time: "",
    duration: "",
    trainer: "",
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setData((prevData) => [...prevData, formData]);
    setFormData({
      course: "",
      date: "",
      time: "",
      duration: "",
      trainer: "",
    });
    handleClose();
  };
  // const handleStartRecording = (index) => {
  //     const recordedItem = {
  //         ...data[index],
  //         recordedDate: dayjs().format('HH:mm:ss'),
  //     };
  //     setRecordedData((prevData) => [...prevData, recordedItem]);

  //     setData((prevData) => {
  //         const newData = [...prevData];
  //         newData.splice(index, 1);
  //         return newData;
  //     });
  // };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  return (
    <>
      <Container sx={{ overflow: "auto" }}>
        <Paper style={{ marginTop: "15px" }}>
          <Grid container justifyContent="flex-start">
            <Grid item>
              <Box>
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Sheduled" {...a11yProps(0)} />
                  <Tab label="Recorded" {...a11yProps(1)} />
                </Tabs>
              </Box>
            </Grid>
          </Grid>
        </Paper>
        <br />

        <TabPanel value={tabValue} index={0}>
          <Table>
            <TableHead>
              <TableRow>
                <Heading>Course Name</Heading>
                <Heading>Start Date</Heading>
                <Heading>Start Time</Heading>
                <Heading>Approx.Duration</Heading>
                <Heading>Trainer</Heading>
                <Heading></Heading>
              </TableRow>
            </TableHead>

            <TableBody component={Paper} elevation={3}>
              {data.map((item, index) => (
                <TableRow key={index}>
                  <Data>{item.course}</Data>
                  <Data>{item.date}</Data>
                  <Data>{item.time}</Data>
                  <Data>{item.duration}</Data>
                  <Data>{item.trainer}</Data>
                  <Data>
                    <Button
                      variant="contained"
                      // onClick={() => handleStartRecording(index)}
                    >
                      Start
                    </Button>
                  </Data>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Fab
            variant="contained"
            style={{
              color: "#ffff",
              backgroundColor: "#2962ff",
              position: "absolute",
              right: "5%",
              bottom: "5%",
            }}
            onClick={handleOpen}
          >
            <AddIcon />
          </Fab>
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <Table>
            <TableHead>
              <TableRow>
                <Heading>Course Name</Heading>
                <Heading>Start Date</Heading>
                <Heading>Start Time</Heading>
                <Heading>Duration</Heading>
                <Heading>Trainer</Heading>
                <Heading></Heading>
              </TableRow>
            </TableHead>
            <TableBody component={Paper} elevation={3}>
              {recordedData.map((item, index) => (
                <TableRow key={index}>
                  <Data>{item.course}</Data>
                  <Data>{item.date}</Data>
                  <Data>{item.time}</Data>
                  <Data>{item.recordedDate}</Data>
                  <Data>{item.trainer}</Data>
                  <Data>
                    <Button download variant="contained">
                      Download
                    </Button>
                  </Data>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TabPanel>

        <Modal
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h5"
              component="h2"
              sx={{ textAlign: "center" }}
            >
              Add Live Schedule
            </Typography>
            <form onSubmit={handleSubmit} style={{ marginTop: "5%" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Expand
                    name="course"
                    value={formData.course}
                    required
                    fullWidth
                    label="Course Name"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Expand
                    name="date"
                    type={"date"}
                    value={formData.date}
                    required
                    fullWidth
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Expand
                    name="time"
                    type={"time"}
                    value={formData.time}
                    required
                    fullWidth
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Expand
                    name="duration"
                    value={formData.duration}
                    required
                    fullWidth
                    label="Duration"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Expand
                    name="trainer"
                    value={formData.trainer}
                    required
                    fullWidth
                    label="Trainer"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "3%",
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{
                        background: "#747DE8",
                        "&:hover": {
                          background: "#747DE8",
                        },
                      }}
                    >
                      Submit
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Modal>
      </Container>
    </>
  );
};

export default LiveRecorded;
