import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  styled,
  Box,
  Typography,
  Divider,
  TextField,
  IconButton,
  InputAdornment,
  TableContainer,
} from "@mui/material";

import { Search } from "@mui/icons-material";

import { Link } from "react-router-dom";

// apis
import { get } from "../../../api/apiMethods";
import { urls } from "../../../api/urlConstants";
import { theme } from "../../../theme";

const Heading = styled(TableCell)(() => ({
  fontWeight: "bold",
  textAlign: "center",
}));
const Data = styled(TableCell)(() => ({
  textAlign: "center",
}));

const SubscriberInfo = () => {
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [searchFilter, setSearchFilter] = useState([]);

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());

    if (value.trim() !== "") {
      users.length > 0 &&
        setSearchFilter(
          users.filter((ele) => {
            const nameMatch = ele.name
              .toLowerCase()
              .includes(value.toLowerCase().trim());
            const phoneMatch = ele.mobile
              .toLowerCase()
              .includes(value.toLowerCase().trim());
            const emailMatch = ele.email.address
              .toLowerCase()
              .includes(value.toLowerCase().trim());
            const userIdMatch =
              ele.userId &&
              ele.userId.toString().includes(value.toLowerCase().trim());

            return nameMatch || phoneMatch || emailMatch || userIdMatch;
          })
        );
    } else {
      setSearchFilter([]);
    }
  };

  useEffect(() => {
    const getUsers = async () => {
      try {
        const getUser = await get(`${urls.user.getSubscriberList}`);
        setUsers(getUser.data.result.data);
      } catch (error) {
        console.log(error);
      }
    };
    getUsers();
  }, []);

  return (
    <>
      <Box sx={{ padding: "10px 30px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
          <Typography
            sx={{
              flex: "1 1 100%",
              m: 2,
              color: `${theme.palette.primary.main}`,
              fontSize: { xs: "16px", sm: "18px", md: "22px" },
            }}
            variant="h6"
            id="tableTitle"
            component="div"
            fontWeight={600}>
            Total Subscribers ({users.length})
          </Typography>

          <TextField
            size="small"
            value={search}
            onChange={handleSearch}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" type="submit">
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{
              "& .MuiInputBase-root": {
                borderRadius: "20px",
                height: "35px",
                boxShadow: 0.5,
              },
            }}
            placeholder="Search"
          />
        </Box>
        <Divider />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <Heading>S.No</Heading>
                <Heading>Customer ID</Heading>
                <Heading>Name</Heading>
                <Heading>Mail</Heading>
                <Heading>Contact Number</Heading>
              </TableRow>
            </TableHead>

            <TableBody>
              {search
                ? searchFilter.map((data, index) => (
                    <TableRow key={data._id}>
                      <Data>{index + 1}</Data>
                      <Data>{data.userId}</Data>
                      <Data>{data.name}</Data>
                      <Data>
                        <Link
                          to={`mailto:${data.email.address}`}
                          size="small"
                          style={{ color: `${theme.palette.primary.main}` }}>
                          {data.email.address}
                        </Link>
                      </Data>
                      <Data>{data.mobile}</Data>
                    </TableRow>
                  ))
                : users.map((data, index) => (
                    <TableRow key={data._id}>
                      <Data>{index + 1}</Data>
                      <Data>{data.userId}</Data>
                      <Data>{data.name}</Data>
                      <Data>
                        <Link
                          to={`mailto:${data.email.address}`}
                          size="small"
                          style={{ color: `${theme.palette.primary.main}` }}>
                          {" "}
                          {data.email.address}
                        </Link>
                      </Data>
                      <Data>{data.mobile}</Data>
                    </TableRow>
                  ))}
              <TableRow>
                {!users.length && !search.trim() && (
                  <Data align="center" colSpan={12}>
                    <Typography
                      variant="h6"
                      sx={{
                        textAlign: "center",
                        margin: "5px",
                        padding: "5px",
                      }}>
                      No data found
                    </Typography>
                  </Data>
                )}
                {search.trim() && !searchFilter.length && (
                  <Data align="center" colSpan={12}>
                    <Typography
                      variant="h6"
                      sx={{
                        textAlign: "center",
                        margin: "5px",
                        padding: "5px",
                      }}>
                      No data found
                    </Typography>
                  </Data>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default SubscriberInfo;
