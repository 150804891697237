import React from "react";
import { Box, Typography } from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function ReactQuillInput(props) {
  const {
    name,
    type,
    formik
   } = props;

  return (
    
    <Box>
      <ReactQuill
       style={{height:"200px"}}
        onChange={(v) => formik.setFieldValue(name, v)}
        value={formik.values[name] || (type === "number" ? Number("") : "")}
        onBlur={() => formik.handleBlur(name)}
        id={name}
      />
      {/* {formik.touched[name] && formik.errors[name] ? (
        <Typography color="error">{formik.errors[name]}</Typography>
      ) : null} */}
    </Box>
  );
}

export default ReactQuillInput;



