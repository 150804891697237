import {
  Box,
  Button,
  Grid,
  Paper,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  TextField,
  styled,
  Divider,
  Card,
  Modal,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { put, get } from "../api/apiMethods";
import { urls } from "../api/urlConstants";
import FormSelect from "./FormSelect";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { LoadingButton } from "@mui/lab";
import { theme } from "../theme";

const Label = styled("label")(() => ({
  fontWeight: "bold",
  paddingLeft: "10px",
  fontSize: "14px",
}));

const modal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  borderRadius: "5px",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const SelfPaced = ({ selectedCourseId }) => {
  const [editMode, setEditMode] = useState(false);
  const [products, setProducts] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [courseId, setCourseId] = useState(selectedCourseId || "");
  const [selfPacedInfo, setSelfPacedInfo] = useState({
    standardPrice: "",
    earlyBirdPrice: "",
    // earlyBirdEndDate: "",
  });
  const [priceLoading, setPriceLoading] = useState(false);
  const [fileChoosen, setFileChoosen] = useState(null);
  const [bulkUploadLoader, setBulkUploadLoader] = useState(false);
  const handleEditClick = () => {
    setEditMode(!editMode);
  };
  const handleUpload = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setFileChoosen(null);
  };
  const handleChange = (event) => {
    setCourseId(event.target.value);
  };
  const handleFileChange = (e) => {
    setFileChoosen(e.target.files[0]);
  };
  const handleSheetUpload = async (e) => {
    setBulkUploadLoader(true);
    e.preventDefault();
    console.log("test ", fileChoosen);
    try {
      if (!fileChoosen) {
        console.error("No file selected.");
        return;
      }
      const formData = new FormData();
      formData.append("file", fileChoosen);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await put(urls.course.uploadSample, formData, config);
      console.log("Response:", response.data);
      console.log("Sheet uploaded successfully!");
      handleClose();
    } catch (error) {
      console.error(error);
    }
    setBulkUploadLoader(false);
  };
  const downloadSample = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const res = await get(urls.course.downloadSample, {
        responseType: "blob",
      });
      console.log(res, "reeeesss");

      const downloadUrl = URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `sample.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setLoading("");
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  // useEffect(() => {
  //   const getCourse = async () => {
  //     try {
  //       const product = await get(`${urls.course.getList}`);
  //       setCourseList(product.data.result.data);
  //       setProducts(product.data.result.data);
  //       console.log(product.data.result.data,"dataaaaaaaaaaaaaaaa======================")
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   getCourse();
  // }, []);

  useEffect(() => {
    const getCourse = async () => {
      try {
        const product = await get(`${urls.course.getList}`);
        const paidProducts = product.data.result.data.filter(
          (product) => product.isPaid
        );
        setCourseList(paidProducts);
        setProducts(paidProducts);
        console.log(paidProducts, "Paid Products ======================");
      } catch (error) {
        console.log(error);
      }
    };
    getCourse();
  }, []);

  console.log(selfPacedInfo, "selfpaced");
  const formik = useFormik({
    initialValues: {
      standardPrice: selfPacedInfo?.standardPrice || "",
      earlyBirdPrice: selfPacedInfo?.earlyBirdPrice || "",
      earlyBirdEndDate:
        dayjs(selfPacedInfo?.earlyBirdEndDate).format("YYYY-MM-DD") || "",
    },

    validationSchema: Yup.object({
      standardPrice: Yup.number()
        .typeError("Must be a number")
        .required("Standard Price is required"),
      earlyBirdPrice: Yup.number().typeError("Must be a number"),
      earlyBirdEndDate: Yup.date(),
    }),

    onSubmit: async (values) => {
      try {
        const body = {
          standardPrice: values.standardPrice,
          earlyBirdPrice: values.earlyBirdPrice,
          earlyBirdEndDate: values.earlyBirdEndDate,
        };

        setPriceLoading(true);
        const response = await put(
          `${urls.course.updateSelfPaced}/${courseId}`,
          body
        );
        console.log("Response:", response);
        setPriceLoading(false);
      } catch (error) {
        console.error(error);
      }
      setPriceLoading(false);
    },
    validateOnBlur: false,
    enableReinitialize: true,
  });
  useEffect(() => {
    const fetchSelfPacedInfo = async () => {
      try {
        // let courseId;
        // courseId = courseList.filter((m) => m.title === formik.values.product)[0]?._id;
        // console.log(courseId,"courseId")
        const response = await get(`${urls.course.getOnevalue}/${courseId}`);
        const data = response.data.result.data;
        console.log(data, "ssdata");
        setSelfPacedInfo({
          standardPrice: data.selfPacedInfo?.standardPrice || "",
          earlyBirdPrice: data.selfPacedInfo?.earlyBirdPrice || "",
          earlyBirdEndDate: data.selfPacedInfo?.earlyBirdEndDate || "",
        });
      } catch (error) {
        console.error(error);
      }
    };
    if (courseId) {
      fetchSelfPacedInfo();
    }
  }, [courseId]);

  return (
    <>
      {/* <Paper sx={{ padding: "15px" }}> */}
      <Grid container>
        <Grid item xs={8} sm={4} md={3}>
          <FormControl fullWidth required size="small">
            <InputLabel>Course Name</InputLabel>
            <Select
              label="select Course"
              id="demo-simple-select-filled"
              value={courseId ? courseId : selectedCourseId}
              placeholder="select Course"
              name="courseId"
              size="small"
              onChange={handleChange}
            >
              {products.map((name) => (
                <MenuItem key={name._id} value={name._id}>
                  {name.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* <Grid item xs={8} sm={6} md={3}>
              <Button
                onClick={handleUpload}
                sx={{
                  backgroundColor: "#747DE8",

                  "&:hover": {
                    background: "#747DE8",
                  },
                  color: "#ffff",
                }}>
                Bulk Upload
              </Button>
            </Grid> */}
      </Grid>
      <Divider sx={{ color: "#00000075", border: 1, my: 3 }} />

      {/* </Paper> */}
      {/* <Card sx={{ padding: "10px", mt: "10px" }}> */}
      <form onSubmit={formik.handleSubmit}>
        {/* <Box sx={{ m: 1, p: 1 }}> */}

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <InputLabel
              htmlFor="standardPrice"
              sx={{
                fontSize: "14px",
                fontWeight: "bold",
                color: "#000000DE",
                paddingLeft: "10px",
              }}
            >
              Standard Price
            </InputLabel>

            <TextField
              size="small"
              placeholder="Standard Price"
              disabled={!editMode}
              type="number"
              name="standardPrice"
              value={formik.values.standardPrice}
              onChange={formik.handleChange}
              error={
                formik.touched.standardPrice &&
                Boolean(formik.errors.standardPrice)
              }
              helperText={
                formik.touched.standardPrice && formik.errors.standardPrice
              }
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <InputLabel
              htmlFor="earlyBirdPrice"
              sx={{
                fontSize: "14px",
                fontWeight: "bold",
                color: "#000000DE",
                paddingLeft: "10px",
              }}
            >
              Early Bird Price
            </InputLabel>

            <TextField
              size="small"
              name="earlyBirdPrice"
              placeholder="Early Bird Price"
              type="number"
              disabled={!editMode}
              value={formik.values.earlyBirdPrice}
              onChange={formik.handleChange}
              error={
                formik.touched.earlyBirdPrice &&
                Boolean(formik.errors.earlyBirdPrice)
              }
              helperText={
                formik.touched.earlyBirdPrice && formik.errors.earlyBirdPrice
              }
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <InputLabel
              htmlFor="earlyBirdEndDate"
              sx={{
                fontSize: "14px",
                fontWeight: "bold",
                color: "#000000DE",
                paddingLeft: "10px",
              }}
            >
              Early Bird End Date
            </InputLabel>

            <TextField
              type="date"
              size="small"
              name="earlyBirdEndDate"
              disabled={!editMode}
              value={formik.values.earlyBirdEndDate}
              onChange={formik.handleChange}
              error={
                formik.touched.earlyBirdEndDate &&
                Boolean(formik.errors.earlyBirdEndDate)
              }
              helperText={
                formik.touched.earlyBirdEndDate &&
                formik.errors.earlyBirdEndDate
              }
              required
              fullWidth
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{ mt: { xs: 0, sm: 3, md: 3, lg: 3 } }}
          >
            <Tooltip title={editMode ? "Save" : "Edit"}>
              <LoadingButton
                loading={priceLoading}
                variant="contained"
                size="small"
                type={editMode ? "button" : "submit"}
                onClick={handleEditClick}
              >
                {editMode ? "Save" : "Edit"}
              </LoadingButton>
            </Tooltip>
          </Grid>
        </Grid>
        {/* </Box> */}
      </form>
      {/* </Card> */}

      <Modal
        onClose={handleClose}
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modal}>
          <div style={{ textAlign: "center", marginBottom: "30px" }}>
            <span style={{ color: "red", fontFamily: "cursive" }}>
              Upload Course
            </span>
          </div>
          <form onSubmit={handleSheetUpload}>
            <Grid container spacing={1} sx={{ ml: 1.5 }}>
              <Grid item xs={4} md={4} lg={4}>
                <Tooltip title="Get Sample">
                  <Button
                    variant="contained"
                    size="small"
                    type="submit"
                    loading={loading}
                    onClick={downloadSample}
                  >
                    Sample
                  </Button>
                </Tooltip>
              </Grid>
              <Grid item xs={4} md={4} lg={4}>
                <Tooltip title="Upload">
                  <Button variant="contained" size="small" component="label">
                    Upload
                    <input
                      onChange={handleFileChange}
                      name="file"
                      hidden
                      accept=".xlsx"
                      type="file"
                    />
                  </Button>
                </Tooltip>

                <div>{fileChoosen && fileChoosen.name}</div>
              </Grid>

              <Grid item xs={4} md={4} lg={4}>
                <Tooltip title="Submit">
                  <LoadingButton
                    size="small"
                    loading={bulkUploadLoader}
                    variant="contained"
                    type="submit"
                  >
                    Submit
                  </LoadingButton>
                </Tooltip>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default SelfPaced;
