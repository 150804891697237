export const urls = {
  menu: {
    createMenu: "/menu/create",
    editMenu: "/menu/updateName",
    getList: "/menu/list",
    updateStatus: "/menu/changeStatus",
  },
  // course: {
  //   createCourse: "/course/create",
  //   updateCourse: "/course/update",
  //   getCourse: "/course/list",
  //   getCourseName: "/course/getCoursesNames",
  // },
  user: {
    // getOneUser: "/account/getOneUser",
    // getAllUser: "/account/list",
    // getSubscriberList: "/account/getSubscriberList",
    // resetPassword: "/account/resetPassword",
    // updateInfo: "/account/updateInfo",
    // downloadInvoice: "/account/downloadInvoice",

    getOneUser: "/account/getOneUser",
    getAllUser: "/account/list",
    getSubscriberList: "/account/getSubscriberList",
    resetPassword: "/account/resetPassword",
    updateInfo: "/account/updateInfo",
    downloadInvoice: "/account/downloadInvoice",
    resetPasswordLink: "/account/resetPasswordLink",
    resetForgotPasswordInLink: "/account/resetForgotPasswordInLink",
  },
  course: {
    createCourse: "/course/create",
    editCourse: "/course/update",
    getList: "/course/list",
    getOnevalue: "/course/getOnevalue",
    updateSelfPaced: "/course/updateSelfPacedInfo",
    updateInstructorLedInfo: "/course/updateInstructorLedInfo",
    addInstructorLedSchedule: "/course/addInstructorLedSchedule",
    updateInstructorLedSchedule: "/course/updateInstructorLedSchedule",
    updateClassRoomInfo: "/course/updateClassRoomInfo",
    addClassRoomSchedule: "/course/addClassRoomSchedule",
    updateClassRoomSchedule: "/course/updateClassRoomSchedule",
    changeStatus: "/course/changeStatus",
    changeClassStatus: "/course/changeClassStatus",
    deleteCourse: "/course/delete",
    uploadCourseMaterial: "/course/uploadCourseMaterial",
    downloadMaterial: "/course/downloadMaterial",
    downloadSample: "/course/downloadSample",
    downloadClassroomSample: "/course/downloadClassroomSample",
    uploadOnlineInstructor: "/course/uploadOnlineInstructor",
    uploadClassroom: "/course/uploadClassroom",
    deleteCourseMaterial: "/course/deleteCourseMaterial",
  },
  // courseContent: {
  //   createCourseContent: "/content/create",
  //   updateCourseContent: "/content/update",
  //   getCourseContent: "/content/details",
  //   changeOrderSequence: "/content/changeOrderSequence",
  //   deleteContent: "/content/deleteContent",

  // },
  courseContent: {
    createCourseContent: "/content/addContentToChapter",
    updateCourseContent: "/content/update",
    getCourseContent: "/content/details",
    changeOrderSequence: "/content/changeOrderSequence",
    deleteContent: "/content/deleteContent",
    createChapter: "/content/createChapter",
    updateChapter: "/content/updateChapterDetails",
    deleteChapter: "/content/deleteChapter",
    deleteChapterMaterial: "/content/deleteChapterMaterial",
  },

  enquiry: {
    getEnquiry: "/enquiry/list",
    markSeen: "/enquiry/update",
  },
  coupon: {
    create: "/coupan/create",
    getList: "/coupan/list",
    editCoupon: "/coupan/update/",
    delCoupan: "/coupan/deleteCoupan/",
  },
  payment: {
    getAllPaidPayments: "/payment/getAllPaidPayments",
  },
};
