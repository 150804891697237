import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  styled,
  Box,
  Grid,
  Paper,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";

// apis
import { get } from "../../../api/apiMethods";
import { urls } from "../../../api/urlConstants";

const Date = styled(Box)(() => ({
  gap: 6,
  display: "flex",
}));

const Heading = styled(TableCell)(() => ({
  fontWeight: "bold",
  textAlign: "center",
  fontSize: "15px",
}));

const Data = styled(TableCell)(() => ({
  textAlign: "center",
  fontSize: "large",
}));

const Invoice = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [error, setError] = useState("");
  const [loadingFined, setLoadingFind] = useState(false);
  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndStartDate = (e) => {
    setEndDate(e.target.value);
  };
  const getInvoices = async () => {
    try {
      const response = await get(`${urls.payment.getAllPaidPayments}`);

      setFilteredInvoices(response.data.result);
      console.log(response, "kkk");
    } catch (error) {
      console.log(error);
      setLoadingFind(false);
    }
  };
  useEffect(() => {
    getInvoices();
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingFind(true);

    try {
      const response = await get(`${urls.payment.getAllPaidPayments}`);
      const filteredResult = response.data.result.filter(
        (m) => m.createdAt >= startDate && m.createdAt <= endDate
      );

      setFilteredInvoices(filteredResult);
      console.log(filteredResult, "kjkjkjk");
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingFind(false);
    }
  };
  const handleClear = () => {
    setStartDate("");
    setEndDate("");
    getInvoices();
  };

  const handleInvoiceDownload = async (invoiceData, id) => {
    setLoading((prev) => ({
      ...prev,
      [id]: true,
    }));

    try {
      const res = await get(`${urls.user.downloadInvoice}/${id}`, {
        responseType: "blob",
        params: {
          email: invoiceData.customerDetails.email,
        },
      });

      if (res) {
        const downloadURL = URL.createObjectURL(res.data);
        window.open(downloadURL, "__blank");
        setLoading((prev) => ({
          ...prev,
          [id]: false,
        }));
      }
      // setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading((prev) => ({
        ...prev,
        [id]: false,
      }));
    }
  };

  return (
    <>
      <Box sx={{ padding: "10px 30px" }}>
        <Typography
          sx={{
            textAlign: "left",
            fontWeight: "bold",
            fontSize: "1.5rem",
            color: (theme) => theme.palette.primary.main,
            margin: "10px 0 30px 10px",
          }}
        >
          Invoices
        </Typography>
        <Grid container justifyContent="flex-start" my={2}>
          <Grid item xs={12} md={4}>
            <form onSubmit={handleSubmit}>
              <Date>
                <Box>
                  <label htmlFor="start-date" style={{ paddingLeft: "10px" }}>
                    Start Date:
                  </label>
                  <br />
                  <TextField
                    type="date"
                    id="start-date"
                    value={startDate}
                    onChange={handleStartDate}
                    size="small"
                    sx={{ mt: 1 }}
                  />
                </Box>
                <Box>
                  <label htmlFor="end-date" style={{ paddingLeft: "10px" }}>
                    End Date:
                  </label>
                  <br />
                  <TextField
                    type="date"
                    id="end-date"
                    value={endDate}
                    onChange={handleEndStartDate}
                    size="small"
                    sx={{ mt: 1 }}
                  />
                </Box>
                <Box mt={4}>
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    disabled={!startDate || !endDate}
                    loading={loadingFined}
                  >
                    Find
                  </LoadingButton>
                </Box>
                <Box mt={4}>
                  {" "}
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={!startDate || !endDate}
                    onClick={handleClear}
                  >
                    Clear
                  </Button>{" "}
                </Box>
              </Date>
            </form>

            {error && <Typography color="error">{error}</Typography>}
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <Heading>Invoice Number</Heading>
                <Heading>Date</Heading>
                <Heading>Amount</Heading>
                <Heading>Action</Heading>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredInvoices.length === 0 ? (
                <TableRow>
                  <TableCell align="center" colSpan={4}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      height={200}
                    >
                      <Typography variant="h6">No data found</Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              ) : (
                filteredInvoices.map((invoice) => (
                  <TableRow key={invoice._id} component={Paper} elevation={3}>
                    <Data>{invoice.orderId}</Data>
                    <Data>{dayjs(invoice.createdAt).format("YYYY-MM-DD")}</Data>
                    <Data>{invoice.amount}</Data>
                    <Data>
                      <LoadingButton
                        variant="contained"
                        type="submit"
                        loading={loading[invoice._id]}
                        onClick={() =>
                          handleInvoiceDownload(invoice, invoice._id)
                        }
                      >
                        View Invoice
                      </LoadingButton>
                    </Data>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default Invoice;
