import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  styled,
  MenuItem,
  Select,
  Typography,
  TextField,
} from "@mui/material";
import React from "react";
import Checkbox from "@mui/material/Checkbox";
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const UsageRestriction = () => {
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column", // to stack items vertically
          gap: "10px",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <Typography>Minimum spend</Typography>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <TextField
              type="number"
              placeholder="Minimum spend"
              name="value"
              size="small"
              value=""
            />
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <Typography>Maximum spend</Typography>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <TextField
              type="number"
              placeholder="Maximum spend"
              name="value"
              size="small"
              value=""
            />
          </FormControl>
        </Box>
                {/* <Box sx={{ display: "flex", gap: 2,alignItems:"center" }}>
        <Typography>Individual Use Only</Typography>
        <Checkbox {...label} />
        <Typography>
          Check this box if the coupon cannot be used in conjunction with other
          coupons.
        </Typography>
        </Box> */}
        <Box sx={{ display: "flex",justifyContent:"flex-start", gap: 2, alignItems: "center" }}>
          <Typography>Products</Typography>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <TextField
              type="number"
              placeholder="Products"
              name="value"
              size="small"
              value=""
            />
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <Typography>Exclude products</Typography>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <TextField
              type="number"
              placeholder="Exclude products"
              name="value"
              size="small"
              value=""
            />
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <Typography>Product categories</Typography>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <TextField
              type="number"
              placeholder="Product categories"
              name="value"
              size="small"
              value=""
            />
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <Typography> Exclude categories</Typography>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <TextField
              type="number"
              placeholder=" Exclude categories"
              name="value"
              size="small"
              value=""
            />
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <Typography>Allowed emails</Typography>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <TextField
              type="number"
              placeholder="Allowed emails"
              name="value"
              size="small"
              value=""
            />
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};

export default UsageRestriction;
