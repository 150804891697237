import {
  Box,
  Container,
  Grid,
  Button,
  Typography,
  InputLabel,
  TextField,
  styled,
  Modal,
} from "@mui/material";
import React, { useState, useRef, useContext } from "react";
import ChapterData from "./ChapterData";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const style = {
  width: "70%",

  border: "2px solid white",
  boxShadow: 24,
  bgcolor: "background.paper",
  p: 4,
};
const InputLabels = styled(InputLabel)(() => ({
  fontSize: "12px",
  fontWeight: 580,
  color: "#616161",
}));
const Buttons = styled(Typography)(() => ({
  backgroundColor: "#e0e0e0",
  color: "black",
  border: "1px solid e0e0e0",
  borderRadius: 5,
  fontSize: "12px",
  height: "35px",
  width: "200px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  boxShadow: 3,
  textTransform: "uppercase",
}));
const Buttons1 = styled(Button)(() => ({
  backgroundColor: "#e0e0e0",
  color: "black",
  border: "1px solid e0e0e0",
  borderRadius: 5,
  fontSize: "12px",
  height: "35px",
  width: "200px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  boxShadow: 3,
  textTransform: "uppercase",
  marginTop: "2px",
  "&:hover": {
    backgroundColor: "#e0e0e0",
  },
}));

const AddChapter = () => {
  let dataName = useContext(ChapterData);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [videoFile, setVideoFile] = useState(null);
  const fileInputRef = useRef(null);
  const location = useLocation();

  const { selectedCourse } = location.state || {};

  let [name, setName] = useState("");

  const handleClose = () => setOpen(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setVideoFile(URL.createObjectURL(file));
  };
  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    dataName.setNamedata([...dataName.namedata, name]);

    setName("");
    setVideoFile("");
    navigate("/upload-courses/adding", {
      state: { resetData: selectedCourse },
    });
  };

  return (
    <>
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClose={handleClose}>
          <Box sx={style}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontWeight: "bolder",
                      fontSize: "20px",
                    }}>
                    Add Chapters for Course:{selectedCourse}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabels>Chapter Name</InputLabels>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <TextField
                      fullWidth
                      size="small"
                      value={name}
                      onChange={(e) => setName(e.target.value)}></TextField>
                    <Buttons1 onClick={handleUploadButtonClick}>
                      Upload Video
                    </Buttons1>
                    <input
                      type="file"
                      accept="video/*"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                      ref={fileInputRef}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  {videoFile && <video src={videoFile} width="300" controls />}
                </Grid>
                <Grid item xs={12} md={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "3%",
                    }}>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => navigate(-1)}>
                      Cancel
                    </Button>

                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        background: "#747DE8",
                        "&:hover": {
                          background: "#747DE8",
                        },
                      }}>
                      Submit
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default AddChapter;
