import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  styled,
  MenuItem,
  Select,
  Typography,
  TextField,
} from "@mui/material";
import React from "react";
const Label = styled("label")(() => ({
  fontWeight: "bold",
}));


const General = () => {
      const [age, setAge] = React.useState("");

      const handleChange = (event) => {
        setAge(event.target.value);
      };
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column", // to stack items vertically
          gap: "10px",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <Typography>Discount Type</Typography>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <Select
              id="demo-select-small"
              value={age}
              sx={{
                minWidth: { md: 227, xs: "100%" },
              }}
              onChange={handleChange}>
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={"Percentage"}>Percentage discount</MenuItem>
              <MenuItem value={"Flat"}>Fixed Cart discount</MenuItem>
              <MenuItem value={"Fixed"}>Fixed Product discount</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <Typography>Coupon Amount</Typography>
          <FormControl sx={{ m: 1 }} size="small">
            <TextField
              required
              variant="outlined"
              placeholder="Coupon Amount"
              label="Coupon Amount"
              name="value"
              size="small"
              value=""
              sx={{
                minWidth: { md: 227, xs: "100%" },
              }}
            />
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <Typography>Coupon expiry date</Typography>
          <TextField
            size="small"
            name="expiryDate"
            type="date"
            sx={{
              minWidth: { md: 227, xs: "100%" },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default General;
