import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  styled,
  Stack,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import "react-quill/dist/quill.snow.css";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormInput from "./FormInput";
import ReactQuillInput from "./ReactQuillInput";
import { post, put, get } from "../api/apiMethods";
import { urls } from "../api/urlConstants";
import FormSelect from "./FormSelect";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { LoadingButton } from "@mui/lab";
import { theme } from "../theme";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { toast } from "react-toastify";
import ProgrammName from "../utils/data";
const Label = styled("label")(() => ({
  fontWeight: "bold",
  fontSize: "14px",
  paddingLeft: "10px",
}));
const Wrapper = styled(Box)(() => ({
  padding: "10px",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

const Title = styled(Typography)(() => ({
  fontWeight: "bold",
  fontSize: "20px",
  textAlign: "left",
  marginLeft: "10px",
}));

const FormikError = styled(Typography)(() => ({
  color: "#d32f2f",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  fontSize: "14px",
  marginTop: "4px",
}));

const InputContainer = styled(Box)(() => ({
  flexDirection: "column",
  marginTop: "20px",
  paddingBottom: "40px",
  maxWidth: "100%",
}));

const InputLabel = styled(Typography)(() => ({
  // fontWeight: "bold",
  // color: theme.palette.primary.main,
  // fontSize: "20px",
  // marginBottom: "10px",
  fontWeight: "bold",
  fontSize: "14px",
  // paddingLeft: "10px",
}));

const Buttons1 = styled(Button)(() => ({
  backgroundColor: "#e0e0e0",
  color: "black",
  border: "1px solid e0e0e0",
  borderRadius: 5,
  fontSize: "10px",
  height: "40px",
  width: "100px",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  marginTop: "2px",
  "&:hover": {
    backgroundColor: "#e0e0e0",
  },
}));

export default function AddCourse({
  edit,
  formData,
  setEditCourse,
  history,
  courses = [],
}) {
  const fileInputRef = React.useRef(null);
  const [imageFile, setImageFile] = React.useState(
    formData && formData.thumbnailImage ? formData.thumbnailImage : null
  );
  const [selectedImage, setSelectedImage] = useState(null);
  const [list, setList] = useState([]);
  const [menuList, setMenuList] = useState([]);
  const [programmeDates, setProgrammeDates] = useState(
    formData && formData.programmeDates ? formData.programmeDates : []
  );
  const [isPaid, setIsPaid] = useState(
    formData && formData.isPaid ? formData.isPaid : false
  );

  const [isFree, setIsFree] = useState(
    formData && formData.isFree ? formData.isFree : false
  );
  useEffect(() => {
    if (formData && Object.keys(formData).length) {
      setImageFile(formData.thumbnailImage);
    }
  }, [formData]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };
  // console.log(formData, "sssssss");
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
    setImageFile(URL.createObjectURL(file));
    formik.setFieldError("thumbnailImage", "");
  };
  console.log(selectedImage, "===selectedImage==s");
  const formik = useFormik({
    initialValues: {
      title: edit ? formData.title : "",
      menu: edit ? formData.menu.name : "",
      pdu: edit ? formData.pdu : "",
      highlights: edit ? formData.highlights : "",
      description: edit ? formData.description : "",
      overview: edit ? formData?.overview : "",
      keyFeatures: edit ? formData?.keyFeatures : "",
      courseOutline: edit ? formData?.courseOutline : "",
      benefits: edit ? formData?.benefits : "",
      whoIsItFor: edit ? formData?.whoIsItFor : "",
    },
    validationSchema: Yup.object({
      highlights: Yup.string().required().label("HighLights"),
      description: Yup.string().required().label("Description"),
      menu: Yup.string().required().label("Category Name"),
      overview: Yup.string().required().label("Overview"),
      keyFeatures: Yup.string().required().label("Key Features"),
      courseOutline: Yup.string().required().label("Couse Details"),
      whoIsItFor: Yup.string().required().label("Who Should Attend"),
      benefits: Yup.string().required().label("Benefits"),
      title: Yup.string().required().label("Title").max(50),
      pdu: Yup.number().nullable().label("Pdu"),
    }),
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (!imageFile) {
        return toast.error("PLEASE SELECT A THUMBNAIL IMAGE");
      }
      setLoading(true);
      try {
        let menuId;
        if (edit) {
          // menuId = values.menu;
          menuId = menuList.filter((m) => m?.name === values?.menu)[0]?._id;
        } else {
          menuId = menuList.filter((m) => m?.name === values?.menu)[0]?._id;
        }
        const body = {
          highlights: values.highlights,
          description: values.description,
          title: values.title,
          pdu: values.pdu,
          menu: menuId,
          isPaid,
          isFree,
          programmeDates: programmeDates,
          courseDetails: {
            overview: values.overview,
            keyFeatures: values.keyFeatures,
            courseOutline: values.courseOutline,
            whoIsItFor: values.whoIsItFor,
            benefits: values.benefits,
          },
        };
        const formsData = new FormData();
        formsData.append("bodyData", JSON.stringify(body));
        formsData.append("file", selectedImage);
        if (!edit) {
          const { data } = await post(
            `${urls.course.createCourse}/${menuId}`,
            formsData
          );
          navigate(history ? history : "/products");
        } else {
          const { data } = await put(
            `${urls.course.editCourse}/${edit}`,
            formsData
          );
          // console.log(data, "data");
          navigate(history ? history : "/products");
        }
        // formik.resetForm();
        setEditCourse(null);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
    validateOnBlur: false,
  });

  // console.log(formData, "----errrr");
  // console.log(setEditCourse, "setEditCourse");

  useEffect(() => {
    const getMenu = async () => {
      try {
        const { data, status } = await get(`${urls.menu.getList}`);
        setMenuList(data.result.data);
        setList(
          data.result.data.map((m) => ({ label: m.name, value: m.name }))
        );
      } catch (error) {
        console.log(error);
      }
    };
    getMenu();
  }, []);

  // console.log(window.history, "ispaiddddddddddddd");

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} sx={{ p: 4, mb: 1 }} maxWidth="xl">
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={12}
            display="flex"
            justifyContent="start"
            alignItems="center"
            textAlign="center"
          >
            <Button
              variant="text"
              size="medium"
              startIcon={<ArrowBackIcon />}
              onClick={() =>
                !setEditCourse
                  ? navigate(history ? history : "/products")
                  : setEditCourse(null)
              }
              sx={{ color: theme.palette.primary.main }}
            >
              Back
            </Button>
            <Title sx={{ m: "15px" }}>
              {edit ? "Update Course" : "Add Course"}
            </Title>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Label htmlFor="">Category</Label>
            <br />
            <FormSelect
              formik={formik}
              name="menu"
              type="text"
              options={list}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Label htmlFor="productName">Course Name</Label>
            <br />
            <FormInput
              size="small"
              name="title"
              placeholder="course name"
              required
              fullWidth
              formik={formik}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Label htmlFor="pdu">PDU</Label>
            <br />
            <FormInput
              size="small"
              name="pdu"
              placeholder="pdu"
              fullWidth
              formik={formik}
              type="number"
            />
          </Grid>
          {/* <Grid item xs={12} sm={6} md={3}>
            <Label htmlFor="duration">Duration(in hrs)</Label>
            <br />
            <FormInput
              size="small"
              name="courseHours"
              type="number"
              placeholder="duration"
              required
              fullWidth
              formik={formik}
            />
          </Grid> */}
          <Grid item xs={12} sm={6} md={6}>
            <FormControl fullWidth required size="small">
              <InputLabel>Suported Programme</InputLabel>
              <Select
                label="Suported Programme"
                id="demo-simple-select-filled"
                value={programmeDates}
                name="programmeDates"
                size="small"
                onChange={(e) => setProgrammeDates(e.target.value)}
                multiple
              >
                <MenuItem value={"onlineInstructorLed"}>
                  {ProgrammName["Online Instructor"]}
                </MenuItem>
                <MenuItem value={"classRoom"}>
                  {ProgrammName["Classroom"]}
                </MenuItem>
                <MenuItem value={"selfPacedInfo"}>
                  {ProgrammName["Online Self Paced"]}
                </MenuItem>
                <MenuItem value={"onsite"}>{ProgrammName["Onsite"]}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            {/* <Label htmlFor="duration">Course Thumbnail</Label> */}

            {imageFile && (
              <>
                <img
                  src={imageFile}
                  alt="Flashcard"
                  style={{ width: "100px", marginBottom: "20px" }}
                />
              </>
            )}
            <br />

            {/* <Buttons1 onClick={handleUploadButtonClick}>Select Image</Buttons1> */}
            <Button
              onClick={handleUploadButtonClick}
              variant="outlined"
              size="small"
              startIcon={<UploadFileIcon />}
            >
              Upload Thumbnail
            </Button>
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: "none" }}
              ref={fileInputRef}
            />
            {formik.errors.thumbnailImage && (
              <Typography color="error" variant="caption" sx={{ mt: 1 }}>
                {formik.errors.thumbnailImage}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Box sx={{ py: 2 }}>
              <InputLabel sx={{ paddingLeft: "10px" }}>
                Short Description
              </InputLabel>
              {/* <br /> */}
              <FormInput
                size="small"
                name="description"
                placeholder="description"
                required
                fullWidth
                formik={formik}
                // multiline={true}
                inputProps={{
                  maxLength: 200,
                }}
              />
              <InputContainer mt={2}>
                <InputLabel>Highlights</InputLabel>

                <ReactQuillInput
                  theme="snow"
                  name="highlights"
                  formik={formik}
                />
              </InputContainer>
              <FormikError variant="body">
                {formik.errors.highlights}
              </FormikError>
              <InputContainer>
                <InputLabel>OverView</InputLabel>

                <ReactQuillInput theme="snow" name="overview" formik={formik} />
              </InputContainer>
              <FormikError variant="body">{formik.errors.overview}</FormikError>
              <InputContainer>
                <InputLabel>Key Features</InputLabel>

                <ReactQuillInput
                  theme="snow"
                  name="keyFeatures"
                  formik={formik}
                />
              </InputContainer>
              <FormikError variant="body">
                {formik.errors.keyFeatures}
              </FormikError>
              <InputContainer>
                <InputLabel>Course Outline</InputLabel>

                <ReactQuillInput
                  theme="snow"
                  name="courseOutline"
                  formik={formik}
                />
              </InputContainer>
              <FormikError variant="body">
                {formik.errors.courseOutline}
              </FormikError>
              <InputContainer>
                <InputLabel>Benefits</InputLabel>

                <ReactQuillInput theme="snow" name="benefits" formik={formik} />
              </InputContainer>
              <FormikError variant="body">{formik.errors.benefits}</FormikError>
              <InputContainer>
                <InputLabel>Who Should Attend</InputLabel>

                <ReactQuillInput
                  theme="snow"
                  name="whoIsItFor"
                  formik={formik}
                />
              </InputContainer>
              <FormikError variant="body">
                {formik.errors.whoIsItFor}
              </FormikError>
              <Box style={{ display: "flex", gap: "1%" }}>
                {/* <label style={{ display: "flex", marginTop: "15px" }}>
                  <input
                    type="checkbox"
                    name="isPaid"
                    checked={isPaid}
                    onChange={(e) => {
                      setIsPaid(e.target.checked);
                    }}
                  />

                  <span style={{ paddingLeft: "5px" }}> Is Paid Course?</span>
                </label> */}

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isPaid}
                      name="isPaid"
                      onChange={(e) => {
                        setIsPaid(e.target.checked);
                      }}
                      color="primary" // You can change the color if needed
                    />
                  }
                  label="Is Paid Course?"
                  style={{ display: "flex", marginTop: "15px" }}
                />

                {/* <label style={{ display: "flex", marginTop: "15px" }}>
                  <input
                    type="checkbox"
                    name="isFree"
                    checked={isFree}
                    onChange={(e) => {
                      setIsFree(e.target.checked);
                    }}
                  />

                  <span style={{ paddingLeft: "5px" }}> Is Free Course?</span>
                </label> */}
              </Box>

              <Stack
                direction="row"
                spacing={2}
                style={{
                  display: "flex",
                  // justifyContent: "center",
                  alignItems: "center",
                  paddingTop: "30px",
                }}
              >
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() =>
                    !setEditCourse
                      ? navigate(history ? history : "/products")
                      : setEditCourse(null)
                  }
                >
                  Cancel
                </Button>
                <LoadingButton
                  variant="contained"
                  type="submit"
                  sx={{
                    background: theme.palette.primary.main,
                    "&:hover": {
                      background: theme.palette.primary.main,
                    },
                  }}
                  loading={loading}
                >
                  Submit
                </LoadingButton>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
