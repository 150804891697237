import React, { useContext, useState } from "react";
import {
  Box,
  Container,
  Grid,
  Button,
  Typography,
  InputLabel,
  TextField,
  styled,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import Radio from "@mui/material/Radio";
import ChapterData from "./ChapterData";
import { useNavigate } from "react-router-dom";

const label = { inputProps: { "aria-label": "Checkbox demo" } };
const style = {
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid white",
  boxShadow: 24,
  p: 4,
};

const InputLabels = styled(InputLabel)(() => ({
  fontSize: "12px",
  fontWeight: 580,
  color: "#616161",
}));

const RadioButtons = styled(Radio)(() => ({
  marginTop: "-10px",
}));
const Boxes = styled(Box)(() => ({
  display: "flex",
}));
const AddQuiz = () => {
  let quizName = useContext(ChapterData);
  const navigate = useNavigate();
  const [value, setValue] = React.useState("");
  const [formCount, setFormCount] = useState(1);
  const location = useLocation();

  const { data } = location.state || {};

  const [quiz, setQuiz] = useState({
    question: "",
    option1: "",
    option2: "",
    option3: "",
    option4: "",
  });

  let { question, option1, option2, option3, option4 } = quiz;

  const handleAddQuiz = () => {
    setFormCount(formCount + 1);
  };
  const handleCancel = () => {
    setFormCount(formCount - 1);
  };
  //radio selection
  const handleChange = (event) => {
    setValue(event.target.value);
    let name = event.target.name;
    let value = event.target.value;
    setQuiz({
      ...quiz,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    quizName.setQuizData([...quizName.quizData, quiz]);
    setQuiz("");
    navigate("/upload-courses/adding", {
      state: { resetData: data },
    });
  };
  let a = 1;

  const renderForms = () => {
    const forms = [];
    for (let i = 0; i < formCount; i++) {
      forms.push(
        <Grid container spacing={2} key={i} sx={{ marginTop: 2 }}>
          <Grid item xs={12} md={12}>
            <InputLabels>{a++}. Quiz Question</InputLabels>
            <TextField
              fullWidth
              size="small"
              name="question"
              value={question}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Boxes>
              <InputLabels>Option1</InputLabels>
              <RadioButtons
                {...label}
                size="small"
                color="default"
                checked={value === "option1"}
                onChange={handleChange}
                value="option1"
                type="radio"
              />
            </Boxes>
            <TextField
              fullWidth
              size="small"
              name="option1"
              value={option1}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Boxes>
              <InputLabels>Option2</InputLabels>
              <RadioButtons
                {...label}
                size="small"
                color="default"
                checked={value === "option2"}
                onChange={handleChange}
                value="option2"
                type="radio"
              />
            </Boxes>
            <TextField
              fullWidth
              size="small"
              name="option2"
              value={option2}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Boxes>
              <InputLabels>Option3</InputLabels>
              <RadioButtons
                {...label}
                size="small"
                color="default"
                checked={value === "option3"}
                onChange={handleChange}
                value="option3"
                type="radio"
              />
            </Boxes>
            <TextField
              fullWidth
              size="small"
              name="option3"
              value={option3}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Boxes>
              <InputLabels>Option4</InputLabels>
              <RadioButtons
                {...label}
                size="small"
                color="default"
                checked={value === "option4"}
                onChange={handleChange}
                value="option4"
                type="radio"
              />
            </Boxes>
            <TextField
              fullWidth
              size="small"
              name="option4"
              value={option4}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      );
    }

    return forms;
  };

  return (
    <>
      <Container>
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box sx={style}>
              <Typography sx={{ textAlign: "center", fontWeight: "bolder" }}>
                Add Quiz
              </Typography>
              {renderForms()}
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      marginTop: "5px",
                    }}
                  >
                    <Button
                      size="small"
                      variant="contained"
                      onClick={handleAddQuiz}
                    >
                      Add Question
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "3%",
                    }}
                  >
                    <Link to="/upload-courses/adding">
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={handleCancel}
                      >
                        Cancel
                      </Button>
                    </Link>
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{
                        background: "#747DE8",
                        "&:hover": {
                          background: "#747DE8",
                        },
                      }}
                    >
                      Submit
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </form>
      </Container>
    </>
  );
};

export default AddQuiz;
