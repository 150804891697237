import React from "react";
import { Box, TextField } from "@mui/material";

function FormInputLogin(props) {
  const {
    label,
    name,
    type,
    formik,
    style,
    disabled,
    fullWidth = true,
    placeholder = "",
    autoComplete = "true",
    inputProps,
    multiline,
  } = props;

  return (
    <Box sx={[{ width: "100%", marginBottom: "10px" }, style]} variant="div">
      <TextField
        error={formik.touched[name] && formik.errors[name] ? true : false}
        value={formik.values[name] || (type == "number" ? Number("") : "")}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        id={name}
        disabled={disabled}
        autoComplete={(autoComplete === "true").toString()}
        name={name}
        type={type}
        fullWidth={fullWidth}
        size="small"
        label={label}
        helperText={formik.touched[name] ? formik.errors[name] : ""}
        placeholder={placeholder}
        InputProps={inputProps}
        multiline={multiline}
        sx={{
          "& .MuiInputBase-root": {
            borderRadius: "5px",
            paddingLeft: 0,
            backgroundColor: "white",
            border: "1px solid #f5f5f5",
          },
        }}
      />
    </Box>
  );
}

export default FormInputLogin;
