import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      // main: "#421c52",
      // main: "#17143f",
      main: "#146213",
    },
    secondary: {
      main: "#ce901b",
      // main: "#b0b3ff",
    },
  },
  typography: {
    allVariants: {
      // wordWrap: "break-word",
      // wordBreak: "break-word",
    },
  },
  shape: {
    borderRadius: 20,
  },

  components: {
    MuiButton: {
      styleOverrides: {
        sizeSmall: {
          fontSize: 12,
        },
      },
    },
    MuiIcon: {
      defaultProps: {
        fontSize: "small",
      },
    },
  },
});
