export const settings = [
  {
    id: "1",
    path: "/account-setting",
    name: "Account Settings",
  },
  {
    id: "2",
    path: "/change-password",
    name: "Change Password",
  },
  {
    id: "3",
    path: "/logout",
    name: "Logout",
  },
];
