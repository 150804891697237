import React, { createContext, useContext, useEffect, useState } from "react";
import { Container, Grid, TextField, Button, styled, Box } from "@mui/material";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Expand = styled(TextField)(({ theme }) => ({
  border: "1px solid #f5f5f5",
  borderRadius: "5px",

  // width:'70%',
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));
const Date = styled(Box)(() => ({
  gap: "3%",
  display: "flex",
  // marginTop: '5%'
}));
const Label = styled("label")(() => ({
  fontWeight: "bold",
}));
const style = {
  width: "90%",
  p: 4,
};
const Buttons1 = styled(Button)(() => ({
  backgroundColor: "#e0e0e0",
  color: "black",
  border: "1px solid e0e0e0",
  borderRadius: 5,
  fontSize: "10px",
  height: "35px",
  width: "100px",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  marginTop: "2px",
  "&:hover": {
    backgroundColor: "#e0e0e0",
  },
}));
const CourseDetailsForm = () => {
  const [open, setOpen] = useState(false);
  const [imageFile, setImageFile] = React.useState(null);
  const fileInputRef = React.useRef(null);
  let [formDetails, setFormDetails] = useState([]);
  const { state } = useLocation();
  let [courseData, setCourseData] = useState({
    coursename: "",
    description: "",
    catagory: "",
    trainername: "",
    prize: "",
    thumbnail: "",
  });

  useEffect(() => {
    if (state == "products") {
    }
  }, [state]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCourseData((predata) => ({
      ...predata,
      [name]: value,
    }));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormDetails((prevData) => [...prevData, courseData]);
    setCourseData({
      coursename: "",
      description: "",
      catagory: "",
      trainername: "",
      prize: "",
      thumbnail: "",
    });
    handleClose();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setImageFile(URL.createObjectURL(file));
  };

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };
  return (
    <Container>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Box sx={style}>
          <form onSubmit={handleSubmit} style={{ marginTop: "5%" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Label htmlFor="">Course Name</Label>
                <br />
                <Expand
                  size="small"
                  name="coursename"
                  value={courseData.coursename}
                  required
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Label htmlFor="">Course Description</Label>
                <br />
                <Expand
                  size="small"
                  name="description"
                  value={courseData.description}
                  required
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Label htmlFor="">Course Catagory</Label>
                <br />
                <Expand
                  name="catagory"
                  size="small"
                  value={courseData.catagory}
                  required
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Label htmlFor="">Trainer Name</Label>
                <br />
                <Expand
                  name="trainername"
                  size="small"
                  value={courseData.trainername}
                  required
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Label htmlFor="">Course Thumbnail</Label>
                <Buttons1 onClick={handleUploadButtonClick}>
                  Select Image
                </Buttons1>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  ref={fileInputRef}
                />
                {imageFile && (
                  <img
                    src={imageFile}
                    alt="Flashcard"
                    style={{ width: "100px", marginTop: "5%" }}
                  />
                )}
                <br />
              </Grid>
              <Grid item xs={12} md={4}>
                <Label htmlFor="">Course Prize</Label>
                <br />
                <Expand
                  name="prize"
                  size="small"
                  value={courseData.prize}
                  required
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Label htmlFor="">Course TimeLine</Label>
                <Date>
                  <Box>
                    <label htmlFor="">Start Date:</label>
                    <br />
                    <TextField type="date" variant="filled" size="small" />
                  </Box>
                  <Box>
                    <label htmlFor="">End Date:</label>
                    <br />
                    <TextField type="date" variant="filled" size="small" />
                  </Box>
                </Date>
              </Grid>
              <Grid item xs={12} md={4} sx={{ marginTop: 4 }}>
                <Label htmlFor="">Earlybird Price</Label>
                <br />
                <Expand
                  name="earlybirdprize"
                  size="small"
                  value={courseData.prize}
                  required
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Date sx={{ marginTop: 4 }}>
                  <Box>
                    <label htmlFor="start-date">Start Date:</label>
                    <br />
                    <TextField
                      type="date"
                      id="start-date"
                      variant="filled"
                      size="small"
                    />
                  </Box>
                  <Box>
                    <label htmlFor="end-date">End Date:</label>
                    <br />
                    <TextField
                      type="date"
                      id="end-date"
                      variant="filled"
                      size="small"
                    />
                  </Box>
                </Date>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Link to="/upload-courses/adding">
                  <Button variant="contained" type="submit">
                    Next
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </Container>
  );
};

export default CourseDetailsForm;
