// import {
//   Box,
//   Button,
//   Dialog,
//   Divider,
//   Grid,
//   IconButton,
//   TextField,
//   Tooltip,
//   Typography,
//   useMediaQuery,
//   useTheme,
// } from "@mui/material";
// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
// import DialogTitle from "@mui/material/DialogTitle";
// import React, { useState, useEffect } from "react";
// import { LoadingButton } from "@mui/lab";
// import ClearIcon from "@mui/icons-material/Clear";
// // custom imports

// import UploadCourseButtons from "./UploadCourseButtons";
// import UploadCourseTable from "./UploadCourseTable";

// //Icons
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/Delete";
// import FileUploadIcon from "@mui/icons-material/FileUpload";

// // apis
// import { put } from "../../../api/apiMethods";
// import { urls } from "../../../api/urlConstants";

// export default function ShowUploadTable({
//   chapter,
//   course,
//   updateList = () => {},
// }) {
//   const theme = useTheme();
//   const [open, setOpen] = React.useState(false);
//   const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
//   const [updatingChapter, setUpdatingChapter] = useState(false);
//   const [openChapter, setOpenChapter] = useState(false);
//   const [title, setTitle] = useState(chapter.title);
//   const [material, setMaterial] = useState();
//   const [description, setDescription] = useState(chapter.description);
//   const [selectedFile, setSelectedFile] = useState(null);
//   const [fileName, setFileName] = useState("");

//   const [materialURL, setMaterialURL] = useState();

//   console.log(material, "pppppppppppppppppppppppppp");

//   const handleChapterClose = () => {
//     setOpenChapter(false);
//     setFileName("");
//     setTitle(chapter.title ? chapter.title : "");
//   };
//   useEffect(() => {
//     const getMaterial = chapter.material;
//     if (getMaterial) {
//       const materialURLs = new URL(getMaterial);
//       const fileName = decodeURIComponent(
//         materialURLs.pathname.split("/").pop()
//       );
//       setMaterial(fileName);
//       setMaterialURL(getMaterial);
//     }
//   }, [chapter]);
//   const handleUpdateChapter = async () => {
//     setUpdatingChapter(true);

//     try {
//       const body = {
//         chapterId: chapter._id,
//         title,
//         material,
//       };
//       const formsData = new FormData();
//       console.log(body, "body");
//       formsData.append("body", JSON.stringify(body));
//       formsData.append("file", selectedFile);
//       console.log(selectedFile, "uuuuuuuuuuuuuuuuuu");
//       const { data } = await put(
//         `${urls.courseContent.updateChapter + "/" + course.courseId._id}`,
//         formsData
//       );
//       updateList();
//       setOpenChapter(false);
//       setFileName("");
//     } catch (error) {
//       console.log(error);
//     }
//     setUpdatingChapter(false);
//   };

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };
//   const handleDeleteChapter = async () => {
//     try {
//       const { data } = await put(
//         urls.courseContent.deleteChapter + "/" + course.courseId._id,
//         { chapterId: chapter._id }
//       );
//       updateList();
//       handleClose();
//     } catch (error) {
//       console.log(error);
//       handleClose();
//     }
//   };
//   const handleFileChange = (e) => {
//     const selectedFile = e.target.files[0];
//     setSelectedFile(selectedFile);
//     setFileName(selectedFile.name);
//   };
//   console.log(fileName, "ppppppppppppppooooooooooooooooooooo");
//   const handleDownload = () => {
//     try {
//       window.open(materialURL, "_blank");
//     } catch (error) {
//       console.error(error);
//     }
//   };
//   const handleDeleteMaterial = async (e) => {
//     e.preventDefault();
//     try {
//       const res = await put(
//         `${urls.courseContent.deleteChapterMaterial}/${course.courseId._id}`,
//         { chapterId: chapter._id }
//       );
//       setMaterial(null);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   return (
//     <>
//       <Box
//         sx={{
//           border: "1px solid lightgray",
//           borderRadius: "20px",
//           px: 1,
//           mb: 3,
//           backgroundColor: theme.palette.grey[100],
//         }}>
//         <Grid container>
//           <Grid
//             item
//             xs={12}
//             sm={12}
//             md={6}
//             sx={{
//               display: "flex",
//               justifyContent: "space-between",
//               alignItems: "center",
//               p: 2,
//             }}>
//             <Typography
//               width="100%"
//               variant="h6"
//               component="div"
//               fontWeight={600}
//               fontSize={16}
//               display="flex"
//               alignItems="center">
//               <Tooltip title="delete Chapter">
//                 <IconButton onClick={handleClickOpen} size="small">
//                   <DeleteIcon
//                     fontSize="small"
//                     color="error"
//                     style={{
//                       marginRight: "5px",
//                     }}
//                   />
//                 </IconButton>
//               </Tooltip>
//               <Tooltip title="update Chapter">
//                 <IconButton onClick={() => setOpenChapter(true)} size="small">
//                   <EditIcon
//                     fontSize="small"
//                     style={{
//                       marginRight: "5px",
//                       color: `${theme.palette.primary.main}`,
//                     }}
//                   />
//                 </IconButton>
//               </Tooltip>
//               Chapter: {chapter.title}
//             </Typography>
//           </Grid>
//           <Grid item xs={6} sm={6} md={6} mt={{ xs: 0, sm: 1, md: 2 }}>
//             <UploadCourseButtons
//               updateList={updateList}
//               courseId={course.courseId}
//               chapter={chapter}
//             />
//           </Grid>
//           <Grid item xs={12} sm={12} md={12}>
//             {/* <RecevedData courseId={course.courseId} chapter={chapter} /> */}
//             <UploadCourseTable
//               updateList={updateList}
//               courseId={course.courseId}
//               chapter={chapter}
//             />
//           </Grid>
//         </Grid>
//       </Box>
//       <Dialog
//         fullScreen={fullScreen}
//         open={openChapter}
//         onClose={handleChapterClose}
//         aria-labelledby="responsive-dialog-title">
//         <Grid container rowSpacing={2} p={2}>
//           <Grid item xs={12} md={12}>
//             <Typography
//               sx={{
//                 textAlign: "center",
//                 fontSize: "18px",
//               }}>
//               Update Chapter Details:
//               <Typography
//                 sx={{
//                   textAlign: "center",
//                   fontWeight: "bolder",
//                   fontSize: "20px",
//                 }}>
//                 {chapter.title}
//               </Typography>
//             </Typography>
//           </Grid>
//           <Grid
//             item
//             xs={12}
//             md={12}
//             sx={{
//               textAlign: "center",
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "space-between",
//               columnGap: 2,
//             }}>
//             {/* <InputLabels>Chapter Name</InputLabels> */}
//             <TextField
//               fullWidth
//               size="small"
//               name="title"
//               value={title}
//               placeholder="Enter Chapter Name"
//               onChange={(e) => setTitle(e.target.value)}
//             />
//             <Tooltip title="Upload Material">
//               <Button
//                 component="label"
//                 variant="outlined"
//                 fullWidth
//                 style={{
//                   maxWidth: 200,
//                   padding: "7px",
//                 }}
//                 startIcon={<FileUploadIcon fontSize="small" />}
//                 size="small">
//                 update material
//                 <input
//                   hidden
//                   type="file"
//                   onChange={handleFileChange}
//                   style={{ display: "block" }}
//                 />
//               </Button>
//             </Tooltip>
//           </Grid>
//           <Typography>{fileName}</Typography>
//           <Typography sx={{ mt: 1 }}>{material}</Typography>
//           {material ? (
//             <Button onClick={handleDeleteMaterial}>
//               <ClearIcon />
//             </Button>
//           ) : (
//             ""
//           )}
//           <Grid
//             sx={{
//               display: "flex",
//               width: "100%",
//               flexDirection: "column",
//               justifyContent: "center",
//               my: 1,
//             }}
//             item
//             xs={12}
//             md={12}>
//             <Box
//               sx={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 gap: 2,
//                 alignSelf: "center",
//                 flex: 1,
//                 width: "100%",
//               }}>
//               {/* <Button
//                 variant="outlined"
//                 color="error"
//                 onClick={handleChapterClose}
//                 sx={{ width: "45%" }}>
//                 Cancel
//               </Button> */}
//               {material ? (
//                 <Button
//                   size="small"
//                   sx={{
//                     width: "45%",
//                   }}
//                   variant="outlined"
//                   onClick={handleDownload}>
//                   download
//                 </Button>
//               ) : (
//                 <Button
//                   variant="outlined"
//                   color="error"
//                   onClick={handleChapterClose}
//                   sx={{ width: "45%" }}>
//                   Cancel
//                 </Button>
//               )}

//               <LoadingButton
//                 type="submit"
//                 variant="contained"
//                 sx={{
//                   width: "45%",
//                 }}
//                 loading={updatingChapter}
//                 onClick={handleUpdateChapter}>
//                 Update
//               </LoadingButton>
//             </Box>
//           </Grid>
//         </Grid>
//       </Dialog>

//       <Dialog
//         open={open}
//         onClose={handleClose}
//         BackdropProps={{
//           sx: { backgroundColor: "rgba(0,0,0,0.6)" },
//         }}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description">
//         <DialogContent>
//           {/* <DialogContentText id="alert-dialog-description" my={1}>
//               Confirmation
//             </DialogContentText> */}
//           <DialogContentText id="alert-dialog-description" fontWeight={600}>
//             Are you sure you want to delete this chapter?
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose}>No</Button>
//           <Button onClick={handleDeleteChapter} autoFocus>
//             Yes
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* <Dialog
//         fullScreen={fullScreen}
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description"
//       >
//         <DialogContentText id="alert-dialog-title"></DialogContentText>
//         <DialogActions>
//           <Button onClick={handleClose}>No</Button>
//           <Button onClick={handleDeleteChapter} autoFocus>
//             Yes
//           </Button>
//         </DialogActions>
//       </Dialog> */}
//     </>
//   );
// }

import {
  Box,
  Button,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useState, useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import ClearIcon from "@mui/icons-material/Clear";
// custom imports

import UploadCourseButtons from "./UploadCourseButtons";
import UploadCourseTable from "./UploadCourseTable";

//Icons
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileUploadIcon from "@mui/icons-material/FileUpload";

// apis
import { put } from "../../../api/apiMethods";
import { urls } from "../../../api/urlConstants";
import { Download } from "@mui/icons-material";

const DownloadBox = styled(Box)(() => ({
  background: `rgb(133 140 223 / 19%)`,
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: "10px",
}));

export default function ShowUploadTable({
  chapter,
  course,
  updateList = () => {},
}) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [updatingChapter, setUpdatingChapter] = useState(false);
  const [openChapter, setOpenChapter] = useState(false);
  const [title, setTitle] = useState(chapter.title);
  const [material, setMaterial] = useState(null);
  const [description, setDescription] = useState(chapter.description);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState(
    chapter.material
      ? decodeURIComponent(chapter.material.split("/").pop()).replace("%", " ")
      : ""
  );

  const [materialURL, setMaterialURL] = useState();

  const handleChapterClose = () => {
    setOpenChapter(false);
    // setFileName("");
    setTitle(chapter.title ? chapter.title : "");
  };
  useEffect(() => {
    if (chapter.material) {
      setFileName(
        decodeURIComponent(chapter.material.split("/").pop()).replace("%", " ")
      );
    }
  }, [chapter]);

  const handleUpdateChapter = async () => {
    setUpdatingChapter(true);

    try {
      const body = {
        chapterId: chapter._id,
        title,
        material,
      };
      const formsData = new FormData();
      console.log(body, "body");
      formsData.append("body", JSON.stringify(body));
      formsData.append("file", selectedFile);
      console.log(selectedFile, "uuuuuuuuuuuuuuuuuu");
      const { data } = await put(
        `${urls.courseContent.updateChapter + "/" + course.courseId._id}`,
        formsData
      );
      updateList();
      setOpenChapter(false);
      setFileName("");
    } catch (error) {
      console.log(error);
    }
    setUpdatingChapter(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleDeleteChapter = async () => {
    try {
      const { data } = await put(
        urls.courseContent.deleteChapter + "/" + course.courseId._id,
        { chapterId: chapter._id }
      );
      updateList();
      handleClose();
    } catch (error) {
      console.log(error);
      handleClose();
    }
  };
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setSelectedFile(selectedFile);
    setFileName(selectedFile.name);
  };
  const handleDownload = () => {
    try {
      window.open(chapter.material, "_blank");
    } catch (error) {
      console.error(error);
    }
  };
  const handleDeleteMaterial = async (e) => {
    e.preventDefault();
    if (!chapter.material) {
      setSelectedFile(null);
      return setFileName("");
    }
    try {
      const res = await put(
        `${urls.courseContent.deleteChapterMaterial}/${course.courseId._id}`,
        { chapterId: chapter._id }
      );
      setFileName("");
      // setOpenChapter(false);
      updateList();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Box
        sx={{
          border: "1px solid lightgray",
          borderRadius: "20px",
          px: 1,
          mb: 3,
          backgroundColor: theme.palette.grey[100],
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 2,
            }}
          >
            <Typography
              width="100%"
              variant="h6"
              component="div"
              fontWeight={600}
              fontSize={16}
              display="flex"
              alignItems="center"
            >
              <Tooltip title="delete Chapter">
                <IconButton onClick={handleClickOpen} size="small">
                  <DeleteIcon
                    fontSize="small"
                    color="error"
                    style={{
                      marginRight: "5px",
                    }}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="update Chapter">
                <IconButton onClick={() => setOpenChapter(true)} size="small">
                  <EditIcon
                    fontSize="small"
                    style={{
                      marginRight: "5px",
                      color: `${theme.palette.primary.main}`,
                    }}
                  />
                </IconButton>
              </Tooltip>
              Chapter: {chapter.title}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={6} mt={{ xs: 0, sm: 1, md: 2 }}>
            <UploadCourseButtons
              updateList={updateList}
              courseId={course.courseId}
              chapter={chapter}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <UploadCourseTable
              updateList={updateList}
              courseId={course.courseId}
              chapter={chapter}
            />
          </Grid>
        </Grid>
      </Box>
      <Dialog
        id={chapter._id}
        fullScreen={fullScreen}
        open={openChapter}
        onClose={handleChapterClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Grid container rowSpacing={2} p={2}>
          <Grid item xs={12} md={12}>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "18px",
                fontWeight: 600,
              }}
            >
              Update Chapter Details: {chapter.title}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              columnGap: 2,
            }}
          >
            <TextField
              fullWidth
              size="small"
              name="title"
              value={title}
              placeholder="Enter Chapter Name"
              onChange={(e) => setTitle(e.target.value)}
            />
            {!chapter.material ? (
              <Tooltip title="Upload Material">
                <Button
                  component="label"
                  variant="outlined"
                  fullWidth
                  style={{
                    maxWidth: 200,
                    padding: "7px",
                  }}
                  startIcon={<FileUploadIcon fontSize="small" />}
                  size="small"
                >
                  Upload Material
                  <input
                    hidden
                    type="file"
                    onChange={handleFileChange}
                    style={{ display: "block" }}
                  />
                </Button>
              </Tooltip>
            ) : null}
          </Grid>

          {fileName ? (
            <DownloadBox p={1} mt={1.5}>
              <Typography>{fileName}</Typography>
              <Stack direction={"row"}>
                {chapter.material ? (
                  <Button size="small" onClick={handleDownload} color="error">
                    <Download fontSize="small" color="primary" />
                  </Button>
                ) : null}

                <Button
                  size="small"
                  onClick={handleDeleteMaterial}
                  color="error"
                >
                  <ClearIcon fontSize="small" />
                </Button>
              </Stack>
            </DownloadBox>
          ) : (
            ""
          )}

          <Grid
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              justifyContent: "center",
              // my: 1,
            }}
            item
            xs={12}
            md={12}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: 2,
                alignSelf: "center",
                flex: 1,
                width: "100%",
              }}
            >
              <Button
                variant="outlined"
                color="error"
                onClick={handleChapterClose}
                sx={{ width: "45%" }}
              >
                Cancel
              </Button>

              <LoadingButton
                type="submit"
                variant="contained"
                sx={{
                  width: "45%",
                }}
                loading={updatingChapter}
                onClick={handleUpdateChapter}
              >
                Update
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </Dialog>
      {/* Delete Chapter Modal */}
      <Dialog
        open={open}
        onClose={handleClose}
        BackdropProps={{
          sx: { backgroundColor: "rgba(0,0,0,0.6)" },
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description" my={1}>
              Confirmation
            </DialogContentText> */}
          <DialogContentText id="alert-dialog-description" fontWeight={600}>
            Are you sure you want to delete this chapter?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleDeleteChapter} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      {/* <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContentText id="alert-dialog-title"></DialogContentText>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleDeleteChapter} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog> */}
    </>
  );
}
