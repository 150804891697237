import React, { useState, useEffect, useContext } from "react";

import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

// mui=============================
import { Box, Button, Grid, Typography, styled } from "@mui/material";

// formik===========================
import { useFormik } from "formik";
import * as Yup from "yup";

// components=========================
import { LoadingButton } from "@mui/lab";
import { urls } from "../../../api/urlConstants";
import { post, get } from "../../../api/apiMethods";
import bubble from "../../../assets/bg.png";
import laptop from "../../../assets/admin login.png";
import NavbarContext from "../../../context/NavbarContext";
import FormInput from "../../FormInput";

const ResetPasswordLogin = () => {
  const navigate = useNavigate();
  const { access_token } = useParams();
  const [loading, setLoading] = useState(false);
  const { setShowNavbar } = useContext(NavbarContext);

  useEffect(() => {
    setShowNavbar(false);

    return () => setShowNavbar(true);
  }, []);

  const Main = styled(Box)(() => ({
    height: "100vh",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    backgroundImage: `url(${bubble})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  }));

  const LeftPart = styled(Box)(() => ({
    display: "flex",
    height: "100%",
    backgroundRepeat: "no-repeat",
    backgroundPositionY: "100%",
  }));

  const RightPart = styled(Box)(() => ({
    position: "relative",
    backgroundImage: `url(${laptop})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPositionY: "center",
    justifyContent: "end",
  }));

  const inputForm = {
    width: { xs: "100%", sm: "80%", md: "80%", lg: "60%" },
    margin: "150px auto",
    padding: "0 20px",
  };

  const formik = useFormik({
    initialValues: {
      newpassword: "",
      conpassword: "",
    },

    validationSchema: Yup.object({
      newpassword: Yup.string().required().label("Password").max(32),
      conpassword: Yup.string().required().label("Password").max(32),
    }),
    onSubmit: async (values) => {
      try {
        const body = {
          newPassword: values.newpassword,
          confirmPassword: values.conpassword,
          token: access_token,
        };

        const { data, status } = await post(
          urls.user.resetForgotPasswordInLink,
          body
        );
        navigate("/");
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
    validateOnBlur: false,
  });
  return (
    <>
      <Main>
        <LeftPart
          sx={{
            width: { xs: "100%", sm: "100%", md: "50%", lg: "50%" },
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2} sx={inputForm}>
              <Grid item xs={12} md={12}>
                <Typography variant="h5" fontWeight={600} my={2}>
                  Change Password
                </Typography>
                <Typography
                  variant="body"
                  textAlign="center"
                  sx={{
                    fontSize: {
                      xs: "13px",
                      sm: "14px",
                      md: "16px",
                    },
                  }}
                >
                  Enter your new password!
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormInput
                  formik={formik}
                  name="newpassword"
                  type="password"
                  label="New Password"
                  size="small"
                />
                <FormInput
                  formik={formik}
                  name="conpassword"
                  type="password"
                  label="Confirm Password"
                  size="small"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={loading}
                  fullWidth
                >
                  Submit
                </LoadingButton>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Link to="/" style={{ width: "100%" }}>
                  <Button type="submit" variant="outlined" fullWidth>
                    Back to Login
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </form>
        </LeftPart>
        <RightPart
          sx={{
            width: { xs: "0", sm: "65%", md: "60%", lg: "50%" },
            marginTop: { xs: "20%", sm: "20%", md: "10%", lg: "10%" },
            display: { xs: "none", sm: "none", md: "block", lg: "block" },
            backgroundPositionX: { md: "center", lg: "35px" },
          }}
        >
          <Box
            sx={{
              position: "absolute",
              margin: "auto",
            }}
          >
            <img src={laptop} width="100%" height="100%" alt="Lodding..." />
          </Box>
        </RightPart>
      </Main>
    </>
  );
};

export default ResetPasswordLogin;
