import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { post, put, get } from "../api/apiMethods";
import { urls } from "../api/urlConstants";
import { useNavigate } from "react-router-dom";

const Loading = () => {
  const [user, setUser] = useState({});
  const { access_token } = useParams();

  window.localStorage.setItem("access_token", access_token);
  const navigate = useNavigate();

  useEffect(() => {
    console.log(access_token, "access");
    const getEmployee = async () => {
      try {
        const response = await get(`${urls.user.getOneUser}`);
        setUser(response.data.result.data);

        console.log(response.data.result.data, "responseee");
        window.localStorage.setItem(
          "user",
          JSON.stringify(response.data.result.data)
        );
        console.log(localStorage, "localSssssstorage");
        navigate("/menu");
      } catch (error) {
        console.log(error);
      }
    };
    getEmployee();
  }, []);

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          m: 1,
          gap: "10px",
        }}
      >
        Loading
      </Box>
    </div>
  );
};

export default Loading;
