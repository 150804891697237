import {
  Box,
  Grid,
  Paper,
  Tab,
  Tabs,
  styled,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  Button,
  Tooltip,
  InputLabel,
  Modal,
  Divider,
} from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import Switch from "@mui/material/Switch";
import EditIcon from "@mui/icons-material/Edit";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormInput from "./FormInput";
import FormSelect from "./FormSelect";
import { post, put, get } from "../api/apiMethods";
import { urls } from "../api/urlConstants";
import dayjs from "dayjs";
import { LoadingButton } from "@mui/lab";
import { theme } from "../theme";

const modal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  borderRadius: "20px",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
const label = {
  inputProps: { "aria-label": "Switch demo" },
  fontSize: "small",
};

const Expand = styled(TextField)(({ theme }) => ({
  border: "1px solid #f5f5f5",
  borderRadius: "5px",
}));

const Label = styled("label")(() => ({
  fontWeight: "bold",
  paddingLeft: "10px",
  fontSize: "14px",
}));

const ClassRoom = () => {
  const [editMode, setEditMode] = useState(false);
  const [data, setData] = useState({
    courseName: "",
    standardPrice: "",
    earlyBirdPrice: "",
    earlyBirdEndDate: "",
  });
  const [courseList, setCourseList] = useState([]);
  const [products, setProducts] = useState([]);
  const [courseId, setCourseId] = useState("");
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [editSchedule, setEditSchedule] = useState(null);
  const [infoData, setInfoData] = useState({
    courseName: "",
    courseHours: "",
    standardPrice: "",
    earlyBirdPrice: "",
    earlyBirdEndDate: "",
  });
  const [scheduleData, setScheduleData] = useState([]);
  const [fileChoosen, setFileChoosen] = useState(null);
  const [bulkUploadLoader, setBulkUploadLoader] = useState(false);
  const [priceLoading, setPriceLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [add, setAdd] = useState([
    {
      type: "",
      startDate: "",
      endDate: "",
      hrs: "",
      totalDays: "",
      startTime: "",
      endTime: "",
    },
  ]);

  const handleClick = () => {
    setAdd([
      ...add,
      {
        type: "",
        startDate: "",
        hrs: "",
        totalDays: "",
        startTime: "",
        endTime: "",
      },
    ]);
  };
  useEffect(() => {
    const getCourse = async () => {
      try {
        const product = await get(`${urls.course.getList}`);
        setCourseList(product.data.result.data);
        setProducts(product.data.result.data);
      } catch (error) {
        console.log(error);
      }
    };
    getCourse();
  }, []);
  const handleUpload = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setFileChoosen(null);
  };
  const handleChange = (event) => {
    const selectedCourseId = event.target.value;
    setCourseId(selectedCourseId);
    setInfoData({
      courseName: "",
      standardPrice: "",
      earlyBirdPrice: "",
      earlyBirdEndDate: "",
      courseHours: "",
    });
    setScheduleData([]); // Update the selected course in the state
  };
  const handleOnChange = (e, index) => {
    const { name, value } = e.target;
    const updatedScheduleData = [...scheduleData];
    updatedScheduleData[index][name] = value;
    setScheduleData(updatedScheduleData);
  };
  const handleOptionChange = (e, i) => {
    const { name, value } = e.target;

    const updatedScheduleData = [...scheduleData];
    updatedScheduleData[i] = {
      ...updatedScheduleData[i],
      [name]: value,
    };
    setScheduleData(updatedScheduleData);
  };

  const handleEditClick = () => {
    if (!editMode) {
      setEditMode(true);
    } else {
      setEditMode(false);
    }
  };
  const handleEditSchedule = (id) => {
    if (editSchedule === id) {
      setEditSchedule(null);
    } else {
      setEditSchedule(id);
    }
  };
  console.log(infoData, "i+===================");
  const fetchSelfPacedInfo = async () => {
    try {
      const response = await get(`${urls.course.getOnevalue}/${courseId}`);
      const data = response.data.result.data;
      console.log(data, "mmmmmmm=============iiiiiiiiiiiiiii");

      setInfoData({
        courseName: data.title,
        id: data,
        courseHours: data.courseHours || "",
        standardPrice: data.classRoomInfo?.standardPrice || "",
        earlyBirdPrice: data.classRoomInfo?.earlyBirdPrice || "",
        earlyBirdEndDate: data.classRoomInfo?.earlyBirdEndDate || "",
      });

      setScheduleData(
        data.classRoomInfo?.schedules ? data.classRoomInfo?.schedules : []
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchSelfPacedInfo();
  }, [courseId]);
  const formik = useFormik({
    initialValues: {
      courseName: infoData.courseName,
      standardPrice: infoData.standardPrice,
      earlyBirdPrice: infoData.earlyBirdPrice,
      earlyBirdEndDate: dayjs(infoData.earlyBirdEndDate).format("YYYY-MM-DD"),
    },
    validationSchema: Yup.object({
      courseName: Yup.string().required().label("CourseName"),
      standardPrice: Yup.string().required().label("StandardPrice"),
      earlyBirdPrice: Yup.string().label("EarlyBirdPrice"),
      earlyBirdEndDate: Yup.string().label("EarlyBirdEndDate"),
    }),
    onSubmit: async (values) => {
      try {
        const body = {
          courseName: values.courseName,
          standardPrice: values.standardPrice,
          earlyBirdPrice: values.earlyBirdPrice,
          earlyBirdEndDate: values.earlyBirdEndDate,
        };
        setPriceLoading(true);
        const response = await put(
          `${urls.course.updateClassRoomInfo}/${courseId}`,
          body
        );
        console.log("Response:", response);

        if (response.status === 200) {
          setData({
            ...data,
            ...values,
          });
        }
        setPriceLoading(false);
      } catch (error) {
        console.error(error);
      }
      setPriceLoading(false);
    },
    validateOnBlur: false,
    enableReinitialize: true,
  });
  const formik1 = useFormik({
    initialValues: {
      type: "",
      startDate: "",
      hrs: "",
      totalDays: "",
      startTime: "",
      endTime: "",
    },
    validationSchema: Yup.object({
      type: Yup.string().required().label("type"),
      startDate: Yup.string().required().label("startDate"),
      // hrs: Yup.string().required().label("hrs"),
      totalDays: Yup.string().required().label("totalDays"),
      startTime: Yup.string().required().label("startTime"),
      endTime: Yup.string().required().label(" endTime"),
    }),

    onSubmit: async (values) => {
      try {
        console.log(values, "valuess");
        let courseId;
        courseId = courseList.filter((m) => m.title === values.menu)[0]._id;
        console.log(courseId, "courseidss");
        const body = {
          weekType: values.type,
          startDate: values.startDate,
          hoursPerDay: values.hrs,
          totalDays: values.totalDays,
          startTime: values.startTime,
          endTime: values.endTime,
        };
        const response = await put(
          `${urls.course.updateClassRoomSchedule}/${courseId}`,
          body
        );
        console.log("Respponse", response);
        if (response.status === 200) {
          setData({
            ...data,
            ...values,
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    validateOnBlur: false,
  });
  const formik2 = useFormik({
    initialValues: {
      type: "",
      startDate: "",
      endDate: "",
      hrs: "",
      totalDays: "8",
      startTime: "",
      endTime: "",
    },
    validationSchema: Yup.object({
      type: Yup.string().required().label("type"),
      startDate: Yup.string().required().label("startDate"),
      endDate: Yup.string().required().label("endDate"),
      // hrs: Yup.string().required().label("hrs"),
      totalDays: Yup.string().required().label("totalDays"),
      startTime: Yup.string().required().label("startTime"),
      endTime: Yup.string().required().label(" endTime"),
    }),

    onSubmit: async (values) => {
      try {
        // let courseId;
        // courseId = courseList.filter((m) => m.title === values.menu)[0]._id;
        // console.log(courseId,"courseidss")
        console.log(values, "values");
        const body = {
          weekType: values.type,
          startDate: values.startDate,
          endDate: values.endDate,
          hoursPerDay: values.hrs,
          totalDays: Math.ceil(infoData.courseHours / values.hrs),
          startTime: values.startTime,
          endTime: values.endTime,
        };
        setCreateLoading(true);
        const response = await put(
          `${urls.course.addClassRoomSchedule}/${courseId}`,
          body
        );
        fetchSelfPacedInfo();
        formik2.resetForm();
        setCreateLoading(false);
      } catch (error) {
        console.error(error);
      }
      setCreateLoading(false);
    },
    validateOnBlur: false,
    enableReinitialize: true,
  });
  const handleUpdateSchedule = async (e, id, index) => {
    console.log(id, "coussssssrseId");
    e.preventDefault();
    try {
      const body = {
        weekType: scheduleData[index].weekType,
        startDate: scheduleData[index].startDate,
        endDate: scheduleData[index].endDate,
        hoursPerDay: scheduleData[index].hoursPerDay,
        totalDays: Math.ceil(
          infoData.courseHours / scheduleData[index].hoursPerDay
        ),
        startTime: scheduleData[index].startTime,
        endTime: scheduleData[index].endTime,
        scheduleId: id,
      };
      console.log(body, "eee");
      setUpdateLoading(true);
      const response = await put(
        `${urls.course.updateClassRoomSchedule}/${courseId}`,
        body
      );
      console.log(response, "response");
      setUpdateLoading(false);
    } catch (error) {
      console.log(error);
    }
    setUpdateLoading(false);
  };
  const handleUpdateStatus = async (id) => {
    console.log(id, "coussssssrseId");
    try {
      const body = {
        scheduleId: id,
      };
      const response = await put(
        `${urls.course.changeClassStatus}/${courseId}`,
        body
      );
      console.log(response, "response");
    } catch (error) {
      console.log(error);
    }
  };
  const options = [
    { value: "Weekday", label: "Weekday" },
    { value: "Weekend", label: "Weekend" },
  ];

  const handleFileChange = (e) => {
    setFileChoosen(e.target.files[0]);
  };
  const handleSheetUpload = async (e) => {
    setBulkUploadLoader(true);
    e.preventDefault();
    console.log("test ", fileChoosen);
    try {
      if (!fileChoosen) {
        console.error("No file selected.");
        return;
      }
      const formData = new FormData();
      formData.append("file", fileChoosen);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await put(urls.course.uploadClassroom, formData, config);
      console.log("Response:", response.data);
      console.log("Sheet uploaded successfully!");
      handleClose();
      fetchSelfPacedInfo();
    } catch (error) {
      console.error(error);
    }
    setBulkUploadLoader(false);
  };
  const downloadSample = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const res = await get(urls.course.downloadClassroomSample, {
        responseType: "blob",
      });
      console.log(res, "reeeesss");

      const downloadUrl = URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `ClassRoomSample.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setLoading("");
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  return (
    <>
      {/* <Paper style={{ margin: "15px 22px" }}> */}
      <Grid container spacing={1}>
        <Grid item xs={8} sm={4} md={3}>
          <FormControl fullWidth required size="small">
            <InputLabel>Course Name</InputLabel>
            <Select
              label="Course name"
              id="demo-simple-select-filled"
              value={courseId}
              name="courseId"
              size="small"
              onChange={handleChange}
            >
              {products.map((name) => (
                <MenuItem key={name._id} value={name._id}>
                  {name.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={8} sm={6} md={2}>
          <Button
            onClick={handleUpload}
            sx={{
              backgroundColor: theme.palette.primary.main,

              "&:hover": {
                background: theme.palette.primary.main,
              },
              color: "#ffff",
            }}
          >
            Bulk Upload
          </Button>
        </Grid>
      </Grid>
      <Modal
        onClose={handleClose}
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modal}>
          <div style={{ textAlign: "center", marginBottom: "30px" }}>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              Upload Course
            </Typography>
          </div>
          <form onSubmit={handleSheetUpload}>
            <Grid container spacing={1} sx={{ ml: 1.5 }}>
              <Grid item xs={4} md={4} lg={4}>
                <Tooltip title="Get Sample">
                  <Button
                    variant="contained"
                    size="small"
                    type="submit"
                    loading={loading}
                    onClick={downloadSample}
                  >
                    Sample
                  </Button>
                </Tooltip>
              </Grid>
              <Grid item xs={4} md={4} lg={4}>
                <Tooltip title="Upload">
                  <Button variant="contained" size="small" component="label">
                    Upload
                    <input
                      onChange={handleFileChange}
                      name="file"
                      hidden
                      accept=".xlsx"
                      type="file"
                    />
                  </Button>
                </Tooltip>

                {/* <div>{fileChoosen && fileChoosen.name}</div> */}
              </Grid>

              <Grid item xs={4} md={4} lg={4}>
                <Tooltip title="Submit">
                  <LoadingButton
                    size="small"
                    loading={bulkUploadLoader}
                    variant="contained"
                    type="submit"
                  >
                    Submit
                  </LoadingButton>
                </Tooltip>
              </Grid>
            </Grid>
            <Typography
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "5px",
              }}
            >
              {fileChoosen && fileChoosen.name}
            </Typography>
          </form>
        </Box>
      </Modal>
      {courseId && (
        <>
          <form onSubmit={formik.handleSubmit}>
            {/* <Paper sx={{ mt: 4 }}> */} {/* <Box sx={{ m: 1 }}> */}
            <Divider sx={{ color: "#00000075", border: 1, my: 2 }} />
            <Grid container spacing={1}>
              {/* <Grid item xs={12} sm={6} md={3}>
                <Label htmlFor="">Course Name</Label>
                <br />

                <TextField
                  size="small"
                  placeholder="Course Name"
                  disabled
                  name="courseName"
                  value={formik.values.courseName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.courseName &&
                    Boolean(formik.errors.courseName)
                  }
                  helperText={
                    formik.touched.courseName && formik.errors.courseName
                  }
                  required
                  fullWidth
                />
              </Grid> */}
              <Grid item xs={12} sm={6} md={3}>
                <Label htmlFor="">Standard Price</Label>
                <br />
                <TextField
                  size="small"
                  placeholder="Standard Price"
                  type="number"
                  disabled={!editMode}
                  name="standardPrice"
                  value={formik.values.standardPrice}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.standardPrice &&
                    Boolean(formik.errors.standardPrice)
                  }
                  helperText={
                    formik.touched.standardPrice && formik.errors.standardPrice
                  }
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Label htmlFor="">Early Bird Price</Label>
                <br />
                <TextField
                  size="small"
                  name="earlyBirdPrice"
                  type="number"
                  placeholder="Early Bird Price"
                  disabled={!editMode}
                  value={formik.values.earlyBirdPrice}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.earlyBirdPrice &&
                    Boolean(formik.errors.earlyBirdPrice)
                  }
                  helperText={
                    formik.touched.earlyBirdPrice &&
                    formik.errors.earlyBirdPrice
                  }
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <Label htmlFor="">Early Bird End Date</Label>
                <br />
                <TextField
                  type="date"
                  size="small"
                  name="earlyBirdEndDate"
                  disabled={!editMode}
                  value={formik.values.earlyBirdEndDate}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.earlyBirdEndDate &&
                    Boolean(formik.errors.earlyBirdEndDate)
                  }
                  helperText={
                    formik.touched.earlyBirdEndDate &&
                    formik.errors.earlyBirdEndDate
                  }
                  required
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={1}
                sx={{
                  display: "flex",
                  justifyContent: { xs: "flex-start", md: "flex-end" },
                  alignItems: { xs: "start", md: "center" },
                  mt: 2,
                }}
              >
                <Tooltip title={editMode ? "Save" : "Edit"}>
                  <LoadingButton
                    variant="contained"
                    loading={priceLoading}
                    size="small"
                    type={editMode ? "button" : "submit"}
                    sx={{ m: 1 }}
                    onClick={handleEditClick}
                  >
                    {editMode ? "Save" : "Edit"}
                  </LoadingButton>
                </Tooltip>
              </Grid>
            </Grid>
            <Divider sx={{ color: "#00000075", border: 1, mt: 3 }} />
            {/* </Box> */}
            {/* </Paper> */}
          </form>

          <>
            <form onSubmit={formik2.handleSubmit} style={{ marginTop: "30px" }}>
              <Typography variant="h6" fontWeight="bold">
                {" "}
                Create/Manage Schedules for this course
              </Typography>
              <Paper
                sx={{
                  border: "1px solid lightgray",
                  borderRadius: "20px",
                  px: 2,
                  backgroundColor: theme.palette.grey[100],
                }}
                elevation={0}
              >
                <Grid container spacing={2} sx={{ mt: 2, p: 1, mb: 2 }}>
                  <Grid item xs={12} md={3} sm={6}>
                    <Label htmlFor="">Type</Label>
                    <FormSelect
                      formik={formik2}
                      name="type"
                      options={options}
                    />
                  </Grid>
                  <Grid item xs={12} md={3} sm={6}>
                    <Label htmlFor="">Start Date</Label>
                    <br />
                    <TextField
                      type="date"
                      size="small"
                      name="startDate"
                      value={formik2.values.startDate}
                      onChange={formik2.handleChange}
                      error={
                        formik2.touched.startDate &&
                        Boolean(formik2.errors.startDate)
                      }
                      helperText={
                        formik2.touched.startDate && formik2.errors.startDate
                      }
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3} sm={6}>
                    <Label htmlFor="">End Date</Label>
                    <br />
                    <TextField
                      type="date"
                      size="small"
                      name="endDate"
                      value={formik2.values.endDate}
                      onChange={formik2.handleChange}
                      error={
                        formik2.touched.endDate &&
                        Boolean(formik2.errors.endDate)
                      }
                      helperText={
                        formik2.touched.endDate && formik2.errors.endDate
                      }
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3} sm={6}>
                    <Label htmlFor="">Duration/per day(in hrs)</Label>
                    <br />

                    <TextField
                      size="small"
                      name="hrs"
                      value={formik2.values.hrs}
                      onChange={formik2.handleChange}
                      error={formik2.touched.hrs && Boolean(formik2.errors.hrs)}
                      helperText={formik2.touched.hrs && formik2.errors.hrs}
                      // required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3} sm={6}>
                    <Label htmlFor="">Total Days</Label>
                    <br />

                    <TextField
                      size="small"
                      name="totalDays"
                      placeholder="Total Days"
                      value={Math.ceil(
                        infoData.courseHours / formik2.values.hrs
                      )}
                      onChange={formik2.handleChange}
                      error={
                        formik2.touched.totalDays &&
                        Boolean(formik2.errors.totalDays)
                      }
                      helperText={
                        formik2.touched.totalDays && formik2.errors.totalDays
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3} sm={6}>
                    <Label htmlFor="">Start Time</Label>
                    <br />
                    <TextField
                      type={"time"}
                      size="small"
                      name="startTime"
                      value={formik2.values.startTime}
                      onChange={formik2.handleChange}
                      error={
                        formik2.touched.startTime &&
                        Boolean(formik2.errors.startTime)
                      }
                      helperText={
                        formik2.touched.startTime && formik2.errors.startTime
                      }
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={3} sm={6}>
                    <Label htmlFor="">End Time</Label>
                    <br />
                    <TextField
                      type={"time"}
                      size="small"
                      name="endTime"
                      value={formik2.values.endTime}
                      onChange={formik2.handleChange}
                      error={
                        formik2.touched.endTime &&
                        Boolean(formik2.errors.endTime)
                      }
                      helperText={
                        formik2.touched.endTime && formik2.errors.endTime
                      }
                      required
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} md={3} sm={6}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mt: 3,
                      }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginLeft: "10px",
                        }}
                      >
                        <Tooltip title="edit"></Tooltip>
                        {/* <Tooltip title="active/inactive">
                          <Button>
                            <Switch {...label} />
                          </Button>
                        </Tooltip> */}
                      </Box>
                      <Tooltip title="add">
                        <LoadingButton
                          loading={createLoading}
                          sx={{
                            background: theme.palette.primary.main,
                            color: "white",
                            "&:hover": {
                              background: theme.palette.primary.main,
                            },
                          }}
                          type="submit"
                          onClick={handleClick}
                        >
                          Add
                        </LoadingButton>
                      </Tooltip>
                    </Box>
                  </Grid>
                  {/* <Button
          style={{ color: "#1b3779", marginTop: "40px" }}
          onClick={() => handleremove(i)}>
          <RemoveIcon />
        </Button> */}
                </Grid>
              </Paper>
            </form>
            {scheduleData.length > 0
              ? scheduleData.map((data, index) => (
                  <form
                    onSubmit={(e) => handleUpdateSchedule(e, data._id, index)}
                    style={{ marginTop: "20px" }}
                  >
                    <Paper
                      sx={{
                        border: "1px solid lightgray",
                        borderRadius: "20px",
                        px: 2,
                        backgroundColor: theme.palette.grey[100],
                      }}
                      elevation={0}
                    >
                      <Grid container spacing={2} sx={{ mt: 2, p: 1, mb: 2 }}>
                        <Grid item xs={12} md={3} sm={6}>
                          <Label htmlFor="">Type</Label>
                          <br />
                          <Select
                            label="Type"
                            id="demo-simple-select-filled"
                            value={data.weekType}
                            name="weekType"
                            size="small"
                            onChange={(e) => handleOptionChange(e, index)}
                            disabled={editSchedule !== data._id}
                            required
                            fullWidth
                          >
                            {options.map((name) => (
                              <MenuItem value={name.value} key={name.value}>
                                {name.value}
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>
                        <Grid item xs={12} md={3} sm={6}>
                          <Label htmlFor="">Start Date</Label>
                          <br />
                          <TextField
                            type="date"
                            size="small"
                            name="startDate"
                            value={dayjs(data.startDate).format("YYYY-MM-DD")}
                            onChange={(e) => handleOnChange(e, index)}
                            disabled={editSchedule !== data._id}
                            error={
                              formik1.touched.startDate &&
                              Boolean(formik1.errors.startDate)
                            }
                            helperText={
                              formik1.touched.startDate &&
                              formik1.errors.startDate
                            }
                            required
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={3} sm={6}>
                          <Label htmlFor="">End Date</Label>
                          <br />
                          <TextField
                            type="date"
                            size="small"
                            name="endDate"
                            value={dayjs(data.endDate).format("YYYY-MM-DD")}
                            onChange={(e) => handleOnChange(e, index)}
                            disabled={editSchedule !== data._id}
                            error={
                              formik1.touched.endDate &&
                              Boolean(formik1.errors.endDate)
                            }
                            helperText={
                              formik1.touched.endDate && formik1.errors.endDate
                            }
                            required
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={3} sm={6}>
                          <Label htmlFor="">Duration/per day(in hrs)</Label>
                          <br />

                          <TextField
                            size="small"
                            name="hoursPerDay"
                            value={data.hoursPerDay}
                            onChange={(e) => handleOnChange(e, index)}
                            error={
                              formik1.touched.hrs && Boolean(formik1.errors.hrs)
                            }
                            helperText={
                              formik1.touched.hrs && formik1.errors.hrs
                            }
                            // required
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={3} sm={6}>
                          <Label htmlFor="">Total Days</Label>
                          <br />

                          <TextField
                            size="small"
                            name="totalDays"
                            placeholder="Total Days"
                            value={Math.ceil(
                              infoData.courseHours / data.hoursPerDay
                            )}
                            onChange={(e) => handleOnChange(e, index)}
                            disabled={editSchedule !== data._id}
                            error={
                              formik1.touched.totalDays &&
                              Boolean(formik1.errors.totalDays)
                            }
                            helperText={
                              formik1.touched.totalDays &&
                              formik1.errors.totalDays
                            }
                            required
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={3} sm={6}>
                          <Label htmlFor="">Start Time</Label>
                          <br />
                          <TextField
                            type={"time"}
                            size="small"
                            name="startTime"
                            value={data.startTime}
                            onChange={(e) => handleOnChange(e, index)}
                            disabled={editSchedule !== data._id}
                            error={
                              formik1.touched.startTime &&
                              Boolean(formik1.errors.startTime)
                            }
                            helperText={
                              formik1.touched.startTime &&
                              formik1.errors.startTime
                            }
                            required
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={3} sm={6}>
                          <Label htmlFor="">End Time</Label>
                          <br />
                          <TextField
                            type={"time"}
                            size="small"
                            name="endTime"
                            value={data.endTime}
                            onChange={(e) => handleOnChange(e, index)}
                            disabled={editSchedule !== data._id}
                            error={
                              formik1.touched.endTime &&
                              Boolean(formik1.errors.endTime)
                            }
                            helperText={
                              formik1.touched.endTime && formik1.errors.endTime
                            }
                            required
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={3} sm={6}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              mt: 3,
                            }}
                          >
                            <Box
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginLeft: "10px",
                              }}
                            >
                              <Tooltip title="active/inactive">
                                <Button>
                                  <Switch
                                    {...label}
                                    disabled={editSchedule !== data._id}
                                    defaultChecked={data.activated}
                                    onClick={() => {
                                      if (editSchedule === data._id) {
                                        handleUpdateStatus(data._id);
                                      }
                                    }}
                                  />
                                </Button>
                              </Tooltip>
                            </Box>

                            <Tooltip
                              title={
                                editSchedule === data._id ? "Save" : "Edit"
                              }
                            >
                              <LoadingButton
                                variant="contained"
                                size="small"
                                loading={
                                  updateLoading && editSchedule == data._id
                                }
                                type={
                                  editSchedule === data._id
                                    ? "button"
                                    : "submit"
                                }
                                // onClick={() => {
                                //   if (editSchedule === data._id) {
                                //     handleEditSchedule(data._id);
                                //   }
                                // }}
                                onClick={() => handleEditSchedule(data._id)}
                                sx={{
                                  m: 1,
                                  background: theme.palette.primary.main,

                                  "&:hover": {
                                    background: theme.palette.primary.main,
                                  },
                                }}
                              >
                                {editSchedule === data._id ? "Save" : "Edit"}
                              </LoadingButton>
                            </Tooltip>
                          </Box>
                        </Grid>
                        {/* <Button
                                    style={{ color: "#1b3779", marginTop: "40px" }}
                                    onClick={() => handleremove(i)}>
                                    <RemoveIcon />
                                  </Button> */}
                      </Grid>
                    </Paper>
                  </form>
                ))
              : ""}
          </>
        </>
      )}
      {/* </Paper> */}
    </>
  );
};
export default ClassRoom;
