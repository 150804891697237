import {
  Box,
  Grid,
  Paper,
  Tab,
  Tabs,
  styled,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  Button,
  InputLabel,
  Tooltip,
  Modal,
  Divider,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import SelfPaced from "./SelfPaced";
import ClassRoom from "./ClassRoom";
import Switch from "@mui/material/Switch";
import EditIcon from "@mui/icons-material/Edit";
import { useFormik, useFormikContext } from "formik";
import * as Yup from "yup";
import FormInput from "./FormInput";
import FormSelect from "./FormSelect";
import { async } from "q";
import { format } from "date-fns";
import { post, put, get } from "../api/apiMethods";
import { urls } from "../api/urlConstants";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { theme } from "../theme";
import { toast } from "react-toastify";
import ProgrammName from "../utils/data";
const modal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  borderRadius: "20px",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const label = {
  inputProps: { "aria-label": "Switch demo" },
  fontSize: "small",
};

const Label = styled("label")(() => ({
  fontWeight: "bold",
  fontSize: "14px",
  paddingLeft: "10px",
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

const Pricing = () => {
  const [tabValue, setTabValue] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [editSchedule, setEditSchedule] = useState(null);
  const [bulkUploadLoader, setBulkUploadLoader] = useState(false);
  const [priceLoading, setPriceLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [data, setData] = useState({
    courseName: "",
    standardPrice: "",
    earlyBirdPrice: "",
    earlyBirdEndDate: "",
  });
  const [edit, setEdit] = useState();
  const [courseList, setCourseList] = useState([]);
  const [products, setProducts] = useState([]);
  const [infoData, setInfoData] = useState({
    courseName: "",
    standardPrice: "",
    earlyBirdPrice: "",
    earlyBirdEndDate: "",
    courseHours: "",
  });
  const [courseName, setCourseName] = useState("");
  const [courseId, setCourseId] = useState("");
  const [scheduleData, setScheduleData] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const [fileChoosen, setFileChoosen] = useState(null);
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const { selectedCourseId } = location.state || {};
  // console.log(selectedCourseId, "klklklklklkllooooooooooooo");

  const [add, setAdd] = useState([
    {
      type: "",
      startDate: "",
      endDate: "",
      hrs: "",
      totalDays: "",
      startTime: "",
      endTime: "",
    },
  ]);

  const handleFileChange = (e) => {
    setFileChoosen(e.target.files[0]);
  };

  const downloadSample = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const res = await get(urls.course.downloadSample, {
        responseType: "blob",
      });
      const downloadUrl = URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `OnlineInstructorSample.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleClick = () => {
    setAdd([
      ...add,
      {
        type: "",
        startDate: "",
        endDate: "",
        hrs: "",
        totalDays: "",
        startTime: "",
        endTime: "",
      },
    ]);
  };

  useEffect(() => {
    const getCourse = async () => {
      try {
        const product = await get(`${urls.course.getList}`);
        setCourseList(product.data.result.data);
        setProducts(product.data.result.data);
      } catch (error) {
        console.log(error);
      }
    };
    getCourse();
  }, []);

  const handleOnChange = (e, index) => {
    const { name, value } = e.target;
    const updatedScheduleData = [...scheduleData];
    updatedScheduleData[index][name] = value;
    setScheduleData(updatedScheduleData);
  };

  const handleOptionChange = (e, i) => {
    const { name, value } = e.target;

    const updatedScheduleData = [...scheduleData];
    updatedScheduleData[i] = {
      ...updatedScheduleData[i],
      [name]: value,
    };
    setScheduleData(updatedScheduleData);
  };

  const handleChange = (event) => {
    setCourseId(event.target.value);
    setInfoData({
      courseName: "",
      standardPrice: "",
      earlyBirdPrice: "",
      earlyBirdEndDate: "",
      courseHours: "",
    });
    setCourseName("");
    setScheduleData([]);
  };

  const handleEditClick = () => {
    if (!editMode) {
      setEditMode(true);
    } else {
      setEditMode(false);
    }
  };
  const handleEditSchedule = (id) => {
    if (editSchedule === id) {
      setEditSchedule(null);
    } else {
      setEditSchedule(id);
    }
  };
  console.log(editSchedule, "editSchedule");

  const formik2 = useFormik({
    initialValues: {
      type: "",
      startDate: "",
      endDate: "",
      hrs: "",
      totalDays: "",
      startTime: "",
      endTime: "",
    },
    validationSchema: Yup.object({
      type: Yup.string().required().label("type"),
      startDate: Yup.string().required().label("startDate"),
      endDate: Yup.string().required().label("endDate"),
      // hrs: Yup.string()
      //   .required()
      //   .label("hrs")
      //   .max(24, "Hours cannot be more than 24"),
      totalDays: Yup.string().label("totalDays"),
      startTime: Yup.string().required().label("startTime"),
      endTime: Yup.string().required().label(" endTime"),
    }),

    onSubmit: async (values) => {
      try {
        // let courseId;
        // courseId = courseList.filter((m) => m.title === values.menu)[0]._id;
        console.log(values.hrs, "values.hrs");
        if (values.hrs > 24) {
          alert(
            "Hours per day cannot be more than 24 or Hours per day is more than total Course Hour"
          );
        } else if (values.hrs > infoData.courseHours) {
          alert("Hours per day is more than total Course Hour");
        } else {
          const body = {
            weekType: values.type,
            startDate: values.startDate,
            endDate: values.endDate,
            hoursPerDay: values.hrs,
            totalDays: Math.ceil(infoData.courseHours / values.hrs),
            startTime: values.startTime,
            endTime: values.endTime,
          };
          setCreateLoading(true);
          const response = await put(
            `${urls.course.addInstructorLedSchedule}/${courseId}`,
            body
          );

          fetchSelfPacedInfo();
          formik2.resetForm();
          setCreateLoading(false);
        }
      } catch (error) {
        console.error(error);
      }
      setCreateLoading(false);
    },
    validateOnBlur: false,
    enableReinitialize: true,
  });
  const formik = useFormik({
    initialValues: {
      courseName: infoData.courseName,
      standardPrice: infoData.standardPrice,
      earlyBirdPrice: infoData.earlyBirdPrice,
      earlyBirdEndDate: dayjs(infoData.earlyBirdEndDate).format("YYYY-MM-DD"),
    },
    validationSchema: Yup.object({
      courseName: Yup.string().required().label("CourseName"),
      standardPrice: Yup.string().required().label("StandardPrice"),
      earlyBirdPrice: Yup.string().label("EarlyBirdPrice"),
      earlyBirdEndDate: Yup.string().label("EarlyBirdEndDate"),
    }),
    onSubmit: async (values) => {
      try {
        const body = {
          courseName: values.courseName,
          standardPrice: values.standardPrice,
          earlyBirdPrice: values.earlyBirdPrice,
          earlyBirdEndDate: values.earlyBirdEndDate,
        };
        setPriceLoading(true);
        const response = await put(
          `${urls.course.updateInstructorLedInfo}/${courseId}`,
          body
        );
        console.log("Response:", response);

        if (response.status === 200) {
          setData({
            ...data,
            ...values,
          });
        }
        setPriceLoading(false);
      } catch (error) {
        console.error(error);
      }
      setPriceLoading(false);
    },
    validateOnBlur: false,
    enableReinitialize: true,
  });

  const fetchSelfPacedInfo = async () => {
    try {
      const response = await get(`${urls.course.getOnevalue}/${courseId}`);
      const data = response.data.result.data;
      if (data) {
        setCourseName(data.title);
        setInfoData({
          courseName: data.title,
          id: data,
          courseHours: data.courseHours || "",
          standardPrice: data.onlineInstructorLedInfo?.standardPrice || "",
          earlyBirdPrice: data.onlineInstructorLedInfo?.earlyBirdPrice || "",
          earlyBirdEndDate:
            data.onlineInstructorLedInfo?.earlyBirdEndDate || "",
          earlyBirdEndDate:
            data.onlineInstructorLedInfo?.earlyBirdEndDate || "",
        });

        setScheduleData(
          data.onlineInstructorLedInfo?.schedules
            ? data.onlineInstructorLedInfo.schedules
            : []
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSheetUpload = async (e) => {
    setBulkUploadLoader(true);
    e.preventDefault();
    try {
      if (!fileChoosen) {
        toast.error("No file selected!");
        return;
      }
      const formData = new FormData();
      formData.append("file", fileChoosen);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await put(
        urls.course.uploadOnlineInstructor,
        formData,
        config
      );
      fetchSelfPacedInfo();
      handleClose();
    } catch (error) {
      console.error(error);
    }
    setBulkUploadLoader(false);
  };

  useEffect(() => {
    if (courseId) {
      fetchSelfPacedInfo();
    }
  }, [courseId]);
  const handleUpdateSchedule = async (e, id, index) => {
    e.preventDefault();

    const updatedHoursPerDay = scheduleData[index].hoursPerDay;

    if (updatedHoursPerDay > 24) {
      alert("Hours per day cannot be more than 24");
    } else if (infoData.courseHours < updatedHoursPerDay) {
      alert("Hours per day is more than the total Course Hour");
    } else {
      try {
        const body = {
          weekType: scheduleData[index].weekType,
          startDate: scheduleData[index].startDate,
          endDate: scheduleData[index].endDate,
          hoursPerDay: updatedHoursPerDay,
          totalDays: Math.ceil(infoData.courseHours / updatedHoursPerDay),
          startTime: scheduleData[index].startTime,
          endTime: scheduleData[index].endTime,
          scheduleId: id,
        };
        setUpdateLoading(true);
        console.log(body, "Request Body");

        const response = await put(
          `${urls.course.updateInstructorLedSchedule}/${courseId}`,
          body
        );
        console.log(response, "Response");
        setUpdateLoading(false);
      } catch (error) {
        console.log(error);
      }
      setUpdateLoading(false);
    }
  };

  const handleUpdateStatus = async (id) => {
    console.log(id, "coussssssrseId");
    try {
      const body = {
        scheduleId: id,
      };
      const response = await put(
        `${urls.course.changeStatus}/${courseId}`,
        body
      );
      console.log(response, "response");
    } catch (error) {
      console.log(error);
    }
  };
  const options = [
    { value: "Weekday", label: "Weekday" },
    { value: "Weekend", label: "Weekend" },
  ];

  const handleUpload = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setFileChoosen(null);
  };

  return (
    <>
      <Paper style={{ margin: "15px 22px" }}>
        <Box>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
          >
            <Tab label={ProgrammName["Online Instructor"]} {...a11yProps(0)} />
            <Tab label={ProgrammName["Online Self Paced"]} {...a11yProps(1)} />
            <Tab label={ProgrammName["Classroom"]} {...a11yProps(1)} />
          </Tabs>

          <Divider sx={{ color: "#00000075" }} />
        </Box>

        <TabPanel value={tabValue} index={0}>
          {/* <Paper sx={{ padding: "15px" }}> */}
          {/* <Paper sx={{ padding: "15px" }}> */}
          <Grid container spacing={1} mt={1}>
            <Grid item xs={8} sm={4} md={3}>
              <FormControl fullWidth required size="small">
                <InputLabel>Course Name</InputLabel>
                <Select
                  label="Course Name"
                  id="demo-simple-select-filled"
                  value={courseId}
                  name="courseId"
                  size="small"
                  onChange={handleChange}
                >
                  {products.map((name) => (
                    <MenuItem key={name._id} value={name._id}>
                      {name.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={8} sm={6} md={3}>
              <Button
                onClick={handleUpload}
                sx={{
                  backgroundColor: theme.palette.primary.main,

                  "&:hover": {
                    background: theme.palette.primary.main,
                  },
                  color: "#ffff",
                }}
              >
                Bulk Upload
              </Button>
            </Grid>
          </Grid>
          {/* </Paper> */}
          <Modal
            onClose={handleClose}
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={modal}>
              <div style={{ textAlign: "center", marginBottom: "30px" }}>
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  Upload Course
                </Typography>
              </div>
              <form onSubmit={handleSheetUpload}>
                <Grid container spacing={1} sx={{ ml: 1.5 }}>
                  <Grid item xs={4} md={4} lg={4}>
                    <Tooltip title="Get Sample">
                      <Button
                        variant="contained"
                        size="small"
                        type="submit"
                        loading={loading}
                        onClick={downloadSample}
                      >
                        Sample
                      </Button>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={4} md={4} lg={4}>
                    <Tooltip title="Upload">
                      <Button
                        variant="contained"
                        size="small"
                        component="label"
                      >
                        Upload
                        <input
                          onChange={handleFileChange}
                          name="file"
                          hidden
                          accept=".xlsx"
                          type="file"
                        />
                      </Button>
                    </Tooltip>
                  </Grid>

                  <Grid item xs={4} md={4} lg={4}>
                    <Tooltip title="Submit">
                      <LoadingButton
                        size="small"
                        loading={bulkUploadLoader}
                        variant="contained"
                        type="submit"
                      >
                        Submit
                      </LoadingButton>
                    </Tooltip>
                  </Grid>
                </Grid>
                <Typography
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "5px",
                  }}
                >
                  {fileChoosen && fileChoosen.name}
                </Typography>
              </form>
            </Box>
          </Modal>

          {courseId && (
            <>
              <form onSubmit={formik.handleSubmit}>
                {/* <Paper sx={{ mt: 4 }}> */}{" "}
                {/* <Box
                sx={{
                  m: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              > */}
                <Divider sx={{ color: "#00000075", border: 1, mt: 3 }} />
                <Grid container spacing={2} my={1}>
                  {/* <Grid item xs={12} sm={6} md={3}>
                    <Label htmlFor="">Course Name</Label>
                    <br />

                    <TextField
                      size="small"
                      placeholder="Course Name"
                      disabled
                      name="courseName"
                      value={formik.values.courseName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.courseName &&
                        Boolean(formik.errors.courseName)
                      }
                      helperText={
                        formik.touched.courseName && formik.errors.courseName
                      }
                      fullWidth
                    />
                  </Grid> */}
                  <Grid item xs={12} sm={6} md={3}>
                    <Label htmlFor="">Standard Price</Label>
                    <br />
                    <TextField
                      size="small"
                      placeholder="Standard Price"
                      disabled={!editMode}
                      type="number"
                      name="standardPrice"
                      value={formik.values.standardPrice}
                      onChange={formik.handleChange}
                      // error={
                      //   formik.touched.standardPrice &&
                      //   Boolean(formik.errors.standardPrice)
                      // }
                      // helperText={
                      //   formik.touched.standardPrice &&
                      //   formik.errors.standardPrice
                      // }
                      // required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Label htmlFor="">Early Bird Price</Label>
                    <br />
                    <TextField
                      size="small"
                      name="earlyBirdPrice"
                      placeholder="Early Bird Price"
                      type="number"
                      disabled={!editMode}
                      value={formik.values.earlyBirdPrice}
                      onChange={formik.handleChange}
                      // error={
                      //   formik.touched.earlyBirdPrice &&
                      //   Boolean(formik.errors.earlyBirdPrice)
                      // }
                      // helperText={
                      //   formik.touched.earlyBirdPrice &&
                      //   formik.errors.earlyBirdPrice
                      // }
                      // required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <Label htmlFor="">Early Bird End Date</Label>
                    <br />
                    <TextField
                      type="date"
                      size="small"
                      name="earlyBirdEndDate"
                      disabled={!editMode}
                      value={formik.values.earlyBirdEndDate}
                      onChange={formik.handleChange}
                      // error={
                      //   formik.touched.earlyBirdEndDate &&
                      //   Boolean(formik.errors.earlyBirdEndDate)
                      // }
                      // helperText={
                      //   formik.touched.earlyBirdEndDate &&
                      //   formik.errors.earlyBirdEndDate
                      // }
                      // required
                      fullWidth
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={1}
                    sx={{
                      display: "flex",
                      justifyContent: { xs: "flex-start", md: "flex-end" },
                      alignItems: { xs: "start", md: "center" },
                      mt: 2,
                    }}
                  >
                    <Tooltip title={editMode ? "Save" : "Edit"}>
                      <LoadingButton
                        // size="small"
                        type={editMode ? "button" : "submit"}
                        onClick={handleEditClick}
                        loading={priceLoading}
                        sx={{
                          backgroundColor: theme.palette.primary.main,
                          "&:hover": {
                            background: theme.palette.primary.main,
                          },
                          color: "#ffff",
                        }}
                      >
                        {editMode ? "Save" : "Edit"}
                      </LoadingButton>
                    </Tooltip>
                  </Grid>
                </Grid>
                <Divider sx={{ color: "#00000075", border: 1, mt: 3 }} />
                {/* </Box> */}
                {/* </Paper> */}
              </form>

              <>
                <form
                  onSubmit={formik2.handleSubmit}
                  style={{ marginTop: "30px" }}
                >
                  <Typography variant="h6" fontWeight="bold" ml={1} mb={0}>
                    Create/Manage Schedules for this course
                  </Typography>

                  <Paper
                    sx={{
                      border: "1px solid lightgray",
                      borderRadius: "20px",
                      px: 2,
                      backgroundColor: theme.palette.grey[100],
                    }}
                    elevation={0}
                  >
                    <Grid container spacing={2} py={3}>
                      <Grid item xs={12} md={3} sm={6}>
                        <Label htmlFor="">Type</Label>
                        <FormSelect
                          formik={formik2}
                          name="type"
                          options={options}
                        />
                      </Grid>
                      <Grid item xs={12} md={3} sm={6}>
                        <Label htmlFor="">Start Date</Label>
                        <br />
                        <TextField
                          type="date"
                          size="small"
                          name="startDate"
                          value={formik2.values.startDate}
                          onChange={formik2.handleChange}
                          error={
                            formik2.touched.startDate &&
                            Boolean(formik2.errors.startDate)
                          }
                          helperText={
                            formik2.touched.startDate &&
                            formik2.errors.startDate
                          }
                          required
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={3} sm={6}>
                        <Label htmlFor="">End Date</Label>
                        <br />
                        <TextField
                          type="date"
                          size="small"
                          name="endDate"
                          value={formik2.values.endDate}
                          onChange={formik2.handleChange}
                          error={
                            formik2.touched.endDate &&
                            Boolean(formik2.errors.endDate)
                          }
                          helperText={
                            formik2.touched.endDate && formik2.errors.endDate
                          }
                          required
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={3} sm={6}>
                        <Label htmlFor="">Duration/per day(in hrs)</Label>
                        <br />

                        <TextField
                          size="small"
                          name="hrs"
                          type="number"
                          value={formik2.values.hrs}
                          onChange={formik2.handleChange}
                          error={
                            formik2.touched.hrs &&
                            parseFloat(formik2.values.hrs) > 24
                          }
                          helperText={
                            formik2.touched.hrs &&
                            parseFloat(formik2.values.hrs) > 24
                              ? "Hours cannot be more than 24"
                              : ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={3} sm={6}>
                        <Label htmlFor="">Total Days</Label>
                        <br />

                        <TextField
                          size="small"
                          name="totalDays"
                          placeholder="Total Days"
                          value={Math.ceil(
                            infoData.courseHours / formik2.values.hrs
                          )}
                          onChange={formik2.handleChange}
                          error={
                            formik2.touched.totalDays &&
                            Boolean(formik2.errors.totalDays)
                          }
                          helperText={
                            formik2.touched.totalDays &&
                            formik2.errors.totalDays
                          }
                          required
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={3} sm={6}>
                        <Label htmlFor="">Start Time</Label>
                        <br />
                        <TextField
                          type={"time"}
                          size="small"
                          name="startTime"
                          value={formik2.values.startTime}
                          onChange={formik2.handleChange}
                          error={
                            formik2.touched.startTime &&
                            Boolean(formik2.errors.startTime)
                          }
                          helperText={
                            formik2.touched.startTime &&
                            formik2.errors.startTime
                          }
                          required
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={3} sm={6}>
                        <Label htmlFor="">End Time</Label>
                        <br />
                        <TextField
                          type={"time"}
                          size="small"
                          name="endTime"
                          value={formik2.values.endTime}
                          onChange={formik2.handleChange}
                          error={
                            formik2.touched.endTime &&
                            Boolean(formik2.errors.endTime)
                          }
                          helperText={
                            formik2.touched.endTime && formik2.errors.endTime
                          }
                          required
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={12} md={3} sm={6}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            mt: 3,
                          }}
                        >
                          {/* <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "10px",
                          }}
                        >
                          <Tooltip title="edit"></Tooltip>
                          <Tooltip title="active/inactive">
                            <Button>
                              <Switch {...label} />
                            </Button>
                          </Tooltip>
                        </Box> */}
                          <Tooltip title="add">
                            <LoadingButton
                              loading={createLoading}
                              style={{
                                backgroundColor: theme.palette.primary.main,

                                color: "#ffff",
                                "&:hover": {
                                  background: theme.palette.primary.main,
                                },
                              }}
                              type="submit"
                              onClick={handleClick}
                            >
                              Add
                            </LoadingButton>
                          </Tooltip>
                        </Box>
                      </Grid>
                      {/* <Button
              style={{ color: "#1b3779", marginTop: "40px" }}
              onClick={() => handleremove(i)}>
              <RemoveIcon />
            </Button> */}
                    </Grid>
                  </Paper>
                </form>
                {scheduleData.length > 0
                  ? scheduleData.map((data, index) => (
                      <form
                        onSubmit={(e) =>
                          handleUpdateSchedule(e, data._id, index)
                        }
                      >
                        <Paper
                          sx={{
                            my: 2,
                            border: "1px solid lightgray",
                            borderRadius: "20px",
                            px: 2,
                            backgroundColor: theme.palette.grey[100],
                          }}
                          elevation={0}
                        >
                          <Grid
                            container
                            spacing={2}
                            sx={{ mt: 2, p: 1, mb: 2 }}
                          >
                            <Grid item xs={12} md={3} sm={6}>
                              <Label htmlFor="">Type</Label>
                              <br />
                              <Select
                                label="Type"
                                id="demo-simple-select-filled"
                                value={data.weekType}
                                name="weekType"
                                size="small"
                                onChange={(e) => handleOptionChange(e, index)}
                                disabled={editSchedule !== data._id}
                                required
                                fullWidth
                              >
                                {options.map((name) => (
                                  <MenuItem value={name.value} key={name.value}>
                                    {name.value}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Grid>
                            <Grid item xs={12} md={3} sm={6}>
                              <Label htmlFor="">Start Date</Label>
                              <br />
                              <TextField
                                type="date"
                                size="small"
                                name="startDate"
                                value={dayjs(data.startDate).format(
                                  "YYYY-MM-DD"
                                )}
                                onChange={(e) => handleOnChange(e, index)}
                                disabled={editSchedule !== data._id}
                                // error={
                                //   formik1.touched.startDate &&
                                //   Boolean(formik1.errors.startDate)
                                // }
                                // helperText={
                                //   formik1.touched.startDate &&
                                //   formik1.errors.startDate
                                // }
                                required
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} md={3} sm={6}>
                              <Label htmlFor="">End Date</Label>
                              <br />
                              <TextField
                                type="date"
                                size="small"
                                name="endDate"
                                value={dayjs(data.endDate).format("YYYY-MM-DD")}
                                onChange={(e) => handleOnChange(e, index)}
                                disabled={editSchedule !== data._id}
                                // error={
                                //   formik1.touched.endDate &&
                                //   Boolean(formik1.errors.endDate)
                                // }
                                // helperText={
                                //   formik1.touched.endDate &&
                                //   formik1.errors.endDate
                                // }
                                required
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} md={3} sm={6}>
                              <Label htmlFor="">Duration/per day(in hrs)</Label>
                              <br />

                              <TextField
                                size="small"
                                name="hoursPerDay"
                                type="number"
                                value={data.hoursPerDay}
                                onChange={(e) => handleOnChange(e, index)}
                                disabled={editSchedule !== data._id}
                                required
                                fullWidth
                              />
                            </Grid>

                            <Grid item xs={12} md={3} sm={6}>
                              <Label htmlFor="">Total Days</Label>
                              <br />

                              <TextField
                                size="small"
                                name="totalDays"
                                placeholder="Total Days"
                                value={Math.ceil(
                                  infoData.courseHours / data.hoursPerDay
                                )}
                                onChange={(e) => handleOnChange(e, index)}
                                disabled
                                fullWidth
                              />
                            </Grid>

                            <Grid item xs={12} md={3} sm={6}>
                              <Label htmlFor="">Start Time</Label>
                              <br />
                              <TextField
                                type={"time"}
                                size="small"
                                name="startTime"
                                value={data.startTime}
                                onChange={(e) => handleOnChange(e, index)}
                                disabled={editSchedule !== data._id}
                                // error={
                                //   formik1.touched.startTime &&
                                //   Boolean(formik1.errors.startTime)
                                // }
                                // helperText={
                                //   formik1.touched.startTime &&
                                //   formik1.errors.startTime
                                // }
                                required
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} md={3} sm={6}>
                              <Label htmlFor="">End Time</Label>
                              <br />
                              <TextField
                                type={"time"}
                                size="small"
                                name="endTime"
                                value={data.endTime}
                                onChange={(e) => handleOnChange(e, index)}
                                disabled={editSchedule !== data._id}
                                // error={
                                //   formik1.touched.endTime &&
                                //   Boolean(formik1.errors.endTime)
                                // }
                                // helperText={
                                //   formik1.touched.endTime &&
                                //   formik1.errors.endTime
                                // }
                                required
                                fullWidth
                              />
                            </Grid>

                            <Grid item xs={12} md={3} sm={6}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  mt: 3,
                                }}
                              >
                                <Box
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginLeft: "10px",
                                  }}
                                >
                                  <Tooltip title="edit"></Tooltip>
                                  <Tooltip title="active/inactive">
                                    <Button>
                                      <Switch
                                        {...label}
                                        disabled={editSchedule !== data._id}
                                        defaultChecked={data.activated}
                                        onClick={() => {
                                          if (editSchedule === data._id) {
                                            handleUpdateStatus(data._id);
                                          }
                                        }}
                                      />
                                    </Button>
                                  </Tooltip>
                                </Box>

                                <Tooltip
                                  title={
                                    editSchedule === data._id ? "Save" : "Edit"
                                  }
                                >
                                  <LoadingButton
                                    // size="small"
                                    variant="contained"
                                    loading={
                                      updateLoading && editSchedule == data._id
                                    }
                                    type={
                                      editSchedule === data._id
                                        ? "button"
                                        : "submit"
                                    }
                                    onClick={() => handleEditSchedule(data._id)}
                                  >
                                    {editSchedule === data._id
                                      ? "Save"
                                      : "Edit"}
                                  </LoadingButton>
                                </Tooltip>
                              </Box>
                            </Grid>
                            {/* <Button
                                        style={{ color: "#1b3779", marginTop: "40px" }}
                                        onClick={() => handleremove(i)}>
                                        <RemoveIcon />
                                      </Button> */}
                          </Grid>
                        </Paper>
                      </form>
                    ))
                  : ""}
              </>
            </>
          )}
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <SelfPaced />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <ClassRoom />
        </TabPanel>
      </Paper>
    </>
  );
};

export default Pricing;
