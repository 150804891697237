import FolderIcon from "@mui/icons-material/Folder";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import AddIcon from "@mui/icons-material/Add";
let cardList = [
  {
    id: 1,
    title: "General",
    // path:"/general",
    icon: <FolderIcon />,
  },
  {
    id: 2,
    title: "Usage restriction",
    // path:"/usagerestriction",
    icon: <NotInterestedIcon />,
  },
  {
    id: 3,
    title: "Usage limits",
    // path:"/usagelimits",
    icon: <AddIcon />,
  },
];
export default cardList;
