import {
  Box,
  Button,
  Container,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
const TableData = styled(TableCell)(() => ({
  fontSize: "15px",
  fontWeight: "bold",
  textAlign:'center'
}));

const Td=styled(TableCell)(()=>({
  textAlign: 'center',
  maxWidth:0,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}))
const Label = styled("label")(() => ({
  fontWeight: 650,
  fontSize: "15px",
  color: "#424242",
  
}));
const style = {
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};
const Live = () => {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [edit, setEdit] = useState();

  const [data, setData] = useState([]);
  const [liveData, setLiveData] = useState({
    course: "",
    courseDate:"",
    startTime: "",
    expiryDate: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLiveData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setData((pre) => [...pre, liveData]);
    setLiveData((prev) => ({
      course: "",
      courseDate:"",
      startTime: "",
      expiryDate: "",
    }));
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const handleEdit=()=>{
  //   setEdit();
  //   handleClickOpen()
  // }

  let a = 1;
  return (
    <Container>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          m: 1,
          gap: "10px",
        }}
      >
        {" "}
        <FormControl size="small" sx={{ m: 1, minWidth: 250 }}>
          <InputLabel id="demo-simple-select-label">Courses</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Courses"
          >
            <MenuItem>Java</MenuItem>
          </Select>
        </FormControl>
        <Tooltip title="Create">
          <Button
            onClick={handleClickOpen}
            sx={{
              background: "#747DE8",
              p: 1,
              "&:hover": {
                background: "#747DE8",
              },
            }}
            size="small"
            variant="contained"
          >
            Create
          </Button>
        </Tooltip>
      </Box>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableData sx={{ fontWeight: 600 }}>Sl.No</TableData>
              <TableData sx={{ fontWeight: 600 }}>Course</TableData>
              <TableData sx={{ fontWeight: 600 }}>Link</TableData>
              <TableData sx={{ fontWeight: 600 }}>Date</TableData>
              <TableData sx={{ fontWeight: 600 }}>Start Time</TableData>
              <TableData sx={{ fontWeight: 600 }}>Expiry Date</TableData>
              <TableData sx={{ fontWeight: 600 }}>Action</TableData>
            </TableRow>
          </TableHead>
          <TableBody>
             <TableRow>
                <Td>{a++}.</Td>
                <Td>
                  'Just Leave It Ya but never used'
                </Td>
                <Td>  'Don't Leave It use it again' </Td>
                <Td>

                </Td>
                <Td>
               
                </Td>
                <Td>
                  
                </Td>
                <TableCell sx={{textAlign:'center'}}>
                  <Tooltip title="Edit">
                    <Button>
                      <EditIcon color="blue" />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <Button>
                      <DeleteIcon sx={{ color: "red" }} />
                    </Button>
                  </Tooltip>
                </TableCell>
              </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Box sx={style}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  sx={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "20px",
                  }}
                  component="h2"
                >
                  {edit ? "Update" : "Create"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Label id="demo-simple-select-label">Course</Label>
                <Select
                  size="small"
                  fullWidth
                  name="course"
                  value={liveData.course}
                  onChange={handleChange}
                >
                  <MenuItem value="G">G</MenuItem>
                  <MenuItem value="GG">GG</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
              <Label id="demo-simple-select-label">Date</Label>
              <TextField
                  type="date"
                  size="small"
                  fullWidth
                  name="courseDate"
                  value={liveData.date}
                  onChange={handleChange}
                />

              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Label id="demo-simple-select-label">Start Time</Label>
                <TextField
                  type="time"
                  size="small"
                  fullWidth
                  name="startTime"
                  value={liveData.startTime}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Label id="demo-simple-select-label">Expiry Date</Label>
                <TextField
                  type="date"
                  size="small"
                  fullWidth
                  name="expiryDate"
                  value={liveData.expiryDate}
                  onChange={handleChange}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                display="flex"
                justifyContent={{
                  xs: "center",
                  sm: "center",
                  md: "flex-end",
                  lg: "flex-end",
                }}
              >
                <Button type="submit" variant="contained" onClick={handleClose}>
                  {edit ? "Update" : "Create"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Dialog>
    </Container>
  );
};

export default Live;

// {data.map((ele, i) => (
//   <TableRow>
//     <TableCell>{a++}</TableCell>
//     <TableCell>
//       {" "}
//       <TextField size="small" value={ele.course}/>
//     </TableCell>
//     <TableCell>Oh! God....</TableCell>
//     <TableCell>
      // <TextField type="date" size="small" fullWidth value={ele.courseDate}/>
       {/* {ele.course.length > 5 ? ele.course.substring(0, 5) + '...' : ele.course} */}
//     </TableCell>
//     <TableCell>
//       <TextField
//         type="time"
//         fullWidth
//         size="small"
//         value={ele.startTime}
//       />
//     </TableCell>
//     <TableCell>
//       <TextField type="date" size="small" value={ele.expiryDate} />
//     </TableCell>
//     <TableCell>
//       <Tooltip title="Edit">
//         <Button>
//           <EditIcon color="blue" />
//         </Button>
//       </Tooltip>
//       <Tooltip title="Delete">
//         <Button>
//           <DeleteIcon sx={{ color: "red" }} />
//         </Button>
//       </Tooltip>
//     </TableCell>
//   </TableRow>
// ))
 
