import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  styled,
  MenuItem,
  Select,
  Typography,
  TextField,
} from "@mui/material";
import React from "react";

const UsageLimit = () => {
     const [age, setAge] = React.useState("");

     const handleChange = (event) => {
       setAge(event.target.value);
     };
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column", // to stack items vertically
          gap: "10px",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Box sx={{ display: "flex", gap: 2,alignItems:"center" }}>
          <Typography>Usage limit per coupon</Typography>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <TextField
              type="number"
              placeholder="Unlimited Usage"
              name="value"
              size="small"
              value=""
            />
          </FormControl>
        </Box>

        <Box sx={{ display: "flex", gap: 2,alignItems:"center" }}>
          <Typography>Limit usage to X items</Typography>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <TextField
              type="number"
              placeholder="Apply to all qualifying items in cart"
              name="value"
              size="small"
              value=""
            />
          </FormControl>
        </Box>

      
 <Box sx={{display:"flex",gap:2,alignItems:"center"}}>
        <Typography>Usage limit per user</Typography>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <TextField
            type="number"
            placeholder="Unlimited Usage"
            name="value"
            size="small"
            value=""
          />
        </FormControl>
        </Box>
      </Box>
    </Box>
  );
};

export default UsageLimit;
