import { Delete, Edit, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { post, put, get, del } from "../../api/apiMethods";
import { urls } from "../../api/urlConstants";
import { async } from "q";
import DeleteModal from "../DeleteModal";
import dayjs from "dayjs";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { theme } from "../../theme";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";

const Label = styled("label")(() => ({
  fontWeight: 650,
  fontSize: "15px",
  color: "#424242",
}));

const TableHeading = styled(TableCell)(() => ({
  fontWeight: 600,
  textAlign: "center",
}));

const TableRowData = styled(TableCell)(() => ({
  textAlign: "center",
}));

const Coupon = () => {
  const [data, setData] = useState([]);
  const [list, setList] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchFilter, setSearchFilter] = useState([]);
  const [expiryDate, setExpiryDate] = useState("");
  const navigate = useNavigate();

  const [formData, setFormData] = useState({});
  const [editCoupon, setEditCoupon] = useState();
  const [deleteModal, setDeleteModal] = React.useState({
    open: false,
    itemToDelete: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleCloseDeleteModal = () => {
    setDeleteModal({ open: false, itemToDelete: "" });
  };
  const handleOnchange = (id, e) => {
    const { name, value } = e.target;
    let newList = list.map((l) => (l._id === id ? { ...l, [name]: value } : l));
    setList(newList);
  };
  const handleOnchangeDescribtion = (id, e) => {
    const { name, value } = e.target;
    setList((prev) => {
      return prev.map((item) =>
        item._id === id
          ? {
              ...item,
              notes: value,
            }
          : item
      );
    });
  };
  const handleOnCouponChange = (id, e) => {
    const { name, value } = e.target;
    setList((prev) => {
      return prev.map((item) =>
        item._id === id
          ? {
              ...item,
              generalSchema: { ...item.generalSchema, value: value },
            }
          : item
      );
    });
  };
  const handleOnUsagechange = (id, e) => {
    const { name, value } = e.target;
    setList((prev) => {
      return prev.map((item) =>
        item._id === id
          ? {
              ...item,
              usageLimit: { ...item.usageLimit, usagePerCoupan: value },
            }
          : item
      );
    });
  };
  const handleExpiryChange = (id, e) => {
    const { name, value } = e.target;
    setList((prev) => {
      return prev.map((item) =>
        item._id === id
          ? {
              ...item,
              generalSchema: { ...item.generalSchema, expiryDate: value },
            }
          : item
      );
    });
  };
  const handleOnTypechange = (id, e) => {
    // console.log(id, "idddd");
    const { name, value } = e.target;
    setList((prevList) => {
      return prevList.map((item) =>
        item._id === id
          ? {
              ...item,
              generalSchema: { ...item.generalSchema, coupanType: value },
            }
          : item
      );
    });
  };
  useEffect(() => {
    getCopon();
  }, []);

  const getCopon = async () => {
    try {
      const data = await get(`${urls.coupon.getList}`);
      setList(data.data.result);

      console.log(data.data.result, "rrrrrrrrrrrrrrrrrrrrrr++++++++++++++++");
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      list.length > 0 &&
        setSearchFilter(
          list.filter((ele) =>
            ele.code.toLowerCase().includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };

  const handleEditCoupon = (id, item) => {
    // const data = await put(`${urls.coupon.editCoupon}${id}`, payload);
    // getCopon();
    const editedCoupon = list.find((item) => item._id === id);
    if (editedCoupon) {
      navigate("/addcoupon", { state: { couponData: id, couponItem: item } });
    }
  };

  const handleDeleteone = async (id) => {
    try {
      const res = await del(
        `${urls.coupon.delCoupan}${deleteModal.itemToDelete}`
      );
      setDeleteModal({ open: false, itemToDelete: "" });

      getCopon();
    } catch (error) {
      console.log(error);
    }
  };
  const handleNavigate = () => {
    navigate("/addcoupon");
  };
  return (
    <Box sx={{ padding: "0 30px" }}>
      <Box
        display="flex"
        gap="1%"
        justifyContent={{
          xs: "center",
          sm: "flex-end",
          md: "flex-end",
          lg: "flex-end",
        }}
        m="20px 10px"
      >
        <FormControl sx={{ width: 227 }}>
          <TextField
            size="small"
            placeholder="Search Coupon"
            value={search}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton edge="start" type="submit">
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <Button
          startIcon={<AddIcon />}
          size="small"
          variant="contained"
          onClick={handleNavigate}
          style={{ padding: "0 20px" }}
        >
          Add
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeading>Coupon Code</TableHeading>
              <TableHeading>Coupon Type</TableHeading>
              <TableHeading>Coupon amount</TableHeading>

              <TableHeading>Expiry Date</TableHeading>

              <TableHeading>Action</TableHeading>
            </TableRow>
          </TableHead>
          <TableBody>
            {search
              ? searchFilter.map((item, i) => (
                  <TableRow key={item._id}>
                    <TableRowData>{item.code}</TableRowData>
                    <TableRowData>{item.generalSchema.coupanType}</TableRowData>
                    <TableRowData>{item.generalSchema.value}</TableRowData>

                    <TableRowData>
                      <Typography>
                        {dayjs(item.generalSchema.expiryDate).format(
                          "YYYY-MM-DD"
                        )}
                      </Typography>
                      {/* <TextField
                        size="small"
                        name="expiryDate"
                        onChange={(e) => handleExpiryChange(item._id, e)}
                        type="date"
                        value={dayjs(item.generalSchema.expiryDate).format(
                          "YYYY-MM-DD"
                        )}
                      /> */}
                    </TableRowData>

                    <TableRowData>
                      <Tooltip title="Update">
                        <Button
                          onClick={(e) => handleEditCoupon(item._id, item)}
                        >
                          <EditIcon color="blue" />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <Button
                          onClick={() =>
                            setDeleteModal({
                              itemToDelete: item._id,
                              open: true,
                            })
                          }
                        >
                          <Delete sx={{ color: "red" }} />
                        </Button>
                      </Tooltip>
                    </TableRowData>
                  </TableRow>
                ))
              : list.map((item, i) => (
                  <TableRow key={item._id}>
                    <TableRowData>{item.code}</TableRowData>
                    <TableRowData>{item.generalSchema.coupanType}</TableRowData>
                    <TableRowData>{item.generalSchema.value}</TableRowData>

                    {/* <TableRowData>
                    <TextField
                      size="small"
                      name="Description"
                      onChange={(e) => handleOnchangeDescribtion(item._id, e)}
                      value={item.notes}
                    />
                  </TableRowData>
                  <TableRowData>
                    <TextField />
                  </TableRowData>
                  <TableRowData>
                    <TextField
                      size="small"
                      name="usagePerCoupan"
                      onChange={(e) => handleOnUsagechange(item._id, e)}
                      type="Number"
                      value={item.usageLimit.usagePerCoupan}
                    />
                  </TableRowData> */}
                    <TableRowData>
                      <Typography>
                        {dayjs(item.generalSchema.expiryDate).format(
                          "YYYY-MM-DD"
                        )}
                      </Typography>
                      {/* <TextField
                        size="small"
                        name="expiryDate"
                        onChange={(e) => handleExpiryChange(item._id, e)}
                        type="date"
                        value={dayjs(item.generalSchema.expiryDate).format(
                          "YYYY-MM-DD"
                        )}
                      /> */}
                    </TableRowData>

                    <TableRowData>
                      <Tooltip title="Update">
                        <Button
                          onClick={(e) => handleEditCoupon(item._id, item)}
                        >
                          <EditIcon color="blue" />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <Button
                          onClick={() =>
                            setDeleteModal({
                              itemToDelete: item._id,
                              open: true,
                            })
                          }
                        >
                          <Delete sx={{ color: "red" }} />
                        </Button>
                      </Tooltip>
                    </TableRowData>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!list.length && !search.trim() && (
        <Typography
          variant="h6"
          sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
        >
          No data found
        </Typography>
      )}
      {search.trim() && !searchFilter.length && (
        <Typography
          variant="h6"
          sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
        >
          No data found
        </Typography>
      )}

      <Dialog
        open={deleteModal.open}
        onClose={handleCloseDeleteModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description" fontWeight={600}>
            Are you sure you want to delete this?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteModal}>No</Button>
          <Button onClick={(e) => handleDeleteone(e)} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
export default Coupon;

// import { Delete, Edit, Search } from "@mui/icons-material";
// import {
//   Box,
//   Button,
//   Container,
//   FormControl,
//   Grid,
//   IconButton,
//   InputAdornment,
//   InputLabel,
//   MenuItem,
//   Select,
//   TextField,
//   styled,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   Tooltip,
//   Typography,
// } from "@mui/material";
// import React, { useEffect } from "react";
// import SaveIcon from "@mui/icons-material/Save";
// import { useState } from "react";
// import { post, put, get, del } from "../../api/apiMethods";
// import { urls } from "../../api/urlConstants";
// import { async } from "q";
// import DeleteModal from "../DeleteModal";
// import dayjs from "dayjs";
// import { LoadingButton } from "@mui/lab";

// const Label = styled("label")(() => ({
//   fontWeight: 650,
//   fontSize: "15px",
//   color: "#424242",
// }));
// const Coupon = () => {
//   const [data, setData] = useState([]);
//   const [list, setList] = useState([]);
//   const [deleteModal, setDeleteModal] = React.useState(false);
//   const [search, setSearch] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [searchFilter, setSearchFilter] = useState([]);
//   const [expiryDate, setExpiryDate] = useState("");

//   const [formData, setFormData] = useState({});
//   const [editCoupon, setEditCoupon] = useState();

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prev) => ({
//       ...prev,
//       [name]: value,
//     }));
//   };

//   const handleOnchange = (id, e) => {
//     // console.log(id, "idddd");

//     const { name, value } = e.target;

//     let newList = list.map((l) => (l._id === id ? { ...l, [name]: value } : l));
//     setList(newList);
//   };

//   useEffect(() => {
//     getCopon();
//   }, []);

//   const getCopon = async () => {
//     try {
//       const data = await get(`${urls.coupon.getList}`);
//       setList(data.data.result);

//       // console.log(data.data.result, "rrrrrrrrrrrrrrrrrrrrrr");
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const handleAddSubmit = async (e) => {
//     e.preventDefault();
//     // console.log(formData, "formdataa");
//     setLoading(true);

//     try {
//       const data = await post(`${urls.coupon.create}`, {
//         coupanType: formData.coupanType,
//         value: formData.value,
//         code: formData.code,
//         expiryDate: expiryDate,
//       });
//       getCopon();
//       setFormData({});
//       setLoading(false);
//     } catch (error) {
//       setLoading(false);
//       console.log(error);
//     }
//   };

//   const handleSearch = (e) => {
//     const { value } = e.target;
//     setSearch(value.trim());
//     if (value.trim() !== "") {
//       list.length > 0 &&
//         setSearchFilter(
//           list.filter((ele) =>
//             ele.code.toLowerCase().includes(value.toLowerCase().trim())
//           )
//         );
//     } else {
//       setSearchFilter([]);
//     }
//   };

//   const handleEditCoupon = async (id, e) => {
//     let doc = list.filter((l) => l._id === id)[0];
//     console.log(doc, "oooooooo");
//     const payload = {
//       code: doc.code,
//       coupanType: doc.coupanType,
//       value: doc.value,
//       expiryDate: doc.expiryDate,
//     };
//     const data = await put(`${urls.coupon.editCoupon}${id}`, payload);
//     getCopon();
//   };

//   const handleDeleteone = async (id) => {
//     setDeleteModal(true);

//     try {
//       const res = await del(`${urls.coupon.delCoupan}${id}`);

//       getCopon();
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   return (
//     <Box sx={{ padding: "0 30px" }}>
//       <form onSubmit={handleAddSubmit}>
//         <Grid container spacing={2} mt={1}>
//           <Grid item xs={12} sm={12} md={12}>
//             <Box
//               display="flex"
//               justifyContent={{
//                 xs: "center",
//                 sm: "flex-end",
//                 md: "flex-end",
//                 lg: "flex-end",
//               }}
//               m="20px 10px"
//             >
//               <TextField
//                 size="small"
//                 placeholder="Search Coupon"
//                 value={search}
//                 onChange={handleSearch}
//                 InputProps={{
//                   startAdornment: (
//                     <InputAdornment position="start">
//                       <IconButton edge="start" type="submit">
//                         <Search />
//                       </IconButton>
//                     </InputAdornment>
//                   ),
//                 }}
//               />
//             </Box>
//           </Grid>

//           <Grid item xs={12} sm={6} md={2} lg={2.5}>
//             <Label htmlFor="">Coupon Code</Label>
//             <TextField
//               size="small"
//               fullWidth
//               name="code"
//               required
//               placeholder="coupon code"
//               value={formData.code || ""}
//               onChange={handleChange}
//             />
//           </Grid>
//           <Grid item xs={12} sm={6} md={2} lg={2.5}>
//             <FormControl fullWidth size="small">
//               <Label id="demo-simple-select-label">Discount Type</Label>

//               <Select
//                 labelId="demo-simple-select-label"
//                 id="demo-simple-select"
//                 size="small"
//                 name="coupanType"
//                 required
//                 value={formData.coupanType || ""}
//                 onChange={handleChange}
//                 placeholder="discount type"
//               >
//                 <MenuItem value={"Percentage"}>Percentage</MenuItem>
//                 <MenuItem value={"Flat"}>Flat</MenuItem>
//               </Select>
//             </FormControl>
//           </Grid>
//           <Grid item xs={12} sm={6} md={2} lg={2.5}>
//             <Label htmlFor="">Discount</Label>
//             <TextField
//               size="small"
//               fullWidth
//               name="value"
//               placeholder="discount"
//               required
//               value={formData.value || ""}
//               onChange={handleChange}
//             />
//           </Grid>
//           <Grid item xs={12} sm={6} md={2} lg={2.5}>
//             <Label htmlFor="">Expiry Date</Label>
//             <TextField
//               type="date"
//               size="small"
//               fullWidth
//               required
//               name="expiryDate"
//               onChange={(e) => {
//                 // console.log(e.target.value, "=========");
//                 setExpiryDate(e.target.value);
//               }}
//               value={expiryDate}
//             />
//           </Grid>
//           <Grid item xs={12} sm={6} md={2} lg={2} sx={{ mt: 3 }}>
//             <LoadingButton
//               variant="contained"
//               type="submit"
//               sx={{
//                 background: "#747DE8",
//                 "&:hover": {
//                   background: "#747DE8",
//                 },
//               }}
//               loading={loading}
//             >
//               Create
//             </LoadingButton>
//           </Grid>
//         </Grid>
//       </form>

//       <Table sx={{ marginTop: "50px" }}>
//         <TableHead>
//           <TableRow>
//             <TableCell sx={{ fontWeight: 600 }}>Coupon Code</TableCell>
//             <TableCell sx={{ fontWeight: 600 }}>Discount Type</TableCell>
//             <TableCell sx={{ fontWeight: 600 }}>Discount</TableCell>
//             <TableCell sx={{ fontWeight: 600 }}>Expiry Date</TableCell>
//             <TableCell sx={{ fontWeight: 600 }}>Action</TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody >
//           {search
//             ? searchFilter.map((item, i) => (
//                 <TableRow key={item._id} >
//                   <TableCell>
//                     <TextField
//                       size="small"
//                       name="code"
//                       onChange={(e) => handleOnchange(item._id, e)}
//                       value={item.code}
//                     />
//                   </TableCell>
//                   <TableCell>
//                     <Select
//                       fullWidth
//                       name="coupanType"
//                       onChange={(e) => handleOnchange(item._id, e)}
//                       size="small"
//                       value={item.coupanType}
//                     >
//                       <MenuItem value={item.coupanType}>
//                         {item.coupanType}
//                       </MenuItem>
//                     </Select>
//                   </TableCell>
//                   <TableCell>
//                     <TextField
//                       name="value"
//                       size="small"
//                       onChange={(e) => handleOnchange(item._id, e)}
//                       value={item.value}
//                     />
//                   </TableCell>
//                   <TableCell>
//                     <TextField
//                       size="small"
//                       name="expiryDate"
//                       onChange={(e) => handleOnchange(item._id, e)}
//                       type="date"
//                       value={item.expiryDate}
//                     />
//                   </TableCell>

//                   <TableCell>
//                     <Button onClick={(e) => handleEditCoupon(item._id, e)}>
//                       <SaveIcon color="blue" />
//                     </Button>
//                     <Button onClick={() => handleDeleteone(item._id)}>
//                       <Delete sx={{ color: "red" }} />
//                     </Button>

//                     <DeleteModal
//                       deleteModal={deleteModal}
//                       handleDelete={handleDeleteone}
//                       id={item._id}
//                       setDeleteModal={setDeleteModal}
//                     />
//                   </TableCell>
//                 </TableRow>

//               ))
//             : list.map((item, i) => (
//               <TableRow key={item._id}>

//                   <TableCell>
//                     <TextField
//                       size="small"
//                       name="code"
//                       onChange={(e) => handleOnchange(item._id, e)}
//                       value={item.code}
//                     />
//                   </TableCell>
//                   <TableCell>
//                     <Select
//                       fullWidth
//                       name="coupanType"
//                       onChange={(e) => handleOnchange(item._id, e)}
//                       size="small"
//                       value={item.coupanType}
//                     >
//                       {/* <MenuItem value={item.coupanType}>
//                         {item.coupanType}
//                       </MenuItem> */}
//                       <MenuItem value={"Percentage"}>Percentage</MenuItem>
//                       <MenuItem value={"Flat"}>Flat</MenuItem>
//                     </Select>
//                   </TableCell>
//                   <TableCell>
//                     <TextField
//                       name="value"
//                       size="small"
//                       onChange={(e) => handleOnchange(item._id, e)}
//                       value={item.value}
//                     />
//                   </TableCell>
//                   <TableCell>
//                     <TextField
//                       size="small"
//                       name="expiryDate"
//                       onChange={(e) => handleOnchange(item._id, e)}
//                       type="date"
//                       value={dayjs(item.expiryDate).format("YYYY-MM-DD")}
//                     />
//                   </TableCell>

//                   <TableCell>
//                     <Tooltip title="Update">
//                       <Button onClick={(e) => handleEditCoupon(item._id, e)}>
//                         <SaveIcon color="blue" />
//                       </Button>
//                     </Tooltip>
//                     <Tooltip title="Delete">
//                       <Button onClick={() => setDeleteModal(item._id)}>
//                         <Delete sx={{ color: "red" }} />
//                       </Button>
//                     </Tooltip>

//                     <DeleteModal
//                       deleteModal={deleteModal}
//                       handleDelete={handleDeleteone}
//                       id={item._id}
//                       setDeleteModal={setDeleteModal}
//                     />
//                   </TableCell>
//                 </TableRow>
//               ))}
//         </TableBody>
//       </Table>
//       {!list.length && !search.trim() && (
//         <Typography
//           variant="h6"
//           sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
//         >
//           No data found
//         </Typography>
//       )}
//       {search.trim() && !searchFilter.length && (
//         <Typography
//           variant="h6"
//           sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
//         >
//           No data found
//         </Typography>
//       )}
//     </Box>
//   );
// };

// export default Coupon;
