import React, { useState, useRef, useContext, useEffect } from "react";
import css from "./style.module.css";
import {
  Container,
  Fab,
  Button,
  Grid,
  Typography,
  Box,
  styled,
  InputLabel,
  TextField,
  ListItem,
  MenuList,
  List,
  Tooltip,
  Select,
  MenuItem,
  FormControl,
  Divider,
} from "@mui/material";

import Dialog from "@mui/material/Dialog";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import AddQuiz from "./AddQuiz";
import AddChapter from "./AddChapter";
import ChapterData from "./ChapterData";
import RecevedData from "./RecevedData";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormSelect from "../../FormSelect";
import { post, put, get } from "../../../api/apiMethods";
import { useLocation } from "react-router-dom";
import { urls } from "../../../api/urlConstants";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import Radio from "@mui/material/Radio";
import EditQuiz from "./EditQuiz";
import { CountertopsOutlined } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { LoadingButton } from "@mui/lab";

const Buttons = styled(Typography)(() => ({
  backgroundColor: "#e0e0e0",
  color: "black",
  border: "1px solid e0e0e0",
  borderRadius: 5,
  fontSize: "13px",
  height: "35px",
  width: "140px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  boxShadow: 3,
  marginTop: "10px",
}));

const styleFlash = {
  width: "100%",
  position: "relative",
  border: "2px solid white",
  bgcolor: "background.paper",
  p: 4,
};
const style = {
  width: "100%",
  position: "relative",
  border: "2px solid white",
  bgcolor: "background.paper",
  p: 4,
};
const styleQuiz = {
  position: "relative",
  width: "100%",
  border: "2px solid white",

  bgcolor: "background.paper",
  p: 4,
};

// const style = {
//   // width: "600px",
//   // position: "absolute",
//   // top: "50%",
//   // left: "50%",
//   // transform: "translate(-50%, -50%)",
//   // border: "2px solid white",
//   // boxShadow: 24,
//   // bgcolor: "background.paper",
//   // p: 4,
// };
const style2 = {
  // width: "450px",
  // position: "relative",
  // top: "50%",
  // left: "50%",
  // transform: "translate(-50%, -50%)",
  // border: "2px solid white",
  // borderRadius: "20px",
  // boxShadow: 24,
  // bgcolor: "background.paper",
  // p: 4,

  position: "relative",
  width: "100%",
  border: "2px solid white",
  borderRadius: "20px",
  bgcolor: "background.paper",

  height: 300,
  bgcolor: "background.paper",
  p: 4,
};

const RadioButtons = styled(Radio)(() => ({
  marginTop: "-10px",
}));

const Boxes = styled(Box)(() => ({
  display: "flex",
}));

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const InputLabels = styled(InputLabel)(() => ({
  fontSize: "12px",
  fontWeight: 580,
  color: "#616161",
}));

const Buttons1 = styled(Button)(() => ({
  backgroundColor: "#e0e0e0",
  color: "black",
  border: "1px solid e0e0e0",
  borderRadius: 5,
  fontSize: "12px",
  height: "35px",
  width: "200px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  boxShadow: 3,
  textTransform: "uppercase",
  marginTop: "2px",
  "&:hover": {
    backgroundColor: "#e0e0e0",
  },
}));

const Label = styled("label")(() => ({
  fontWeight: "bold",
}));

const Adding = () => {
  const { namedata, quizData, flashData, imageData } = useContext(ChapterData);
  const location = useLocation();
  const { selectedCourseTitle } = location.state || {};
  const navigate = useNavigate();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [updateList, setUpdateList] = useState(1);

  const { resetData } = location.state || {};

  const fileInputRef = useRef(null);
  const { selectedCourse } = location.state || {};
  const value1 = useContext(ChapterData);
  let dataName = useContext(ChapterData);
  const [add, setAdd] = React.useState(false);
  const [showContent, setShowContent] = useState(false);
  const [list, setList] = useState([]);
  const [open, setOpen] = useState(false);
  const [openQuiz, setOpenQuiz] = useState(false);
  const [openFlash, setOpenFlash] = useState(false);
  const [videoFile, setVideoFile] = useState(null);
  const [video, setVideo] = useState("");
  const [formCount, setFormCount] = useState(1);
  const [value, setValue] = React.useState(Array(formCount).fill(""));
  let quizName = useContext(ChapterData);

  const [quiz, setQuiz] = useState([
    {
      question: "",
      option1: "",
      option2: "",
      option3: "",
      option4: "",
    },
  ]);

  const [course, setCourse] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingData, setEditingData] = useState(null);
  const [name, setName] = useState("");
  const [chapterLoading, setChapterLoading] = useState(false);
  const [flashcardLoading, setFlashcardLoading] = useState(false);
  const [quizLoading, setQuizLoading] = useState(false);

  const handleEdit = (data) => {
    setEditingData(data);
    setIsEditModalOpen(true);
  };
  const [contentList, setContentList] = useState([]);
  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    setEditingData(null);
    setQuiz([
      { question: "", option1: "", option2: "", option3: "", option4: "" },
    ]);
    setValue("");
    setFormCount(1);
    setOpenQuiz(false);
  };
  // let { question, option1, option2, option3, option4 } = quiz;

  let flashDataText = useContext(ChapterData);
  const [flashcard, setFlashcard] = React.useState("Text");
  const [imageFile, setImageFile] = React.useState(null);
  const [image, setImage] = useState("");
  const [formCount1, setFormCount1] = useState(1);
  let [flashText, setFlashText] = useState("");
  // let [imageData, setImageData] = useState("");

  const { data } = location.state || {};

  const handleFileChangeFlash = (event) => {
    const file = event.target.files[0];
    // console.log(file, "file");
    setImageFile(URL.createObjectURL(file));
    setImage(file);
  };

  const handleUploadButtonClickFlash = () => {
    fileInputRef.current.click();
  };

  const handleChangeFlash = (event) => {
    setFlashcard(event.target.value);
    if (event.target.value !== "Image") {
      setImageFile(null);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setName("");
    setVideoFile("");
  };
  const handleOpenQuiz = () => setOpenQuiz(true);

  const handleCloseQuiz = async () => {
    setQuiz([
      { question: "", option1: "", option2: "", option3: "", option4: "" },
    ]);
    setValue("");
    setFormCount(1);
    setOpenQuiz(false);
  };
  const handleOpenFlash = () => setOpenFlash(true);
  const handleCloseFlash = () => {
    setOpenFlash(false);
    setFlashcard("Text");
    setImageFile("");
    setFlashText("");
  };

  let items = [
    {
      id: "1",
      // path: "/upload-courses/adding/addchapter",
      name: "Add Chapter",
      // onClick: (selectedCourse) => {
      //   if (selectedCourse) {
      //     navigate("/upload-courses/adding/addchapter", {
      //       state: { selectedCourse: selectedCourse },
      //     });
      //   }
      // },
      onClick: handleOpen,
    },
    {
      id: "2",
      path: "/upload-courses/adding/addquiz",
      name: "Add Quiz",
      // onClick: (data) => {
      //   navigate("/upload-courses/adding/addquiz", {
      //     state: { data: data },
      //   });
      //   console.log(data);
      // },
      onClick: handleOpenQuiz,
    },
    {
      id: "3",
      // path: "/upload-courses/adding/addflashcard",
      name: "Add FlashCard",
      // onClick: (data) => {
      //   navigate("/upload-courses/adding/addflashcard", {
      //     state: { data: data },
      //   });
      //   console.log(data);
      // },
      onClick: handleOpenFlash,
    },
  ];

  const handleShow = () => {
    if (!selectState.values.title) {
      window.alert("Please select a course before adding.");
    } else {
      setShowContent(!showContent);
    }
  };

  const toggleContent = () => {
    setShowContent((prev) => !prev);
  };

  // const handleOpen = (selectedCourse) => {
  //   console.log(selectedCourse, "ssssss");
  //   if (selectedCourse) {
  //     navigate("/upload-courses/adding/addchapter", {
  //       state: { selectedCourse: selectedCourse },
  //     });
  //   }
  // };

  useEffect(() => {
    getCourse();
  }, []);

  const getCourse = async () => {
    try {
      const { data, status } = await get(`${urls?.course?.getList}`);

      setList(
        data?.result?.data?.map((m) => ({ label: m?.title, value: m?.title }))
      );
      if (data?.result?.data.length > 0 && !selectedCourseTitle) {
        selectState.setFieldValue("title", data?.result?.data[0].title);
      }
      setCourse(data?.result?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const selectState = useFormik({
    initialValues: {
      title: selectedCourseTitle ? selectedCourseTitle : resetData,
    },
    validationSchema: Yup.object({
      title: Yup.string().required().label("Select Product Name"),
    }),
    onSubmit: (values) => {
      console.log(values);
    },
    validateOnBlur: false,
  });
  const courses = course.find((m) => selectState.values.title === m.title);

  const getCourseContent = async () => {
    try {
      const data = await get(
        `${urls.courseContent.getCourseContent}/${courses._id}`
      );

      setContentList(data.data.result.materials || []);
    } catch (error) {
      console.error(error);
    }
  };
  // console.log(contentList, "========================");

  useEffect(() => {
    getCourseContent();
  }, [courses]);

  useEffect(() => {
    if (selectState.values.title) {
      setShowContent(true);
    } else {
      setShowContent(false);
    }
  }, [selectState.values.title]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    // console.log(file, "file");
    setVideoFile(URL.createObjectURL(file));
    setVideo(file);
  };

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setChapterLoading(true);
    const formsData = new FormData();
    let courseId;
    courseId = course.filter((m) => selectState.values.title === m.title)[0]
      ._id;
    const material = {
      type: "Chapter",
      orderSequence: contentList.length + 1,
      name,
    };

    formsData.append("material", JSON.stringify(material));

    // Convert material to JSON string
    formsData.append("file", video);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    // console.log(formsData, "formsData====");

    try {
      const response = await post(
        `${urls.courseContent.createCourseContent}/${courseId}`,
        formsData,
        config
      );
      // console.log("Response:", response);

      console.log(response, "resssss");
      getCourseContent();
      setName("");
      setVideoFile("");
      handleClose();

      // Other actions you want to perform after a successful upload
      setChapterLoading(false);
    } catch (error) {
      console.error(error);
      setChapterLoading(false);

      // Handle the error as needed
    }
  };

  const handleAddQuiz = () => {
    setFormCount(formCount + 1);
    setQuiz([
      ...quiz,
      {
        question: "",
        option1: "",
        option2: "",
        option3: "",
        option4: "",
      },
    ]);
  };
  const handleCancel = () => {
    setFormCount(formCount - 1);
  };

  //radio selection
  const handleOptionChange = (event, formIndex, optionName) => {
    const { values } = event.target;
    const selectOption = [...value];
    selectOption[formIndex] = optionName;
    setValue(selectOption);
  };

  const handleChange = (e, formIndex) => {
    const { name, value } = e.target;
    const updatedFormData = [...quiz];
    updatedFormData[formIndex][name] = value;
    setQuiz(updatedFormData);
  };

  const handleSubmitFlash = async (e) => {
    e.preventDefault();
    setFlashcardLoading(true);

    try {
      const courseId = course.find(
        (m) => selectState.values.title === m.title
      )?._id;

      if (courseId) {
        const material = {
          type: "FlashCards",
          orderSequence: contentList.length + 1,
          flashCard: {
            cardType: flashcard,
            text: flashText,
            image: imageFile,
          },
        };

        const formData = new FormData();
        formData.append("material", JSON.stringify(material));
        formData.append("file", image);
        const response = await post(
          `${urls.courseContent.createCourseContent}/${courseId}`,
          formData
        );
        console.log(response, "respose flashccard");
        await getCourseContent();
        navigate("/upload-courses/adding", {
          state: { resetData: data },
        });
        setFlashcardLoading(false);
        handleCloseFlash();
      } else {
        console.error("Course not found");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmitQuiz = async (event) => {
    event.preventDefault();

    setQuizLoading(true);
    // Ensure quiz is properly initialized
    if (quiz) {
      quizName.setQuizData([...quizName.quizData, quiz]);
      setQuiz("");
      let courseId;
      courseId = course.filter(
        (m) => selectState?.values?.title === m?.title
      )[0]._id;
      try {
        const quizData = quiz?.map((q, index) => {
          return {
            question: q?.question,
            options: [
              { name: q?.option1 },
              { name: q?.option2 },
              { name: q?.option3 },
              { name: q?.option4 },
            ],
            correctOption: value[index],
          };
        });

        const material = {
          type: "Quiz",
          orderSequence: contentList?.length + 1,
          quiz: quizData,
        };

        const formData = new FormData();

        formData.append("material", JSON.stringify(material));

        const response = await post(
          `${urls.courseContent.createCourseContent}/${courseId}`,
          formData
        );
        // console.log(response, "ttttesponse");
        if (response) {
          getCourseContent();
          setQuizLoading(false);
          handleCloseQuiz();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (contentList.length) {
      setUpdateList((prev) => prev + 1);
    }
  }, [contentList.length]);

  let a = 1;
  const renderForms = () => {
    const forms = [];
    for (let i = 0; i < formCount; i++) {
      const formIndex = i;
      const form = quiz[i];
      forms.push(
        <Grid container spacing={2} key={i} sx={{ marginTop: 2 }}>
          <Grid item xs={12} md={12}>
            <InputLabels>{a++}. Quiz Question</InputLabels>
            <TextField
              fullWidth
              size="small"
              name="question"
              value={form?.question}
              onChange={(e) => handleChange(e, formIndex)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Boxes>
              <InputLabels>Option1</InputLabels>
              <RadioButtons
                {...label}
                size="small"
                color="default"
                // checked={value === form?.option1}
                // onChange={handleOptionChange}
                checked={value[formIndex] === form?.option1}
                onChange={(e) =>
                  handleOptionChange(e, formIndex, form?.option1)
                }
                value={form?.option1}
                type="radio"
              />
            </Boxes>
            <TextField
              fullWidth
              size="small"
              name="option1"
              value={form?.option1}
              onChange={(e) => handleChange(e, formIndex)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Boxes>
              <InputLabels>Option2</InputLabels>
              <RadioButtons
                {...label}
                size="small"
                color="default"
                checked={value[formIndex] === form?.option2}
                onChange={(e) =>
                  handleOptionChange(e, formIndex, form?.option2)
                }
                value={form?.option2}
                type="radio"
              />
            </Boxes>
            <TextField
              fullWidth
              size="small"
              name="option2"
              value={form?.option2}
              onChange={(e) => handleChange(e, formIndex)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Boxes>
              <InputLabels>Option3</InputLabels>
              <RadioButtons
                {...label}
                size="small"
                color="default"
                checked={value[formIndex] === form?.option3}
                onChange={(e) =>
                  handleOptionChange(e, formIndex, form?.option3)
                }
                value={form?.option3}
                type="radio"
              />
            </Boxes>
            <TextField
              fullWidth
              size="small"
              name="option3"
              value={form?.option3}
              onChange={(e) => handleChange(e, formIndex)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Boxes>
              <InputLabels>Option4</InputLabels>
              <RadioButtons
                {...label}
                size="small"
                color="default"
                checked={value[formIndex] === form?.option4}
                onChange={(e) =>
                  handleOptionChange(e, formIndex, form?.option4)
                }
                value={form?.option4}
                type="radio"
              />
            </Boxes>
            <TextField
              fullWidth
              size="small"
              name="option4"
              value={form?.option4}
              onChange={(e) => handleChange(e, formIndex)}
            />
          </Grid>
        </Grid>
      );
    }

    return forms;
  };

  return (
    <>
      <Box sx={{ padding: "30px 20px 0 30px" }}>
        {/* <Paper> */}
        <Grid container sx={{ p: 1 }}>
          {/* <Grid item xs={12} sm={12} md={9} sx={{ margin: "auto" }}>
            <Typography variant="body">
              Note: Add Quizzes and flashcards below to the respected chapter.
            </Typography>
          </Grid> */}
          <Grid item xs={6} sm={6} md={3}>
            <Label htmlFor="">Select Course To Add Content</Label>
            <br />
            <FormSelect
              formik={selectState}
              name="title"
              type="text"
              options={list}
            />
          </Grid>
          {showContent && selectState?.values?.title && (
            <Grid
              item
              xs={12}
              sm={12}
              md={9}
              sx={{
                margin: "auto",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "5px",
              }}>
              <Button
                onClick={handleOpen}
                variant="outlined"
                size="small"
                style={{ color: "white", backgroundColor: "#757DE8" }}>
                Add Chapter
              </Button>
              <Button
                onClick={handleOpenQuiz}
                variant="outlined"
                size="small"
                style={{ color: "white", backgroundColor: "#757DE8" }}>
                Add Quiz
              </Button>
              <Button
                onClick={handleOpenFlash}
                variant="outlined"
                size="small"
                style={{ color: "white", backgroundColor: "#757DE8" }}>
                Add flashCard
              </Button>
            </Grid>
          )}
        </Grid>
        <Divider />
        {/* </Paper> */}
        <Box style={{ position: "relative", minHeight: "65vh" }}>
          <RecevedData
            namedata={namedata}
            quizData={quizData}
            flashData={flashData}
            imageData={imageData}
            course={course}
            selectState={selectState}
            updateList={updateList}
            // onEdit={handleEdit}
          />
          <Grid container justifyContent="flex-end" alignItems="flex-end">
            <Grid item xs={12}>
              {add ? (
                <span onClick={handleShow}>
                  {/* <Fab
                  variant="contained"
                  style={{
                    color: "#ffff",
                    backgroundColor: "#2962ff",
                    position: "absolute",
                    right: "5%",
                    bottom: "10%",
                  }}
                  onClick={toggleContent}>
                  <CloseIcon />
                </Fab> */}
                  <Box
                    style={{
                      position: "fixed",
                      right: "77px",
                      bottom: "10px",
                    }}>
                    <Tooltip title="Add" disableInteractive>
                      <Fab
                        size="medium"
                        style={{ backgroundColor: "#2962ff", color: "#fff" }}
                        aria-label="add"
                        onClick={toggleContent}>
                        <CloseIcon />
                      </Fab>
                    </Tooltip>
                  </Box>
                </span>
              ) : (
                <span onClick={handleShow}>
                  {/* <Fab
                  variant="contained"
                  style={{
                    color: "#ffff",
                    backgroundColor: "#2962ff",
                    position: "absolute",
                    right: "5%",
                    bottom: "10%",
                  }}
                  onClick={toggleContent}>
                  <AddIcon />
                </Fab> */}
                  {/* <Box
                    style={{
                      position: "fixed",
                      right: "77px",
                      bottom: "10px",
                    }}>
                    <Tooltip title="Add" disableInteractive>
                      <Fab
                        size="medium"
                        style={{ backgroundColor: "#747DE8", color: "#fff" }}
                        aria-label="add"
                        onClick={toggleContent}>
                        <AddIcon />
                      </Fab>
                    </Tooltip>
                  </Box> */}
                </span>
              )}

              {/* {showContent && selectState?.values?.title && (
                <Box
                  container
                  direction="column"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  sx={{
                    position: "fixed",
                    right: "20px",
                    bottom: { xs: "10%", sm: "10%", lg: "10%", md: "10%" },
                  }}>
                  {items.map((item, index) => (
                    <Fade
                      in={showContent}
                      timeout={1000 + index * 200}
                      key={item?.id}>
                      <Buttons
                        // onClick={() => item.onClick(selectState.values.title)}
                        onClick={item?.onClick}
                        value={item}>
                        <Link
                          style={{
                            textDecoration: "none",
                            color: "black",
                          }}>
                          {item?.name}
                        </Link>
                      </Buttons>
                    </Fade>
                  ))}
                </Box>
              )} */}
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title">
        <Box sx={style}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontWeight: "bolder",
                    fontSize: "20px",
                  }}>
                  Add Chapters for Course:{" "}
                  {selectState?.values?.title
                    ? selectState?.values?.title
                    : resetData}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <InputLabels>Chapter Name</InputLabels>
                <Box sx={{ display: "flex", gap: 1 }}>
                  <TextField
                    fullWidth
                    size="small"
                    value={name}
                    onChange={(e) => setName(e.target.value)}></TextField>
                  <Buttons1 onClick={handleUploadButtonClick}>
                    Upload Video
                  </Buttons1>
                  <input
                    type="file"
                    accept="video/*"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    ref={fileInputRef}
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  // textAlign: "center",
                  fontWeight: 600,
                  fontSize: "20px",
                }}>
                {videoFile && <video src={videoFile} width="300" controls />}
              </Grid>
              <Grid item xs={12} md={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    gap: 2,
                  }}>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={handleClose}>
                    Cancel
                  </Button>

                  <LoadingButton
                    type="submit"
                    variant="contained"
                    sx={{
                      background: "#747DE8",
                      "&:hover": {
                        background: "#747DE8",
                      },
                    }}
                    loading={chapterLoading}>
                    Submit
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Dialog>

      <Dialog
        open={openQuiz}
        onClose={handleCloseQuiz}
        fullScreen={fullScreen}
        aria-labelledby="responsive-dialog-title">
        <form onSubmit={handleSubmitQuiz}>
          <Box
            sx={styleQuiz}
            style={{ overflowY: "scroll", maxHeight: "80vh" }}>
            <Typography sx={{ textAlign: "center", fontWeight: "bolder" }}>
              Add Quiz:{" "}
              {selectState?.values?.title
                ? selectState?.values?.title
                : resetData}
            </Typography>
            {renderForms()}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    marginTop: "15px",
                    marginBottom: "15px",
                  }}>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={handleAddQuiz}
                    color="secondary"
                    startIcon={<AddIcon />}>
                    Add Question
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    gap: 2,
                  }}>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={handleCloseQuiz}>
                    Cancel
                  </Button>

                  <LoadingButton
                    type="submit"
                    variant="contained"
                    sx={{
                      background: "#747DE8",
                      "&:hover": {
                        background: "#747DE8",
                      },
                    }}
                    loading={quizLoading}>
                    Submit
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Dialog>

      <Dialog
        open={openFlash}
        onClose={handleCloseFlash}
        fullScreen={fullScreen}
        aria-labelledby="responsive-dialog-title">
        <Box sx={styleFlash}>
          <form onSubmit={handleSubmitFlash}>
            <Grid item xs={12} md={12}>
              <Typography
                sx={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  fontSize: "20px",
                }}>
                Add FlashCard for Course:{" "}
                {selectState.values.title
                  ? selectState.values.title
                  : resetData}
              </Typography>
            </Grid>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12} md={12}>
                <FormControl required fullWidth>
                  <Select
                    value={flashcard}
                    onChange={handleChangeFlash}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}>
                    <MenuItem value="Text">Flashcard Text</MenuItem>
                    <MenuItem value={"Image"}>Flashcard Image</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} sx={{ marginTop: "2%" }}>
                {flashcard === "Text" ? (
                  <>
                    <TextField
                      value={flashText}
                      required
                      fullWidth
                      onChange={(e) => setFlashText(e.target.value)}
                    />
                  </>
                ) : (
                  <>
                    <Buttons1 onClick={handleUploadButtonClickFlash}>
                      Upload Image
                    </Buttons1>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleFileChangeFlash}
                      style={{ display: "none" }}
                      ref={fileInputRef}
                    />
                  </>
                )}
              </Grid>
              <Grid item xs={12} md={12} sx={{ marginTop: "2%" }}>
                {flashcard === "Image" && imageFile && (
                  <>
                    <img
                      src={imageFile}
                      alt="Flashcard"
                      style={{ width: "300px" }}
                    />
                  </>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12} md={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  gap: 2,
                  marginTop: "15px",
                }}>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={handleCloseFlash}>
                  Cancel
                </Button>
                <LoadingButton
                  variant="contained"
                  type="submit"
                  sx={{
                    background: "#747DE8",
                    "&:hover": {
                      background: "#747DE8",
                    },
                  }}
                  loading={flashcardLoading}>
                  Submit
                </LoadingButton>
              </Box>
            </Grid>
          </form>
        </Box>
      </Dialog>
    </>
  );
};
export default Adding;
