import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { Link } from "react-router-dom";
import React, { useState, useRef, useContext } from "react";
import ChapterData from "./ChapterData";

import { useLocation } from "react-router-dom";

import { useNavigate } from "react-router-dom";

const style = {
  bgcolor: "background.paper",
  border: "2px solid white",
  boxShadow: 24,
  p: 4,
};

const Buttons1 = styled(Button)(() => ({
  backgroundColor: "#e0e0e0",
  color: "black",
  border: "1px solid e0e0e0",
  borderRadius: 5,
  fontSize: "12px",
  height: "35px",
  width: "200px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  boxShadow: 3,
  textTransform: "uppercase",
  marginTop: "2px",
  "&:hover": {
    backgroundColor: "#e0e0e0",
  },
}));

export default function FlashCardForm() {
  let flashDataText = useContext(ChapterData);
  const [flashcard, setFlashcard] = React.useState("");
  const [imageFile, setImageFile] = React.useState(null);
  const fileInputRef = React.useRef(null);
  const [formCount, setFormCount] = useState(1);
  const [formCount1, setFormCount1] = useState(1);
  let [flashText, setFlashText] = useState("");
  let [imageData, setImageData] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const { data } = location.state || {};
  const handleAddFlashCard = () => {
    setFormCount(formCount + 1);
  };

  const handleAddFlashCard1 = () => {
    setFormCount1(formCount1 + 1);
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setImageFile(URL.createObjectURL(file));
  };

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleChange = (event) => {
    setFlashcard(event.target.value);
    if (event.target.value !== 10) {
      setImageFile(null);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (flashcard === "") {
      flashDataText.setFlashData([...flashDataText.flashData, flashText]);
      setFlashText("");
    } else if (flashcard === 10 && imageFile) {
      flashDataText.setImageData([...flashDataText.imageData, imageFile]);
    }

    navigate("/upload-courses/adding", {
      state: { resetData: data },
    });
  };

  return (
    <Container>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={style}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Select
                  value={flashcard}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="">Flashcard Text</MenuItem>
                  <MenuItem value={10}>Flashcard Image</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} md={12} sx={{ marginTop: "2%" }}>
                {flashcard === "" ? (
                  <>
                    <TextField
                      value={flashText}
                      onChange={(e) => setFlashText(e.target.value)}
                    />
                  </>
                ) : (
                  <>
                    <Buttons1 onClick={handleUploadButtonClick}>
                      Upload Image
                    </Buttons1>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                      ref={fileInputRef}
                    />
                  </>
                )}
              </Grid>
              <Grid item xs={12} md={6} sx={{ marginTop: "2%" }}>
                {flashcard === 10 && imageFile && (
                  <>
                    <img
                      src={imageFile}
                      alt="Flashcard"
                      style={{ width: "300px" }}
                    />
                  </>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12} md={12}>
              <Box
                sx={{ display: "flex", justifyContent: "flex-end", gap: "3%" }}
              >
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    background: "#747DE8",
                    "&:hover": {
                      background: "#747DE8",
                    },
                  }}
                >
                  Submit
                </Button>
              </Box>
            </Grid>
          </form>
        </Box>
      </Box>
    </Container>
  );
}
