import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import { post, put, get } from "../api/apiMethods";
import { urls } from "../api/urlConstants";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { theme } from "../theme";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const TableCellHeading = styled(TableCell)(() => ({
  fontWeight: "bold",
  textAlign: "center",
  alignItems: "center",
}));

const TableCellHeading1 = styled(TableCell)(() => ({
  textAlign: "center",
  alignItems: "center",
  borderBottom: "none",
}));

const NoDataFound = styled(Typography)(() => ({
  textAlign: "center",
  margin: "5px",
  padding: "5px",
}));

const SpanText = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: "18px",
}));

const Text = styled(Typography)(() => ({
  fontWeight: 600,
  fontSize: "18px",
}));

const Enquirers = () => {
  const [expandedRow, setExpandedRow] = useState(null);
  const [activeButton, setActiveButton] = useState(1);
  const currDate = new Date().toLocaleDateString();
  const currTime = new Date().toLocaleTimeString();
  const [list, setList] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectedCourseId, setSelectedCourseId] = useState(null);
  const [selectedCourseDetails, setSelectedCourseDetails] = useState({});
  const [mainList, setMainList] = useState([]);
  const navigate = useNavigate();
  // const [filteredList, setFilteredList] = useState([]);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const filter = (type) => {
    if (type == "seen") {
      let newList = mainList.filter((filterData) => filterData.seen === true);
      setList(newList);
    } else if (type == "all") {
      setList(mainList);
    } else {
      let newList = mainList.filter((d) => d.seen == false);
      setList(newList);
    }
  };

  const handleButtonClick = (buttonIndex) => {
    setActiveButton(buttonIndex);
    // setList(filterData)
  };

  const handleExpandClick = (rowId) => {
    if (expandedRow === rowId) {
      setExpandedRow(null);
    } else {
      setExpandedRow(rowId);
    }
  };

  const getEnquiry = async () => {
    try {
      const { data, status } = await get(`${urls.enquiry.getEnquiry}`);
      setList(data.result);
      setMainList(data.result);
      // console.log(data, "o???????????????????????????");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getEnquiry();
  }, []);

  // const [filteredSchedules, setFilteredSchedules] = useState([]);

  // const schedules =
  //   selectedCourseDetails?.onlineInstructorLedInfo?.schedules || [];

  // useEffect(() => {
  //   const targetStartDate =
  //     row?.enquiryDetails?.programmeDetails.intructorLedSchedule?.startDate;

  //   if (targetStartDate) {
  //     const filteredData = schedules.filter(
  //       (data) => data.startDate === targetStartDate
  //     );
  //     setFilteredSchedules(filteredData);
  //   }
  // }, [
  //   schedules,
  //   row?.enquiryDetails?.programmeDetails.intructorLedSchedule?.startDate,
  // ]);

  const getCourse = async (CourseId) => {
    try {
      const res = await get(`${urls.course.getOnevalue}/${CourseId}`);
      setSelectedCourseDetails(res.data.result.data);
      handleOpen();
    } catch (error) {
      console.log(error);
    }
  };

  const handleMarkSeen = async (id) => {
    try {
      const { data } = await put(`${urls.enquiry.markSeen}/${id}`);
      getEnquiry();
    } catch (error) {
      console.log(error, "===error");
    }
  };

  return (
    <>
      <Box sx={{ padding: "0 30px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "left",

            color: `${theme.palette.primary.main}`,
            justifyContent: "space-between",
            p: "15px 0px",
            "& > *": {
              m: 1,
            },
          }}
        >
          <Typography variant="h6" fontWeight={600}>
            Total Enquiries ({list.length})
          </Typography>

          <ButtonGroup variant="outlined" aria-label="outlined button group">
            <Button
              variant="outlined"
              onClick={() => {
                handleButtonClick(1);
                filter("all");
              }}
              style={{
                backgroundColor:
                  activeButton === 1 ? theme.palette.primary.main : "",
                color: activeButton === 1 ? "white" : "black",
                textTransform: "capitalize",
              }}
            >
              All
            </Button>
            <Button
              onClick={() => {
                handleButtonClick(2);
                filter("seen");
              }}
              style={{
                backgroundColor:
                  activeButton === 2 ? theme.palette.primary.main : "",
                color: activeButton === 2 ? "white" : "black",
                textTransform: "capitalize",
              }}
            >
              Read
            </Button>
            <Button
              onClick={() => {
                handleButtonClick(3);
                filter("false");
              }}
              style={{
                backgroundColor:
                  activeButton === 3 ? theme.palette.primary.main : "",
                color: activeButton === 3 ? "white" : "black",
                textTransform: "capitalize",
              }}
            >
              Unread
            </Button>
          </ButtonGroup>
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCellHeading>Enquiry Id</TableCellHeading>
                <TableCellHeading>Name</TableCellHeading>
                <TableCellHeading>Mail</TableCellHeading>
                <TableCellHeading>Contact Number</TableCellHeading>
                <TableCellHeading></TableCellHeading>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.length === 0 ? (
                <TableRow>
                  <TableCell align="center" colSpan={12}>
                    <NoDataFound variant="h6">No data found</NoDataFound>
                  </TableCell>
                </TableRow>
              ) : (
                list.map((row, i) => (
                  <React.Fragment key={row._id}>
                    <TableRow>
                      <TableCellHeading1
                        style={{
                          alignItems: "center",
                          textAlign: "center",
                        }}
                      >
                        {row._id}
                      </TableCellHeading1>
                      <TableCellHeading1
                        style={{
                          alignItems: "center",
                          textAlign: "center",
                        }}
                      >
                        {row.customerDetails.name}
                      </TableCellHeading1>
                      <TableCellHeading1
                        style={{
                          alignItems: "center",
                          textAlign: "center",
                        }}
                      >
                        {row.customerDetails.email}
                      </TableCellHeading1>
                      <TableCellHeading1
                        style={{
                          alignItems: "center",
                          textAlign: "center",
                        }}
                      >
                        {row.customerDetails.contactNumber}
                      </TableCellHeading1>
                      <TableCellHeading1>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <ExpandMore
                            expand={expandedRow === i}
                            // expand={expandedRow === row.id}
                            onClick={() => handleExpandClick(i)}
                            // onClick={() => handleExpandClick(row.id)}
                            aria-expanded={expandedRow === i}
                            // aria-expanded={expandedRow === row.id}
                            aria-label="show more"
                          >
                            <ExpandMoreIcon />
                          </ExpandMore>
                        </Box>
                      </TableCellHeading1>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan="12">
                        <Collapse
                          // in={expandedRow === row.id}
                          in={expandedRow === i}
                          timeout="auto"
                          unmountOnExit
                        >
                          <CardContent>
                            <Box
                              style={{
                                backgroundColor: "#ECECEC",
                                padding: "12px",
                                marginBottom: "12px",
                                borderRadius: "10px",
                              }}
                            >
                              <Typography style={{ color: "gray" }}>
                                {/* {dayjs(row.createdAt).format("DD-MM-YYYY")} */}
                                {new Date(row.createdAt).toLocaleString()}
                              </Typography>
                              {row.subject ? (
                                <Typography>
                                  <span style={{ color: "gray" }}>
                                    Subject:
                                  </span>
                                  {row.subject}
                                </Typography>
                              ) : (
                                ""
                              )}
                              {row.message ? (
                                <Typography>
                                  {" "}
                                  <span style={{ color: "gray" }}>
                                    Message:
                                  </span>
                                  {row.message}
                                </Typography>
                              ) : (
                                ""
                              )}
                              {/* {row.enquiryDetails.courseId ? (
                                <Button
                                  onClick={() =>
                                    handleNavigateCourse(
                                      row.enquiryDetails.courseId
                                    )
                                  }
                                  size="small"
                                  variant="outlined">
                                  <span style={{ color: "gray" }}>
                                    CouresId:
                                  </span>
                                  {row.enquiryDetails.courseId
                                    ? row.enquiryDetails.courseId
                                    : ""}
                                </Button>
                              ) : (
                                ""
                              )} */}

                              {row.enquiryDetails.programmeName ? (
                                <Typography>
                                  <span style={{ color: "gray" }}>
                                    Programme Name:
                                  </span>
                                  {row.enquiryDetails.programmeName
                                    ? row.enquiryDetails.programmeName
                                    : ""}
                                </Typography>
                              ) : (
                                ""
                              )}
                              {row.enquiryDetails.programmeDetails
                                ?.intructorLedSchedule ? (
                                <Typography>
                                  <span style={{ color: "gray" }}>
                                    Start Date:
                                  </span>
                                  {row.enquiryDetails.programmeDetails
                                    ? dayjs(
                                        row?.enquiryDetails?.programmeDetails
                                          .intructorLedSchedule?.startDate
                                      ).format("ddd, DD-MM-YYYY")
                                    : ""}
                                </Typography>
                              ) : (
                                ""
                              )}

                              {row.enquiryDetails.programmeDetails
                                ?.classRoomSchedule?.startDate ? (
                                <Typography>
                                  <span style={{ color: "gray" }}>
                                    Start Date:
                                  </span>
                                  {row.enquiryDetails.programmeDetails
                                    ? dayjs(
                                        row?.enquiryDetails?.programmeDetails
                                          .classRoomSchedule?.startDate
                                      ).format("ddd, DD-MM-YYYY")
                                    : ""}
                                </Typography>
                              ) : (
                                ""
                              )}

                              {row.enquiryDetails.programmeDetails
                                ?.classRoomSchedule?.endDate ? (
                                <Typography>
                                  <span style={{ color: "gray" }}>
                                    End Date:
                                  </span>
                                  {row.enquiryDetails.programmeDetails
                                    ? dayjs(
                                        row?.enquiryDetails?.programmeDetails
                                          .classRoomSchedule?.endDate
                                      ).format("ddd, DD-MM-YYYY")
                                    : ""}
                                </Typography>
                              ) : (
                                ""
                              )}
                              {row.enquiryDetails.programmeDetails
                                ?.intructorLedSchedule ? (
                                <Typography>
                                  <span style={{ color: "gray" }}>
                                    End Date:
                                  </span>
                                  {row.enquiryDetails.programmeDetails
                                    ? dayjs(
                                        row?.enquiryDetails?.programmeDetails
                                          .intructorLedSchedule?.endDate
                                      ).format("ddd, DD-MM-YYYY")
                                    : ""}
                                </Typography>
                              ) : (
                                ""
                              )}
                              {row.enquiryDetails.programmeDetails
                                ?.intructorLedSchedule ? (
                                <Typography>
                                  <span style={{ color: "gray" }}>
                                    Week Type:
                                  </span>{" "}
                                  {row.enquiryDetails.programmeDetails
                                    ? row.enquiryDetails.programmeDetails
                                        .intructorLedSchedule?.weekType
                                    : ""}
                                </Typography>
                              ) : (
                                ""
                              )}

                              {row.enquiryDetails?.programmeDetails
                                ?.classRoomSchedule?.weekType ? (
                                <Typography>
                                  <span style={{ color: "gray" }}>
                                    WeekType:
                                  </span>{" "}
                                  {row.enquiryDetails?.programmeDetails
                                    ?.classRoomSchedule
                                    ? row.enquiryDetails?.programmeDetails
                                        .classRoomSchedule?.weekType
                                    : ""}
                                </Typography>
                              ) : (
                                ""
                              )}

                              {row.enquiryDetails?.programmeDetails?.inhouse ? (
                                <Typography>
                                  <span style={{ color: "gray" }}>
                                    Participant:
                                  </span>{" "}
                                  {row.enquiryDetails?.programmeDetails?.inhouse
                                    ? row.enquiryDetails?.programmeDetails
                                        .inhouse?.participant
                                    : ""}
                                </Typography>
                              ) : (
                                ""
                              )}

                              {row.enquiryDetails?.programmeDetails?.inhouse ? (
                                <Typography>
                                  <span style={{ color: "gray" }}>
                                    Company website:
                                  </span>{" "}
                                  {row.enquiryDetails?.programmeDetails?.inhouse
                                    ? row.enquiryDetails?.programmeDetails
                                        .inhouse?.webname
                                    : ""}
                                </Typography>
                              ) : (
                                ""
                              )}

                              {row.enquiryDetails.others ? (
                                <Typography>
                                  <span style={{ color: "gray" }}>
                                    Expertise:
                                  </span>
                                  {row.enquiryDetails.others
                                    ? row.enquiryDetails.others?.expertise
                                    : ""}
                                </Typography>
                              ) : (
                                ""
                              )}
                              {row.enquiryDetails.programmeName &&
                              !row.enquiryDetails?.programmeDetails?.inhouse &&
                              row.enquiryDetails?.courseId ? (
                                <Button
                                  size="small"
                                  variant="outlined"
                                  onClick={() =>
                                    getCourse(row.enquiryDetails?.courseId)
                                  }
                                  sx={{ marginTop: 1 }}
                                >
                                  View Course
                                </Button>
                              ) : (
                                ""
                              )}
                              {/* ============================== COURSE DETAILS =============================== */}
                              <Dialog
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                              >
                                <DialogTitle
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontWeight: "bold",
                                    width: 500,
                                  }}
                                >
                                  Course Details
                                </DialogTitle>
                                <Divider />

                                <DialogContent>
                                  {/* <Typography>
                                        CourseId:{selectedCourseDetails?._id}
                                      </Typography> */}
                                  <Text variant="h6">
                                    Programme Name:{" "}
                                    <SpanText variant="span">
                                      {row.enquiryDetails.programmeName}
                                    </SpanText>
                                  </Text>
                                  <Text variant="h6">
                                    Course Name:{" "}
                                    <SpanText variant="span">
                                      {selectedCourseDetails?.title}
                                    </SpanText>
                                  </Text>

                                  {row.enquiryDetails.programmeName ? (
                                    <Typography>
                                      {row.enquiryDetails.programmeName ===
                                        "Online Instructor-Led" && (
                                        <>
                                          <Text>
                                            Price:{" "}
                                            <SpanText variant="span">
                                              {
                                                selectedCourseDetails
                                                  ?.onlineInstructorLedInfo
                                                  ?.standardPrice
                                              }
                                            </SpanText>
                                          </Text>

                                          {/* <Text>Schedules:</Text> */}
                                          {selectedCourseDetails?.onlineInstructorLedInfo?.schedules
                                            ?.filter(
                                              (data) =>
                                                data.startDate ===
                                                  row?.enquiryDetails
                                                    ?.programmeDetails
                                                    ?.intructorLedSchedule
                                                    ?.startDate &&
                                                data?.endDate ===
                                                  row?.enquiryDetails
                                                    ?.programmeDetails
                                                    ?.intructorLedSchedule
                                                    ?.endDate
                                            )
                                            .map((data, i) => (
                                              <>
                                                <Text>
                                                  Week Type:{" "}
                                                  <SpanText variant="span">
                                                    {data.weekType}
                                                  </SpanText>
                                                </Text>
                                                <Text>
                                                  Date:{" "}
                                                  <SpanText variant="span">
                                                    {dayjs(
                                                      data.startDate
                                                    ).format(
                                                      "ddd, DD MMM YYYY"
                                                    )}{" "}
                                                    <Text variant="span">
                                                      to{" "}
                                                    </Text>
                                                    {dayjs(data.endDate).format(
                                                      "ddd, DD MMM YYYY"
                                                    )}
                                                  </SpanText>
                                                </Text>
                                                <Text>
                                                  Time:{" "}
                                                  <SpanText variant="span">
                                                    {data.startTime}{" "}
                                                    <Text variant="span">
                                                      to{" "}
                                                    </Text>
                                                    {data.endTime}
                                                  </SpanText>
                                                </Text>
                                                <Text>
                                                  Hours:{" "}
                                                  <SpanText variant="span">
                                                    {data.hoursPerDay} hrs/day
                                                  </SpanText>
                                                </Text>
                                                <Text>
                                                  Total days:{" "}
                                                  <SpanText variant="span">
                                                    {data.totalDays} Days
                                                  </SpanText>
                                                </Text>
                                              </>
                                            ))}
                                          {/* {selectedCourseDetails?.onlineInstructorLedInfo?.schedules?.filter(
                                                (data) =>
                                                  (
                                                    data.startDate ===
                                                    row?.enquiryDetails
                                                      ?.programmeDetails
                                                      .intructorLedSchedule
                                                      ?.startDate
                                                  )(
                                                    console.log(data, "---ff"),
                                                    <>
                                                      <Text>
                                                        Week Type:{" "}
                                                        <SpanText variant="span">
                                                          {data.weekType}
                                                        </SpanText>
                                                      </Text>
                                                      <Text>
                                                        Date:{" "}
                                                        <SpanText variant="span">
                                                          {dayjs(
                                                            data.startDate
                                                          ).format(
                                                            "ddd, DD MMM YYYY"
                                                          )}{" "}
                                                          <Text variant="span">
                                                            to{" "}
                                                          </Text>
                                                          {dayjs(
                                                            data.endDate
                                                          ).format(
                                                            "ddd, DD MMM YYYY"
                                                          )}
                                                        </SpanText>
                                                      </Text>
                                                      <Text>
                                                        Time:{" "}
                                                        <SpanText variant="span">
                                                          {data.startTime}{" "}
                                                          <Text variant="span">
                                                            to{" "}
                                                          </Text>
                                                          {data.endTime}
                                                        </SpanText>
                                                      </Text>
                                                      <Text>
                                                        Hours:{" "}
                                                        <SpanText variant="span">
                                                          {data.hoursPerDay}{" "}
                                                          hrs/day
                                                        </SpanText>
                                                      </Text>
                                                      <Text>
                                                        Total days:{" "}
                                                        <SpanText variant="span">
                                                          {data.totalDays} Days
                                                        </SpanText>
                                                      </Text>
                                                    </>
                                                  )
                                              )} */}

                                          {/* {selectedCourseDetails?.onlineInstructorLedInfo?.schedules?.map(
                                                (data, i) => (
                                                  console.log(data, "---ff"),
                                                  (
                                                    <>
                                                      <Text>
                                                        Week Type:{" "}
                                                        <SpanText variant="span">
                                                          {data.weekType}
                                                        </SpanText>
                                                      </Text>
                                                      <Text>
                                                        Date:{" "}
                                                        <SpanText variant="span">
                                                          {dayjs(
                                                            data.startDate
                                                          ).format(
                                                            "ddd, DD MMM YYYY"
                                                          )}{" "}
                                                          <Text variant="span">
                                                            to{" "}
                                                          </Text>
                                                          {dayjs(
                                                            data.endDate
                                                          ).format(
                                                            "ddd, DD MMM YYYY"
                                                          )}
                                                        </SpanText>
                                                      </Text>
                                                      <Text>
                                                        Time:{" "}
                                                        <SpanText variant="span">
                                                          {data.startTime}{" "}
                                                          <Text variant="span">
                                                            to{" "}
                                                          </Text>
                                                          {data.endTime}
                                                        </SpanText>
                                                      </Text>
                                                      <Text>
                                                        Hours:{" "}
                                                        <SpanText variant="span">
                                                          {data.hoursPerDay}{" "}
                                                          hrs/day
                                                        </SpanText>
                                                      </Text>
                                                      <Text>
                                                        Total days:{" "}
                                                        <SpanText variant="span">
                                                          {data.totalDays} Days
                                                        </SpanText>
                                                      </Text>
                                                    </>
                                                  )
                                                )
                                              )} */}
                                        </>
                                      )}
                                    </Typography>
                                  ) : (
                                    ""
                                  )}
                                  {row.enquiryDetails.programmeName ? (
                                    <Typography>
                                      {row.enquiryDetails.programmeName ===
                                        "Classroom" && (
                                        <>
                                          <Text>
                                            Price:{" "}
                                            <SpanText variant="span">
                                              {
                                                selectedCourseDetails
                                                  ?.classRoomInfo?.standardPrice
                                              }
                                            </SpanText>
                                          </Text>

                                          {selectedCourseDetails?.classRoomInfo?.schedules
                                            ?.filter(
                                              (data) =>
                                                data.startDate ===
                                                  row?.enquiryDetails
                                                    ?.programmeDetails
                                                    ?.classRoomSchedule
                                                    ?.startDate &&
                                                data.endDate ===
                                                  row?.enquiryDetails
                                                    ?.programmeDetails
                                                    ?.classRoomSchedule?.endDate
                                            )
                                            .map((data, i) => (
                                              <>
                                                <Text>
                                                  Week Type:{" "}
                                                  <SpanText variant="span">
                                                    {data.weekType}
                                                  </SpanText>
                                                </Text>
                                                <Text>
                                                  Date:{" "}
                                                  <SpanText variant="span">
                                                    {dayjs(
                                                      data.startDate
                                                    ).format(
                                                      "ddd, DD MMM YYYY"
                                                    )}{" "}
                                                    <Text variant="span">
                                                      to{" "}
                                                    </Text>
                                                    {dayjs(data.endDate).format(
                                                      "ddd, DD MMM YYYY"
                                                    )}
                                                  </SpanText>
                                                </Text>
                                                <Text>
                                                  Time:{" "}
                                                  <SpanText variant="span">
                                                    {data.startTime}{" "}
                                                    <Text variant="span">
                                                      to{" "}
                                                    </Text>
                                                    {data.endTime}
                                                  </SpanText>
                                                </Text>
                                                <Text>
                                                  Hours:{" "}
                                                  <SpanText variant="span">
                                                    {data.hoursPerDay} hrs/day
                                                  </SpanText>
                                                </Text>
                                                <Text>
                                                  Total days:{" "}
                                                  <SpanText variant="span">
                                                    {data.totalDays} Days
                                                  </SpanText>
                                                </Text>
                                              </>
                                            ))}

                                          {/* {selectedCourseDetails?.classRoomInfo?.schedules?.map(
                                                (data, i) => (
                                                  <>
                                                    <Text>
                                                      Week Type:{" "}
                                                      <SpanText variant="span">
                                                        {data.weekType}
                                                      </SpanText>
                                                    </Text>
                                                    <Text>
                                                      Date:{" "}
                                                      <SpanText variant="span">
                                                        {dayjs(
                                                          data.startDate
                                                        ).format(
                                                          "ddd, DD MMM YYYY"
                                                        )}{" "}
                                                        <Text variant="span">
                                                          to{" "}
                                                        </Text>
                                                        {dayjs(
                                                          data.endDate
                                                        ).format(
                                                          "ddd, DD MMM YYYY"
                                                        )}
                                                      </SpanText>
                                                    </Text>
                                                    <Text>
                                                      Time:{" "}
                                                      <SpanText variant="span">
                                                        {data.startTime}{" "}
                                                        <Text variant="span">
                                                          to{" "}
                                                        </Text>
                                                        {data.endTime}
                                                      </SpanText>
                                                    </Text>
                                                    <Text>
                                                      Hours:{" "}
                                                      <SpanText variant="span">
                                                        {data.hoursPerDay}{" "}
                                                        hrs/day
                                                      </SpanText>
                                                    </Text>
                                                    <Text>
                                                      Total days:{" "}
                                                      <SpanText variant="span">
                                                        {data.totalDays} Days
                                                      </SpanText>
                                                    </Text>
                                                  </>
                                                )
                                              )} */}
                                        </>
                                      )}
                                    </Typography>
                                  ) : (
                                    ""
                                  )}
                                </DialogContent>
                              </Dialog>
                              {/* ========================================================= */}
                            </Box>
                            {!row.seen ? (
                              <Button
                                onClick={() => handleMarkSeen(row._id)}
                                sx={{
                                  display: "flex",
                                  alignContent: "flex-end",
                                  color: theme.palette.primary.main,
                                }}
                              >
                                Mark Read
                              </Button>
                            ) : null}
                          </CardContent>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default Enquirers;
