import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import {
  Box,
  CardActionArea,
  Grid,
  Button,
  styled,
  MenuItem,
  Select,
  OutlinedInput,
  FormControl,
} from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import { useState, useEffect } from "react";
import { theme } from "../../../theme";

// custom imports
import AddCourse from "../../AddCourse";

// Icons
import FilterListIcon from "@mui/icons-material/FilterList";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";

// apis
import { get, del } from "../../../api/apiMethods";
import { urls } from "../../../api/urlConstants";

// assets
const image = require("../../../assets/Images/courseimg.png");

const Design = styled(Typography)(() => ({
  color: "purple",
  fontSize: "12px",
}));

const Title = styled(Typography)(() => ({
  textTransform: "uppercase",
  fontSize: "1rem",
  fontWeight: "bold",
  color: `${theme.palette.primary.main}`,
}));

const Label = styled("label")(() => ({
  fontWeight: "bold",
  paddingLeft: "10px",
  fontSize: "14px",
}));

const Content = styled("div")(() => ({
  fontSize: "15px",
  // color: blueGrey[800],
  color: "black",
  overflow: "hidden",
  // textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  height: 30,
  padding: "5px",
  marginTop: "12px",
}));

const Time = styled(Box)(() => ({
  position: "absolute",
  top: "20px",
  right: "5%",
  backgroundColor: "white",
  border: "1px solid white",
  borderRadius: "2px",
  display: "flex",
  fontSize: "13px",
  gap: "5px",
  alignItems: "center",
  textAlign: "center",
}));

export const Course = ({ elem }) => {
  const [editCourse, setEditCourse] = useState(null);
  const [deleteModal, setDeleteModal] = React.useState({
    open: false,
    itemToDelete: "",
  });
  const [history, setHistory] = useState("");
  const [editFormData, setEditFormData] = useState({
    title: "",
    menu: "",
    pdu: "",
    courseHours: "",
    highlights: "",
    description: "",
    thumbnailImage: "",
    overview: "",
    keyFeatures: "",
    courseOutline: "",
    benefits: "",
    whoIsItFor: "",
    selfPacedInfo: {
      earlyBirdEndDate: "",
      earlyBirdPrice: "",
      standardPrice: "",
    },
    programmeDates: [],
    isPaid: false,
    isTrending: false,
  });
  const [courseDetail, setCourseDetail] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [menuList, setMenuList] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState("All");

  const getCourse = async () => {
    try {
      const data = await get(urls.course.getList);
      setCourseDetail(data.data.result.data);
      setFilteredCourses(data.data.result.data);

      const getMeuList = data.data.result.data.map((c) => c.menu.name);
      const uniqueMenuList = [...new Set(getMeuList)];
      setMenuList(["All", ...uniqueMenuList]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCourse();
  }, [editCourse]);

  const handleClose = () => {
    setDeleteModal({ open: false, itemToDelete: "" });
  };
  const handleDelete = async (id) => {
    try {
      const response = await del(
        `${urls.course.deleteCourse}/${deleteModal.itemToDelete}`
      );

      setDeleteModal({ open: false, itemToDelete: "" });
      getCourse();
    } catch (error) {
      console.log(error);
    }
  };

  const handleNavigateCourse = (id, data) => {
    setHistory(window.location.pathname);
    setEditCourse(id);
    setEditFormData({
      title: data.title,
      menu: data.menu,
      pdu: data.pdu,
      isPaid: data.isPaid,
      isTrending: data.isTrending,
      courseHours: data.courseHours,
      highlights: data.highlights,
      description: data.description,
      programmeDates: data.programmeDates,
      thumbnailImage: data.thumbnailImage,
      overview: data.courseDetails.overview,
      keyFeatures: data.courseDetails.keyFeatures,
      courseOutline: data.courseDetails.courseOutline,
      benefits: data.courseDetails.benefits,
      whoIsItFor: data.courseDetails.whoIsItFor,
      courseId: editCourse,
      selfPacedInfo: {
        earlyBirdEndDate: data?.selfPacedInfo?.earlyBirdEndDate,
        earlyBirdPrice: data?.selfPacedInfo?.earlyBirdPrice,
        standardPrice: data?.selfPacedInfo?.standardPrice,
      },
    });
  };

  const handleChange = (event) => {
    setSelectedMenu(event.target.value);
  };

  useEffect(() => {
    if (selectedMenu) {
      if (selectedMenu === "All") {
        setFilteredCourses(courseDetail);
      } else {
        setFilteredCourses(
          courseDetail.filter((c) => c.menu.name === selectedMenu)
        );
      }
    }
  }, [selectedMenu]);

  return (
    <>
      {editCourse !== null ? (
        <AddCourse
          edit={editCourse}
          formData={editFormData}
          setEditCourse={setEditCourse}
          history={history}
          // Pass courseDetail instead of courseList
        />
      ) : (
        <Box p={2} ml={2}>
          <FormControl sx={{ width: 250 }}>
            <Label id="demo-simple-select-label">
              <FilterListIcon fontSize="small" /> Filter Category
            </Label>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              displayEmpty
              input={
                <OutlinedInput
                  sx={{
                    borderRadius: "20px",
                    textAlign: "center",
                    height: "35px",
                  }}
                />
              }
              value={selectedMenu}
              onChange={handleChange}
              size="small"
            >
              {menuList?.map((menuList) => (
                <MenuItem key={menuList} value={menuList}>
                  {menuList}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Grid container spacing={2} my={1.5}>
            {filteredCourses
              .sort((a, b) => a.title.localeCompare(b.title))
              .map((elem, index) => (
                <Grid item xs={12} md={4} sm={6} lg={3} key={index} my={1}>
                  <Card>
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        image={
                          elem.thumbnailImage &&
                          !elem.thumbnailImage.includes("blob:")
                            ? elem.thumbnailImage
                            : image
                        }
                        sx={{
                          height: 150,
                          width: "100%",
                          objectFit: "cover",
                        }}
                        alt="course thumbnail"
                      />
                      <CardContent
                        sx={{
                          padding: "5px",
                          height: 30,
                        }}
                      >
                        <Time>
                          <AccessTimeRoundedIcon sx={{ fontSize: "medium" }} />
                          <Typography>{elem.courseHours} hrs</Typography>
                        </Time>
                        <Design>{elem.design}</Design>
                        <Title
                          variant="h6"
                          sx={{
                            display: "-webkit-box",
                            margin: "5px 0",
                            WebkitLineClamp: 1,
                            whiteSpace: "normal",
                            overflow: "hidden",
                            WebkitBoxOrient: "vertical",
                          }}
                        >
                          {elem.title}
                        </Title>
                      </CardContent>
                      <Content variant="body2">{elem.description}</Content>
                    </CardActionArea>
                    <Box
                      gap={2}
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        margin: "10px",
                      }}
                    >
                      <Button
                        size="small"
                        variant="contained"
                        color="error"
                        onClick={() =>
                          setDeleteModal({ itemToDelete: elem._id, open: true })
                        }
                      >
                        Delete
                      </Button>

                      <Button
                        variant="contained"
                        type="submit"
                        size="small"
                        onClick={() => handleNavigateCourse(elem._id, elem)}
                      >
                        Edit
                      </Button>
                    </Box>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </Box>
      )}

      <Dialog
        open={deleteModal.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description" fontWeight={600}>
            Are you sure you want to delete this?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={(e) => handleDelete(e)} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Course;
