import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  Paper,
  Typography,
  styled,
  Stack,
} from "@mui/material";

// mui icons=========
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

import { useFormik } from "formik";
import * as Yup from "yup";

// images=================
import bubble from "../assets/bg.png";
import towPiece from "../assets/Images/towpiece.png";
import laptop from "../assets/admin login.png";
import logo from "../assets/Images/Aphia new Logo.png";

// components===============
import authentication from "../api/authendication";
import { RippelVideoButton } from "./admin/uploadcourse/RippleVideoButton";
import { theme } from "../theme";
import UserContext from "../context/UserContext";
import NavbarContext from "../context/NavbarContext";
import FormInputLogin from "./FormInputLogin";

const ariaLabel = { "aria-label": "description" };

const Main = styled(Box)(() => ({
  height: "100vh",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  backgroundImage: `url(${bubble})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
}));

const Form = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  width: "90%",
  backgroundColor: "transparent",
  border: "2px solid #ffff",
  borderRadius: "20px",
  boxShadow: "inset 0 0 2000px rgba(255, 255, 255, .5)",
  boxSizing: "border-box",
  marginTop: "50px",
  marginBottom: "50px",
  filter: "blur(0.1px)",
}));

const LeftPart = styled(Box)(() => ({
  display: "flex",
  height: "100%",
  // backgroundImage: `url(${towPiece})`,
  backgroundRepeat: "no-repeat",
  backgroundPositionY: "100%",
  padding: "10px",
}));

const RightPart = styled(Box)(() => ({
  position: "relative",
  backgroundImage: `url(${laptop})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
  justifyContent: "end",
  backgroundPositionY: "center",
}));

const InputForm = styled(Box)(() => ({
  margin: "20px auto",
}));

const HeaderText = styled(Typography)(() => ({
  marginTop: "10px",
  fontSize: "30px",
  fontWeight: 700,
  color: theme.palette.primary.main,
}));

const Text = styled(Typography)(() => ({
  marginTop: "2px",
  marginBottom: "2px",
  fontSize: "15px",
  fontWeight: 500,
}));
const ForgotText = styled(Typography)(() => ({
  paddingLeft: "10px",
  cursor: "pointer",
  textAlign: "center",
}));

const Login = () => {
  const { user, setUser } = useContext(UserContext);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const { setShowNavbar } = useContext(NavbarContext);

  useEffect(() => {
    setShowNavbar(false);

    return () => setShowNavbar(true);
  }, []);

  const handleSubmit = async (value) => {
    setLoading(true);

    try {
      const data = {
        email: value.email,
        password: value.password,
        userType: "admin",
      };
      const loginRes = await authentication.login(data);
      window.localStorage.setItem(
        "user",
        JSON.stringify(loginRes.data.result.user)
      );
      setUser(loginRes.data.result.user);
      window.localStorage.setItem(
        "access_token",
        loginRes.data.result.access_token
      );
      window.location.href = "/menu";
      setLoading(false);
    } catch (error) {
      console.log("setResError", error);
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      userType: "admin",
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required().label("Email").max(150),
      password: Yup.string().required().label("Password").max(32),
    }),
    onSubmit: async (values) => handleSubmit(values),
    validateOnBlur: false,
  });

  return (
    <>
      <Main>
        <Form>
          <LeftPart
            sx={{
              width: { xs: "100%", sm: "100%", md: "65%", lg: "65%" },
            }}
          >
            <InputForm
              component="form"
              onSubmit={formik.handleSubmit}
              sx={{
                width: { xs: "100%", sm: "100%", md: "60%" },
              }}
            >
              <Stack mb={1}>
                <img
                  src={logo}
                  height={150}
                  width={300}
                  style={{ objectFit: "contain" }}
                />
              </Stack>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} mb={1}>
                  <HeaderText variant="h6">Welcome Back!</HeaderText>
                  <Text variant="h6">Please login with your account.</Text>
                </Grid>

                <Box
                  p={2}
                  px={2}
                  sx={{
                    background: "white",
                    width: "100%",
                  }}
                >
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormInputLogin
                      formik={formik}
                      name="email"
                      type="email"
                      label="Enter your Email"
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} my={1}>
                    <FormInputLogin
                      formik={formik}
                      name="password"
                      type={showPassword ? "text" : "password"}
                      label="Enter Password"
                      size="small"
                      inputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end" // Add this line
                              aria-label="toggle password visibility"
                            >
                              {showPassword ? (
                                <VisibilityOff fontSize="small" />
                              ) : (
                                <Visibility fontSize="small" />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Box>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox size="small" />}
                      type="checkbox"
                      onChange={(e) => {
                        setRememberMe(e.target.checked);
                      }}
                      sx={{ fontSize: "12px" }}
                      label="Remember Me"
                    />
                  </FormGroup>
                  <Link
                    to="/forgot-password"
                    style={{ textDecoration: "none" }}
                  >
                    <ForgotText
                      variant="body"
                      sx={{
                        color: `${theme.palette.primary.dark}`,
                        "&:hover": {
                          opacity: 0.8,
                        },
                      }}
                    >
                      Forgot password
                    </ForgotText>
                  </Link>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} my={1}>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={loading}
                    fullWidth
                    sx={{
                      borderRadius: "5px",
                    }}
                  >
                    Sign In
                  </LoadingButton>
                </Grid>
              </Grid>
            </InputForm>
          </LeftPart>

          <RightPart
            sx={{
              width: { xs: "0", sm: "50%", md: "65%", lg: "65%" },
              marginTop: { xs: "20%", sm: "20%", md: "15%", lg: "10%" },
              display: { xs: "none", sm: "none", md: "block", lg: "block" },
              backgroundPositionX: { md: "center", lg: "35px" },
            }}
          >
            <Box
              sx={{
                position: "absolute",
                margin: "auto",
              }}
            >
              <img src={laptop} width="100%" height="100%" alt="Lodding..." />
            </Box>
          </RightPart>
        </Form>
      </Main>
    </>
  );
};

export default Login;
