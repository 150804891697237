import {
  Box,
  Button,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import styled from "@emotion/styled";
import Switch from "@mui/material/Switch";
import { useTheme } from "@mui/material/styles";
import { Search } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

// forms and validation
import { useFormik } from "formik";
import * as Yup from "yup";

// icons
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";

// custom imports
import FormInput from "./FormInput";

// apis
import { post, put, get } from "../api/apiMethods";
import { urls } from "../api/urlConstants";

const label = {
  inputProps: { "aria-label": "Switch demo" },
  fontSize: "small",
};

const Heading = styled(TableCell)(() => ({
  fontWeight: "bold",
  textAlign: "center",
}));

const Data = styled(TableCell)(() => ({
  textAlign: "center",
}));

const style = {
  // width: "100%",
  // height: "auto",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 2,
};

const Category = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchFilter, setSearchFilter] = useState([]);
  const [edit, setEdit] = useState();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setEdit(null);
  };

  useEffect(() => {
    if (!open) {
      formik.resetForm();
    }
  }, [open]);

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required().label("Category Name"),
    }),

    onSubmit: async (values) => {
      setLoading(true);
      try {
        const body = {
          name: values.name,
        };
        if (!edit) {
          const { data } = await post(`${urls.menu.createMenu}`, body);

          setLoading(false);
          getMenu();
          handleClose();
        } else {
          const response = await put(`${urls.menu.editMenu}/${edit}`, body);
          const updatedData = formData.map((menu) =>
            menu._id === edit ? { ...menu, name: values.name } : menu
          );

          setLoading(false);
          setFormData(updatedData);
          getMenu();
          handleClose();
        }
      } catch (error) {
        console.error(error);
      }
    },

    validateOnBlur: false,
  });

  useEffect(() => {
    getMenu();
  }, []);

  const getMenu = async () => {
    try {
      const { data, status } = await get(`${urls.menu.getList}`);
      setFormData(data.result.data);
      if (search.trim()) {
        setSearchFilter(
          data.result.data.filter((ele) =>
            ele.name.toLowerCase().includes(search.toLowerCase().trim())
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleMenuEdit = (id, data) => {
    formik.setValues({
      name: data.name,
    });
    setEdit(id);
    handleOpen();
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value.trim());
    if (value.trim() !== "") {
      formData.length > 0 &&
        setSearchFilter(
          formData.filter((ele) =>
            ele.name.toLowerCase().includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter([]);
    }
  };

  // active/inactive
  const handleUpdateStatus = async (id) => {
    try {
      await put(`${urls.menu.updateStatus}/${id}`);
      getMenu();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Box sx={{ padding: "20px 30px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: { xs: "column", sm: "row", md: "row" },
            alignItems: { xs: "start", sm: "center", md: "center" },
            py: 2,
            // gap: 2,
          }}>
          <FormControl sx={{ width: 227 }}>
            <TextField
              placeholder="Search Category"
              size="small"
              value={search}
              onChange={handleSearch}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" type="submit">
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>{" "}
          <Tooltip title="Add category">
            <Button
              onClick={handleOpen}
              size="small"
              variant="contained"
              startIcon={<AddIcon />}
              sx={{ px: 2, py: 1 }}>
              Category
            </Button>
          </Tooltip>
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <Heading>S.No</Heading>
                <Heading>Category Name</Heading>
                <Heading>Action</Heading>
              </TableRow>
            </TableHead>
            <TableBody>
              {search
                ? searchFilter.map((menu, index) => (
                    <TableRow key={index}>
                      <Data>{index + 1}</Data>
                      <Data>{menu.name}</Data>
                      <Data>
                        <Box>
                          <Tooltip title="Edit">
                            <Button
                              onClick={() => handleMenuEdit(menu._id, menu)}>
                              <EditIcon />
                            </Button>
                          </Tooltip>

                          <Tooltip
                            title={
                              menu.activated === true ? "Active" : "Inactive"
                            }>
                            <Switch
                              {...label}
                              //defaultChecked={menu.activated ? true : false}
                              checked={menu.activated}
                              defaultChecked
                              sx={{
                                "& .MuiSwitch-thumb": {
                                  color: menu.activated ? "#1b5e20" : "#c62828",
                                },
                                "& .MuiSwitch-track": {
                                  backgroundColor: menu.activated
                                    ? "#1b5e20"
                                    : "#c62828",
                                },
                              }}
                            />
                          </Tooltip>
                        </Box>
                      </Data>
                    </TableRow>
                  ))
                : formData.map((menu, index) => (
                    <TableRow key={index}>
                      <Data>{index + 1}</Data>
                      <Data>{menu.name}</Data>
                      <Data>
                        <Box>
                          <Tooltip title="Edit">
                            <Button
                              onClick={() => handleMenuEdit(menu._id, menu)}>
                              <EditIcon />
                            </Button>
                          </Tooltip>
                          <Tooltip
                            title={
                              menu.activated === true ? "Active" : "Inactive"
                            }>
                            <Button
                              onClick={() => handleUpdateStatus(menu._id)}>
                              <Switch
                                {...label}
                                // defaultChecked={menu.activated ? true : false}

                                checked={menu.activated}
                                defaultChecked
                                sx={{
                                  "& .MuiSwitch-thumb": {
                                    color: menu.activated
                                      ? "#1b5e20"
                                      : "#c62828",
                                  },
                                  "& .MuiSwitch-track": {
                                    backgroundColor: menu.activated
                                      ? "#1b5e20"
                                      : "#c62828",
                                  },
                                }}
                              />
                            </Button>
                          </Tooltip>
                        </Box>
                      </Data>
                    </TableRow>
                  ))}
              <TableRow>
                {!formData.length && !search.trim() && (
                  <Data align="center" colSpan={12}>
                    <Typography
                      variant="h6"
                      sx={{
                        textAlign: "center",
                        margin: "5px",
                        padding: "5px",
                      }}>
                      No data found
                    </Typography>
                  </Data>
                )}
                {search.trim() && !searchFilter.length && (
                  <Data align="center" colSpan={12}>
                    <Typography
                      variant="h6"
                      sx={{
                        textAlign: "center",
                        margin: "5px",
                        padding: "5px",
                      }}>
                      No data found
                    </Typography>
                  </Data>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* category create/update model */}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <form onSubmit={formik.handleSubmit}>
          <Box sx={style}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} lg={12} md={12}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  sx={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "20px",
                  }}
                  component="h2">
                  {edit ? "Update Category" : " Add Category"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} lg={12} md={12}>
                <FormInput
                  size="small"
                  name="name"
                  formik={formik}
                  placeholder="Category Name"
                  required
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                lg={12}
                md={12}
                sx={{
                  display: "flex",
                  gap: 2,
                  justifyContent: {
                    xs: "center",
                    sm: "flex-end",
                    md: "flex-end",
                    lg: "flex-end",
                  },
                }}>
                <Button variant="outlined" color="error" onClick={handleClose}>
                  Cancel
                </Button>

                <LoadingButton
                  variant="contained"
                  type="submit"
                  loading={loading}>
                  {edit ? "Update" : "Add"}
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Dialog>
    </>
  );
};

export default Category;
