import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import { post, put, get } from "../../../api/apiMethods";
import { urls } from "../../../api/urlConstants";
import { LoadingButton } from "@mui/lab";
import { theme } from "../../../theme";
import { toast } from "react-toastify";

const Main = styled(Box)(() => ({
  mt: "30px",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const HeaderText = styled(Typography)(() => ({
  fontSize: "20px",
  fontWeight: "bold",
  textAlign: "center",
  color: theme.palette.primary.main,
}));

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});

  useEffect(() => {
    let user = window.localStorage.getItem("user");
    if (user) {
      setUser(JSON.parse(user));
    }
  }, []);

  const [formData, setFormData] = useState({
    oldPassWord: "",
    newPassWord: "",
    confirmPassWord: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !formData.oldPassWord ||
      !formData.newPassWord ||
      !formData.confirmPassWord
    ) {
      toast.error("Please fill the all password fields");
      return;
    }
    setLoading(true);

    try {
      const body = {
        mobile: user.mobile,
        oldPassWord: formData.oldPassWord,
        password: formData.confirmPassWord,
        newPassWord: formData.newPassWord,
      };
      const res = await put(`${urls.user.resetPassword}`, body);
      setLoading(false);
      setFormData({
        oldPassWord: "",
        newPassWord: "",
        confirmPassWord: "",
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      setFormData({
        oldPassWord: "",
        newPassWord: "",
        confirmPassWord: "",
      });
    }
  };
  return (
    <Container>
      <Main>
        <Paper
          sx={{
            width: { xs: "100%", sm: "100%", md: "50%", lg: "50%" },
            padding: "15px",
          }}
        >
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <HeaderText>Reset Password</HeaderText>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                <TextField
                  fullWidth
                  label="Old Password"
                  variant="outlined"
                  size="small"
                  name="oldPassWord"
                  value={formData.oldPassWord}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                <TextField
                  fullWidth
                  label="New Password"
                  variant="outlined"
                  size="small"
                  name="newPassWord"
                  value={formData.newPassWord}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                <TextField
                  fullWidth
                  label="Confirm Password"
                  variant="outlined"
                  name="confirmPassWord"
                  value={formData.confirmPassWord}
                  onChange={handleChange}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} display="flex" mt={2}>
                <LoadingButton
                  variant="contained"
                  size="small"
                  type="submit"
                  loading={loading}
                >
                  Submit
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Main>
    </Container>
  );
};

export default ResetPassword;
